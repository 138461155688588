import React from "react";
import { Container } from "react-bootstrap";
import Layout from "../components/Layout/Layout/Layout";
import PageHeading from "../components/Layout/PageHeading";

const TermsConditions = () => {
  const pageTitle = "Terms and Conditions";
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Layout>
      <PageHeading title={pageTitle} />
      <section className="who-is">
        <Container
          style={{ overflowWrap: "break-word", wordWrap: "break-word" }}
        >
          <p>
            <br className="Apple-interchange-newline" />
          </p>

          {/* <h6>Welcome to As in Auctions is!</h6> */}

          <p
            className="s10"
            style={{
              marginTop: 12,
              marginBottom: 0,
              lineHeight: "14.4px",
              caretColor: "rgb(0, 0, 0)",
              color: "rgb(0, 0, 0)",
              fontFamily: "-webkit-standard",
              fontSize: 16,
              fontStyle: "normal",
              fontVariantCaps: "normal",
              fontWeight: 400,
              letterSpacing: "normal",
              orphans: "auto",
              textAlign: "start",
              textIndent: 0,
              textTransform: "none",
              whiteSpace: "normal",
              widows: "auto",
              wordSpacing: 0,
              WebkitTextStrokeWidth: 0,
              textDecoration: "none",
            }}
          >
            <span
              className="s9"
              style={{ lineHeight: "14.4px", fontWeight: "bold" }}
            >
              WHAT PARTS OF THESE TERMS APPLY TO ME?
              <br />
              <span className="Apple-converted-space">&nbsp;</span>
            </span>
          </p>
          <p
            style={{
              marginTop: 0,
              marginBottom: 0,
              lineHeight: "14.4px",
              caretColor: "rgb(0, 0, 0)",
              color: "rgb(0, 0, 0)",
              fontFamily: "-webkit-standard",
              fontSize: 16,
              fontStyle: "normal",
              fontVariantCaps: "normal",
              fontWeight: 400,
              letterSpacing: "normal",
              orphans: "auto",
              textAlign: "start",
              textIndent: 0,
              textTransform: "none",
              whiteSpace: "normal",
              widows: "auto",
              wordSpacing: 0,
              WebkitTextStrokeWidth: 0,
              textDecoration: "none",
            }}
          > 
            <span style={{ lineHeight: "17.4px" }}>
              This agreement governs your use of the
            </span>
            <span style={{ lineHeight: "17.4px" }}>
              <span className="Apple-converted-space">&nbsp;</span>As Is
              Auctions platform
            </span>
            <span style={{ lineHeight: "17.4px" }}>
              <span className="Apple-converted-space">&nbsp;</span>(
            </span>
            <span
              className="s9"
              style={{ lineHeight: "14.4px", fontWeight: "bold" }}
            >
              Platform
            </span>
            <span style={{ lineHeight: "17.4px" }}>
              ) and any other services made available through the Platform. By
              using the Platform,  you agree to be bound by this agreement which
              forms a binding contractual agreement between you, the User, and
              us,
              <span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span
              className="s9"
              style={{ lineHeight: "14.4px", fontWeight: "bold" }}
            >
              AS IS AUCTIONS PTY LTD (ABN 92 672 843 775)
              <span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>(</span>
            <span style={{ lineHeight: "14.4px" }}>As Is Auctions</span>
            <span style={{ lineHeight: "14.4px" }}>
              ,<span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span
              className="s9"
              style={{ lineHeight: "14.4px", fontWeight: "bold" }}
            >
              As Is Auctions
            </span>
            <span style={{ lineHeight: "14.4px" }}>
              ,<span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span
              className="s9"
              style={{ lineHeight: "14.4px", fontWeight: "bold" }}
            >
              we
            </span>
            <span style={{ lineHeight: "14.4px" }}>
              <span className="Apple-converted-space">&nbsp;</span>or
              <span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span
              className="s9"
              style={{ lineHeight: "14.4px", fontWeight: "bold" }}
            >
              us
            </span>
            <span style={{ lineHeight: "14.4px" }}>). &nbsp;</span>
          </p>
          <p
            style={{
              marginTop: 0,
              marginBottom: 0,
              lineHeight: "14.4px",
              caretColor: "rgb(0, 0, 0)",
              color: "rgb(0, 0, 0)",
              fontFamily: "-webkit-standard",
              fontSize: 16,
              fontStyle: "normal",
              fontVariantCaps: "normal",
              fontWeight: 400,
              letterSpacing: "normal",
              orphans: "auto",
              textAlign: "start",
              textIndent: 0,
              textTransform: "none",
              whiteSpace: "normal",
              widows: "auto",
              wordSpacing: 0,
              WebkitTextStrokeWidth: 0,
              textDecoration: "none",
            }}
          >
            <span style={{ lineHeight: "14.4px" }}>
              The remainder of this agreement is divided into three parts:
            </span>
          </p>
          <div
            className="s12"
            style={{
              marginTop: 0,
              marginBottom: 0,
              textIndent: "-42px",
              marginLeft: 42,
              caretColor: "rgb(0, 0, 0)",
              color: "rgb(0, 0, 0)",
              fontFamily: "-webkit-standard",
              fontSize: 16,
              fontStyle: "normal",
              fontVariantCaps: "normal",
              fontWeight: 400,
              letterSpacing: "normal",
              orphans: "auto",
              textAlign: "start",
              textTransform: "none",
              whiteSpace: "normal",
              widows: "auto",
              wordSpacing: 0,
              WebkitTextStrokeWidth: 0,
              textDecoration: "none",
            }}
          >
            <span
              className="s11"
              style={{ lineHeight: "14.4px", fontFamily: "Symbol" }}
            >
              •<span className="Apple-converted-space">&nbsp;</span>
            </span>
            <a>
              <span style={{ lineHeight: "14.4px" }}>Part A</span>
            </a>
            <span style={{ lineHeight: "14.4px" }}>
              <span className="Apple-converted-space">&nbsp;</span>(
            </span>
            <span
              className="s9"
              style={{ lineHeight: "14.4px", fontWeight: "bold" }}
            >
              All Users
            </span>
            <span style={{ lineHeight: "14.4px" }}>
              ), which sets out terms that apply to all Users;
            </span>
          </div>
          <div
            className="s12"
            style={{
              marginTop: 0,
              marginBottom: 0,
              textIndent: "-42px",
              marginLeft: 42,
              caretColor: "rgb(0, 0, 0)",
              color: "rgb(0, 0, 0)",
              fontFamily: "-webkit-standard",
              fontSize: 16,
              fontStyle: "normal",
              fontVariantCaps: "normal",
              fontWeight: 400,
              letterSpacing: "normal",
              orphans: "auto",
              textAlign: "start",
              textTransform: "none",
              whiteSpace: "normal",
              widows: "auto",
              wordSpacing: 0,
              WebkitTextStrokeWidth: 0,
              textDecoration: "none",
            }}
          >
            <span
              className="s11"
              style={{ lineHeight: "14.4px", fontFamily: "Symbol" }}
            >
              •<span className="Apple-converted-space">&nbsp;</span>
            </span>
            <a>
              <span style={{ lineHeight: "14.4px" }}>Part B</span>
            </a>
            <span style={{ lineHeight: "14.4px" }}>
              <span className="Apple-converted-space">&nbsp;</span>(
            </span>
            <span
              className="s9"
              style={{ lineHeight: "14.4px", fontWeight: "bold" }}
            >
              Seller
            </span>
            <span
              className="s9"
              style={{ lineHeight: "14.4px", fontWeight: "bold" }}
            >
              s
            </span>
            <span style={{ lineHeight: "14.4px" }}>
              ), which sets out additional terms that apply to
              <span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span
              className="s9"
              style={{ lineHeight: "14.4px", fontWeight: "bold" }}
            >
              Seller
            </span>
            <span
              className="s9"
              style={{ lineHeight: "14.4px", fontWeight: "bold" }}
            >
              s
            </span>
            <span style={{ lineHeight: "14.4px" }}>
              , being<span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>
              sellers offering
              <span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>
              motor vehicles or motor vehicle parts (
            </span>
            <span
              className="s9"
              style={{ lineHeight: "14.4px", fontWeight: "bold" }}
            >
              Items
            </span>
            <span style={{ lineHeight: "14.4px" }}>)</span>
            <span style={{ lineHeight: "14.4px" }}>
              <span className="Apple-converted-space">&nbsp;</span>for sale by
              way of
              <span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>
              fixed price or
              <span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>
              auction on the Platform
            </span>
            <span style={{ lineHeight: "14.4px" }}>; and</span>
          </div>
          <div
            className="s12"
            style={{
              marginTop: 0,
              marginBottom: 0,
              textIndent: "-42px",
              marginLeft: 42,
              caretColor: "rgb(0, 0, 0)",
              color: "rgb(0, 0, 0)",
              fontFamily: "-webkit-standard",
              fontSize: 16,
              fontStyle: "normal",
              fontVariantCaps: "normal",
              fontWeight: 400,
              letterSpacing: "normal",
              orphans: "auto",
              textAlign: "start",
              textTransform: "none",
              whiteSpace: "normal",
              widows: "auto",
              wordSpacing: 0,
              WebkitTextStrokeWidth: 0,
              textDecoration: "none",
            }}
          >
            <span
              className="s11"
              style={{ lineHeight: "14.4px", fontFamily: "Symbol" }}
            >
              •<span className="Apple-converted-space">&nbsp;</span>
            </span>
            <a>
              <span style={{ lineHeight: "14.4px" }}>Part C</span>
            </a>
            <span style={{ lineHeight: "14.4px" }}>
              <span className="Apple-converted-space">&nbsp;</span>(
            </span>
            <span
              className="s9"
              style={{ lineHeight: "14.4px", fontWeight: "bold" }}
            >
              Buyers
            </span>
            <span style={{ lineHeight: "14.4px" }}>
              ), which sets out additional terms that apply to
              <span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span
              className="s9"
              style={{ lineHeight: "14.4px", fontWeight: "bold" }}
            >
              Buyers
            </span>
            <span style={{ lineHeight: "14.4px" }}>
              , being<span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>
              buyers looking to purchase
              <span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>Items</span>
            <span style={{ lineHeight: "14.4px" }}>
              <span className="Apple-converted-space">&nbsp;</span>offered for
              sale by way of
              <span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>
              fixed price or
              <span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>
              auction on the Platform
            </span>
            <span style={{ lineHeight: "14.4px" }}>.</span>
          </div>
          <p
            style={{
              marginTop: 0,
              marginBottom: 0,
              lineHeight: "14.4px",
              caretColor: "rgb(0, 0, 0)",
              color: "rgb(0, 0, 0)",
              fontFamily: "-webkit-standard",
              fontSize: 16,
              fontStyle: "normal",
              fontVariantCaps: "normal",
              fontWeight: 400,
              letterSpacing: "normal",
              orphans: "auto",
              textAlign: "start",
              textIndent: 0,
              textTransform: "none",
              whiteSpace: "normal",
              widows: "auto",
              wordSpacing: 0,
              WebkitTextStrokeWidth: 0,
              textDecoration: "none",
            }}
          >
            <span style={{ lineHeight: "17.4px" }}>
              If you intend to use the Platform as a
              <span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "17.4px" }}>Seller</span>
            <span style={{ lineHeight: "17.4px" }}>
              , only<span className="Apple-converted-space">&nbsp;</span>
            </span>
            <a>
              <span style={{ lineHeight: "17.4px" }}>Part A</span>
            </a>
            <span style={{ lineHeight: "17.4px" }}>
              <span className="Apple-converted-space">&nbsp;</span>and
              <span className="Apple-converted-space">&nbsp;</span>
            </span>
            <a>
              <span style={{ lineHeight: "17.4px" }}>Part B</span>
            </a>
            <span style={{ lineHeight: "17.4px" }}>
              <span className="Apple-converted-space">&nbsp;</span>of these
              terms will apply to you.
              <span className="Apple-converted-space">&nbsp;</span>
            </span>
          </p>
          <p
            style={{
              marginTop: 0,
              marginBottom: 0,
              lineHeight: "14.4px",
              caretColor: "rgb(0, 0, 0)",
              color: "rgb(0, 0, 0)",
              fontFamily: "-webkit-standard",
              fontSize: 16,
              fontStyle: "normal",
              fontVariantCaps: "normal",
              fontWeight: 400,
              letterSpacing: "normal",
              orphans: "auto",
              textAlign: "start",
              textIndent: 0,
              textTransform: "none",
              whiteSpace: "normal",
              widows: "auto",
              wordSpacing: 0,
              WebkitTextStrokeWidth: 0,
              textDecoration: "none",
            }}
          >
            <span style={{ lineHeight: "17.4px" }}>
              If you intend to use the Platform as a
              <span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "17.4px" }}>Buyer</span>
            <span style={{ lineHeight: "17.4px" }}>
              , only<span className="Apple-converted-space">&nbsp;</span>
            </span>
            <a>
              <span style={{ lineHeight: "17.4px" }}>Part A</span>
            </a>
            <span style={{ lineHeight: "17.4px" }}>
              <span className="Apple-converted-space">&nbsp;</span>and
              <span className="Apple-converted-space">&nbsp;</span>
            </span>
            <a>
              <span style={{ lineHeight: "17.4px" }}>Part C</span>
            </a>
            <span style={{ lineHeight: "17.4px" }}>
              <span className="Apple-converted-space">&nbsp;</span>of these
              terms will apply to you.
              <span className="Apple-converted-space">&nbsp;</span>
            </span>
          </p>
          <p
            style={{
              marginTop: 0,
              marginBottom: 0,
              lineHeight: "14.4px",
              caretColor: "rgb(0, 0, 0)",
              color: "rgb(0, 0, 0)",
              fontFamily: "-webkit-standard",
              fontSize: 16,
              fontStyle: "normal",
              fontVariantCaps: "normal",
              fontWeight: 400,
              letterSpacing: "normal",
              orphans: "auto",
              textAlign: "start",
              textIndent: 0,
              textTransform: "none",
              whiteSpace: "normal",
              widows: "auto",
              wordSpacing: 0,
              WebkitTextStrokeWidth: 0,
              textDecoration: "none",
            }}
          >
            <span style={{ lineHeight: "17.4px" }}>&nbsp;</span>
          </p>
          <p
            style={{
              marginTop: 0,
              marginBottom: 0,
              lineHeight: "14.4px",
              caretColor: "rgb(0, 0, 0)",
              color: "rgb(0, 0, 0)",
              fontFamily: "-webkit-standard",
              fontSize: 16,
              fontStyle: "normal",
              fontVariantCaps: "normal",
              fontWeight: 400,
              letterSpacing: "normal",
              orphans: "auto",
              textAlign: "start",
              textIndent: 0,
              textTransform: "none",
              whiteSpace: "normal",
              widows: "auto",
              wordSpacing: 0,
              WebkitTextStrokeWidth: 0,
              textDecoration: "none",
            }}
          >
            <span style={{ lineHeight: "17.4px" }}>
              When we talk about the “
            </span>
            <span
              className="s9"
              style={{ lineHeight: "17.4px", fontWeight: "bold" }}
            >
              Services
            </span>
            <span style={{ lineHeight: "17.4px" }}>
              ” in this agreement, we a
            </span>
            <span style={{ lineHeight: "17.4px" }}>
              re referring to
              <span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "17.4px" }}>
              the services available through our website and any associated
              services we offer
            </span>
            <span style={{ lineHeight: "17.4px" }}>.</span>
            <span style={{ lineHeight: "17.4px" }}></span>
          </p>
          <div
            className="s13"
            style={{
              marginTop: 18,
              marginBottom: 0,
              textIndent: "-56px",
              marginLeft: 56,
              caretColor: "rgb(0, 0, 0)",
              color: "rgb(0, 0, 0)",
              fontFamily: "-webkit-standard",
              fontSize: 16,
              fontStyle: "normal",
              fontVariantCaps: "normal",
              fontWeight: 400,
              letterSpacing: "normal",
              orphans: "auto",
              textAlign: "start",
              textTransform: "none",
              whiteSpace: "normal",
              widows: "auto",
              wordSpacing: 0,
              WebkitTextStrokeWidth: 0,
              textDecoration: "none",
            }}
          >
            <span
              className="s9"
              style={{ lineHeight: "14.4px", fontWeight: "bold" }}
            >
              Part A<span className="Apple-converted-space">&nbsp;</span>
            </span>
            <a name="_heading=h.gjdgxs" />
            <a name="_Ref124842544" />
            <span
              className="s9"
              style={{ lineHeight: "14.4px", fontWeight: "bold" }}
            >
              All Users
            </span>
          </div>
          <div
            className="s15"
            style={{
              marginTop: 18,
              marginBottom: 0,
              textIndent: "-42px",
              marginLeft: 42,
              caretColor: "rgb(0, 0, 0)",
              color: "rgb(0, 0, 0)",
              fontFamily: "-webkit-standard",
              fontSize: 16,
              fontStyle: "normal",
              fontVariantCaps: "normal",
              fontWeight: 400,
              letterSpacing: "normal",
              orphans: "auto",
              textAlign: "start",
              textTransform: "none",
              whiteSpace: "normal",
              widows: "auto",
              wordSpacing: 0,
              WebkitTextStrokeWidth: 0,
              textDecoration: "none",
            }}
          >
            <span
              className="s14"
              style={{
                lineHeight: "14.4px",
                textTransform: "uppercase",
                fontWeight: "bold",
              }}
            >
              1<span className="Apple-converted-space">&nbsp;</span>
            </span>
            <a name="_heading=h.30j0zll" />
            <span
              className="s14"
              style={{
                lineHeight: "14.4px",
                textTransform: "uppercase",
                fontWeight: "bold",
              }}
            >
              ELIGIBILITY
            </span>
          </div>
          <div
            className="s16"
            style={{
              marginTop: 0,
              marginBottom: 0,
              textIndent: "-42px",
              marginLeft: 85,
              caretColor: "rgb(0, 0, 0)",
              color: "rgb(0, 0, 0)",
              fontFamily: "-webkit-standard",
              fontSize: 16,
              fontStyle: "normal",
              fontVariantCaps: "normal",
              fontWeight: 400,
              letterSpacing: "normal",
              orphans: "auto",
              textAlign: "start",
              textTransform: "none",
              whiteSpace: "normal",
              widows: "auto",
              wordSpacing: 0,
              WebkitTextStrokeWidth: 0,
              textDecoration: "none",
            }}
          >
            <span style={{ lineHeight: "14.4px" }}>
              (a)<span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>
              This Platform is not intended for unsupervised use by any person
              under the age of 18 years old or any person who has previously
              been suspended or prohibited from using the Platform. By using the
              Platform, you represent and warrant that you are either:
            </span>
          </div>
          <div
            className="s17"
            style={{
              marginTop: 0,
              marginBottom: 0,
              textIndent: "-42px",
              marginLeft: 127,
              caretColor: "rgb(0, 0, 0)",
              color: "rgb(0, 0, 0)",
              fontFamily: "-webkit-standard",
              fontSize: 16,
              fontStyle: "normal",
              fontVariantCaps: "normal",
              fontWeight: 400,
              letterSpacing: "normal",
              orphans: "auto",
              textAlign: "start",
              textTransform: "none",
              whiteSpace: "normal",
              widows: "auto",
              wordSpacing: 0,
              WebkitTextStrokeWidth: 0,
              textDecoration: "none",
            }}
          >
            <span style={{ lineHeight: "14.4px" }}>
              (i)<span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>
              over the age of 18 years and accessing the Platform for personal
              use; or<span className="Apple-converted-space">&nbsp;</span>
            </span>
          </div>
          <div
            className="s17"
            style={{
              marginTop: 0,
              marginBottom: 0,
              textIndent: "-42px",
              marginLeft: 127,
              caretColor: "rgb(0, 0, 0)",
              color: "rgb(0, 0, 0)",
              fontFamily: "-webkit-standard",
              fontSize: 16,
              fontStyle: "normal",
              fontVariantCaps: "normal",
              fontWeight: 400,
              letterSpacing: "normal",
              orphans: "auto",
              textAlign: "start",
              textTransform: "none",
              whiteSpace: "normal",
              widows: "auto",
              wordSpacing: 0,
              WebkitTextStrokeWidth: 0,
              textDecoration: "none",
            }}
          >
            <span style={{ lineHeight: "14.4px" }}>
              (ii)<span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>
              accessing the Platform on behalf of someone under the age of 18
              years old and consent to that person’s use of the Platform.
            </span>
          </div>
          <div
            className="s16"
            style={{
              marginTop: 0,
              marginBottom: 0,
              textIndent: "-42px",
              marginLeft: 85,
              caretColor: "rgb(0, 0, 0)",
              color: "rgb(0, 0, 0)",
              fontFamily: "-webkit-standard",
              fontSize: 16,
              fontStyle: "normal",
              fontVariantCaps: "normal",
              fontWeight: 400,
              letterSpacing: "normal",
              orphans: "auto",
              textAlign: "start",
              textTransform: "none",
              whiteSpace: "normal",
              widows: "auto",
              wordSpacing: 0,
              WebkitTextStrokeWidth: 0,
              textDecoration: "none",
            }}
          >
            <span style={{ lineHeight: "14.4px" }}>
              (b)<span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>
              Please do not access the Platform if you are under the age of 18
              years old and do not have your parent or guardian’s consent, or if
              you have previously been suspended or prohibited from using the
              Platform.
              <span className="Apple-converted-space">&nbsp;</span>
            </span>
          </div>
          <div
            className="s16"
            style={{
              marginTop: 0,
              marginBottom: 0,
              textIndent: "-42px",
              marginLeft: 85,
              caretColor: "rgb(0, 0, 0)",
              color: "rgb(0, 0, 0)",
              fontFamily: "-webkit-standard",
              fontSize: 16,
              fontStyle: "normal",
              fontVariantCaps: "normal",
              fontWeight: 400,
              letterSpacing: "normal",
              orphans: "auto",
              textAlign: "start",
              textTransform: "none",
              whiteSpace: "normal",
              widows: "auto",
              wordSpacing: 0,
              WebkitTextStrokeWidth: 0,
              textDecoration: "none",
            }}
          >
            <span style={{ lineHeight: "14.4px" }}>
              (c)<span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>
              If you use the Platform on behalf of a company or organisation you
              warrant that you have the necessary authority from that company or
              organisation to do so. If you are signing up not as an individual
              but on behalf of your company, your employer, an organisation,
              government or other legal entity (
            </span>
            <span
              className="s9"
              style={{ lineHeight: "14.4px", fontWeight: "bold" }}
            >
              Represented Entity
            </span>
            <span style={{ lineHeight: "14.4px" }}>
              ), then “you” or “User” means the Represented Entity and you are
              binding the Represented Entity to this agreement. If you are
              accepting this agreement and using our Services on behalf of a
              Represented Entity, you represent and warrant that you are
              authorised to do so.
              <span className="Apple-converted-space">&nbsp;</span>
            </span>
          </div>
          <div
            className="s15"
            style={{
              marginTop: 18,
              marginBottom: 0,
              textIndent: "-42px",
              marginLeft: 42,
              caretColor: "rgb(0, 0, 0)",
              color: "rgb(0, 0, 0)",
              fontFamily: "-webkit-standard",
              fontSize: 16,
              fontStyle: "normal",
              fontVariantCaps: "normal",
              fontWeight: 400,
              letterSpacing: "normal",
              orphans: "auto",
              textAlign: "start",
              textTransform: "none",
              whiteSpace: "normal",
              widows: "auto",
              wordSpacing: 0,
              WebkitTextStrokeWidth: 0,
              textDecoration: "none",
            }}
          >
            <span
              className="s14"
              style={{
                lineHeight: "14.4px",
                textTransform: "uppercase",
                fontWeight: "bold",
              }}
            >
              2<span className="Apple-converted-space">&nbsp;</span>
            </span>
            <a name="_heading=h.1fob9te" />
            <span
              className="s14"
              style={{
                lineHeight: "14.4px",
                textTransform: "uppercase",
                fontWeight: "bold",
              }}
            >
              ACCOUNTS
            </span>
          </div>
          <div
            className="s16"
            style={{
              marginTop: 0,
              marginBottom: 0,
              textIndent: "-42px",
              marginLeft: 85,
              caretColor: "rgb(0, 0, 0)",
              color: "rgb(0, 0, 0)",
              fontFamily: "-webkit-standard",
              fontSize: 16,
              fontStyle: "normal",
              fontVariantCaps: "normal",
              fontWeight: 400,
              letterSpacing: "normal",
              orphans: "auto",
              textAlign: "start",
              textTransform: "none",
              whiteSpace: "normal",
              widows: "auto",
              wordSpacing: 0,
              WebkitTextStrokeWidth: 0,
              textDecoration: "none",
            }}
          >
            <span style={{ lineHeight: "14.4px" }}>
              (a)<span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>
              In order to use most of the functionality of the Platform, all
              Users are required to sign-up, register and receive an account
              through the Platform (an
              <span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span
              className="s9"
              style={{ lineHeight: "14.4px", fontWeight: "bold" }}
            >
              Account
            </span>
            <span style={{ lineHeight: "14.4px" }}>).</span>
          </div>
          <div
            className="s16"
            style={{
              marginTop: 0,
              marginBottom: 0,
              textIndent: "-42px",
              marginLeft: 85,
              caretColor: "rgb(0, 0, 0)",
              color: "rgb(0, 0, 0)",
              fontFamily: "-webkit-standard",
              fontSize: 16,
              fontStyle: "normal",
              fontVariantCaps: "normal",
              fontWeight: 400,
              letterSpacing: "normal",
              orphans: "auto",
              textAlign: "start",
              textTransform: "none",
              whiteSpace: "normal",
              widows: "auto",
              wordSpacing: 0,
              WebkitTextStrokeWidth: 0,
              textDecoration: "none",
            }}
          >
            <span style={{ lineHeight: "14.4px" }}>
              (b)<span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>
              As part of the Account registration process and as part of your
              continued use of the Platform, you are required to provide
              personal information and details
            </span>
            <span style={{ lineHeight: "14.4px" }}>
              <span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>
              such as&nbsp;your email address, first and last name, preferred
              username, a secure password, billing, postal and physical
              addresses, mobile phone number
            </span>
            <span style={{ lineHeight: "14.4px" }}>, PayPal email address</span>
            <span style={{ lineHeight: "14.4px" }}>,</span>
            <span style={{ lineHeight: "14.4px" }}>
              <span className="Apple-converted-space">&nbsp;</span>(and if
              applicable, information about the business you represent such as
              your ABN and business address)
            </span>
            <span style={{ lineHeight: "14.4px" }}>
              <span className="Apple-converted-space">&nbsp;</span>and other
              information as determined by
              <span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>As Is Auctions</span>
            <span style={{ lineHeight: "14.4px" }}>
              <span className="Apple-converted-space">&nbsp;</span>from time to
              time.
            </span>
          </div>
          <div
            className="s16"
            style={{
              marginTop: 0,
              marginBottom: 0,
              textIndent: "-42px",
              marginLeft: 85,
              caretColor: "rgb(0, 0, 0)",
              color: "rgb(0, 0, 0)",
              fontFamily: "-webkit-standard",
              fontSize: 16,
              fontStyle: "normal",
              fontVariantCaps: "normal",
              fontWeight: 400,
              letterSpacing: "normal",
              orphans: "auto",
              textAlign: "start",
              textTransform: "none",
              whiteSpace: "normal",
              widows: "auto",
              wordSpacing: 0,
              WebkitTextStrokeWidth: 0,
              textDecoration: "none",
            }}
          >
            <span style={{ lineHeight: "14.4px" }}>
              (c)<span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>
              You warrant that any information you give to
              <span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>As Is Auctions</span>
            <span style={{ lineHeight: "14.4px" }}>
              <span className="Apple-converted-space">&nbsp;</span>in the course
              of completing the Account registration process will always be
              accurate, honest, correct and up to date.
            </span>
          </div>
          <div
            className="s16"
            style={{
              marginTop: 0,
              marginBottom: 0,
              textIndent: "-42px",
              marginLeft: 85,
              caretColor: "rgb(0, 0, 0)",
              color: "rgb(0, 0, 0)",
              fontFamily: "-webkit-standard",
              fontSize: 16,
              fontStyle: "normal",
              fontVariantCaps: "normal",
              fontWeight: 400,
              letterSpacing: "normal",
              orphans: "auto",
              textAlign: "start",
              textTransform: "none",
              whiteSpace: "normal",
              widows: "auto",
              wordSpacing: 0,
              WebkitTextStrokeWidth: 0,
              textDecoration: "none",
            }}
          >
            <span style={{ lineHeight: "14.4px" }}>
              (d)<span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>
              Correspondence between Users must take place on the Platform. You
              agree to ensure that your Account does not display any of your
              personal contact information at any time such that it can be
              viewed by any other User. You agree to not give your contact
              details to any other User. &nbsp;
            </span>
          </div>
          <div
            className="s16"
            style={{
              marginTop: 0,
              marginBottom: 0,
              textIndent: "-42px",
              marginLeft: 85,
              caretColor: "rgb(0, 0, 0)",
              color: "rgb(0, 0, 0)",
              fontFamily: "-webkit-standard",
              fontSize: 16,
              fontStyle: "normal",
              fontVariantCaps: "normal",
              fontWeight: 400,
              letterSpacing: "normal",
              orphans: "auto",
              textAlign: "start",
              textTransform: "none",
              whiteSpace: "normal",
              widows: "auto",
              wordSpacing: 0,
              WebkitTextStrokeWidth: 0,
              textDecoration: "none",
            }}
          >
            <span style={{ lineHeight: "14.4px" }}>
              (e)<span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>
              Once you complete the Account registration process,
              <span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>As Is Auctions</span>
            <span style={{ lineHeight: "14.4px" }}>
              <span className="Apple-converted-space">&nbsp;</span>may, in its
              absolute discretion, choose to accept you as a registered user
              within the Platform and provide you with an Account.
            </span>
          </div>
          <div
            className="s16"
            style={{
              marginTop: 0,
              marginBottom: 0,
              textIndent: "-42px",
              marginLeft: 85,
              caretColor: "rgb(0, 0, 0)",
              color: "rgb(0, 0, 0)",
              fontFamily: "-webkit-standard",
              fontSize: 16,
              fontStyle: "normal",
              fontVariantCaps: "normal",
              fontWeight: 400,
              letterSpacing: "normal",
              orphans: "auto",
              textAlign: "start",
              textTransform: "none",
              whiteSpace: "normal",
              widows: "auto",
              wordSpacing: 0,
              WebkitTextStrokeWidth: 0,
              textDecoration: "none",
            }}
          >
            <span style={{ lineHeight: "14.4px" }}>
              (f)<span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>As Is Auctions</span>
            <span style={{ lineHeight: "14.4px" }}>
              <span className="Apple-converted-space">&nbsp;</span>reserves the
              right to contact you about any concerning behaviour by you, or to
              seek a resolution with you.
            </span>
          </div>
          <div
            className="s16"
            style={{
              marginTop: 0,
              marginBottom: 0,
              textIndent: "-42px",
              marginLeft: 85,
              caretColor: "rgb(0, 0, 0)",
              color: "rgb(0, 0, 0)",
              fontFamily: "-webkit-standard",
              fontSize: 16,
              fontStyle: "normal",
              fontVariantCaps: "normal",
              fontWeight: 400,
              letterSpacing: "normal",
              orphans: "auto",
              textAlign: "start",
              textTransform: "none",
              whiteSpace: "normal",
              widows: "auto",
              wordSpacing: 0,
              WebkitTextStrokeWidth: 0,
              textDecoration: "none",
            }}
          >
            <span style={{ lineHeight: "14.4px" }}>
              (g)<span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>As Is Auctions</span>
            <span style={{ lineHeight: "14.4px" }}>
              <span className="Apple-converted-space">&nbsp;</span>may, in its
              absolute discretion, suspend or cancel your Account for any
              reason, including for any failure to comply with this agreement.
            </span>
          </div>
          <div
            className="s15"
            style={{
              marginTop: 18,
              marginBottom: 0,
              textIndent: "-42px",
              marginLeft: 42,
              caretColor: "rgb(0, 0, 0)",
              color: "rgb(0, 0, 0)",
              fontFamily: "-webkit-standard",
              fontSize: 16,
              fontStyle: "normal",
              fontVariantCaps: "normal",
              fontWeight: 400,
              letterSpacing: "normal",
              orphans: "auto",
              textAlign: "start",
              textTransform: "none",
              whiteSpace: "normal",
              widows: "auto",
              wordSpacing: 0,
              WebkitTextStrokeWidth: 0,
              textDecoration: "none",
            }}
          >
            <span
              className="s14"
              style={{
                lineHeight: "14.4px",
                textTransform: "uppercase",
                fontWeight: "bold",
              }}
            >
              3<span className="Apple-converted-space">&nbsp;</span>
            </span>
            <a name="_heading=h.3znysh7" />
            <a name="_Ref152241741" />
            <span
              className="s14"
              style={{
                lineHeight: "14.4px",
                textTransform: "uppercase",
                fontWeight: "bold",
              }}
            >
              USER OBLIGATIONS
            </span>
          </div>
          <p
            className="s18"
            style={{
              marginTop: 0,
              marginBottom: 0,
              lineHeight: "14.4px",
              marginLeft: 42,
              caretColor: "rgb(0, 0, 0)",
              color: "rgb(0, 0, 0)",
              fontFamily: "-webkit-standard",
              fontSize: 16,
              fontStyle: "normal",
              fontVariantCaps: "normal",
              fontWeight: 400,
              letterSpacing: "normal",
              orphans: "auto",
              textAlign: "start",
              textIndent: 0,
              textTransform: "none",
              whiteSpace: "normal",
              widows: "auto",
              wordSpacing: 0,
              WebkitTextStrokeWidth: 0,
              textDecoration: "none",
            }}
          >
            <span style={{ lineHeight: "14.4px" }}>As a User, you agree:</span>
          </p>
          <div
            className="s16"
            style={{
              marginTop: 0,
              marginBottom: 0,
              textIndent: "-42px",
              marginLeft: 85,
              caretColor: "rgb(0, 0, 0)",
              color: "rgb(0, 0, 0)",
              fontFamily: "-webkit-standard",
              fontSize: 16,
              fontStyle: "normal",
              fontVariantCaps: "normal",
              fontWeight: 400,
              letterSpacing: "normal",
              orphans: "auto",
              textAlign: "start",
              textTransform: "none",
              whiteSpace: "normal",
              widows: "auto",
              wordSpacing: 0,
              WebkitTextStrokeWidth: 0,
              textDecoration: "none",
            }}
          >
            <span style={{ lineHeight: "14.4px" }}>
              (a)<span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>
              not to intimidate, harass, impersonate, stalk, threaten, bully or
              endanger any other User or distribute unsolicited commercial
              content, junk mail, spam, bulk content or harassment;
            </span>
          </div>
          <div
            className="s16"
            style={{
              marginTop: 0,
              marginBottom: 0,
              textIndent: "-42px",
              marginLeft: 85,
              caretColor: "rgb(0, 0, 0)",
              color: "rgb(0, 0, 0)",
              fontFamily: "-webkit-standard",
              fontSize: 16,
              fontStyle: "normal",
              fontVariantCaps: "normal",
              fontWeight: 400,
              letterSpacing: "normal",
              orphans: "auto",
              textAlign: "start",
              textTransform: "none",
              whiteSpace: "normal",
              widows: "auto",
              wordSpacing: 0,
              WebkitTextStrokeWidth: 0,
              textDecoration: "none",
            }}
          >
            <span style={{ lineHeight: "14.4px" }}>
              (b)<span className="Apple-converted-space">&nbsp;</span>
            </span>
            <a name="_Ref124517621" />
            <span style={{ lineHeight: "14.4px" }}>
              to not share your Account with any other person and that any use
              of your Account by any other person is strictly prohibited. You
              must immediately notify
              <span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>As Is Auctions</span>
            <span style={{ lineHeight: "14.4px" }}>
              <span className="Apple-converted-space">&nbsp;</span>of any
              unauthorised use of your Account, password or email, or any other
              breach or potential breach of the Platform’s security;
            </span>
          </div>
          <div
            className="s16"
            style={{
              marginTop: 0,
              marginBottom: 0,
              textIndent: "-42px",
              marginLeft: 85,
              caretColor: "rgb(0, 0, 0)",
              color: "rgb(0, 0, 0)",
              fontFamily: "-webkit-standard",
              fontSize: 16,
              fontStyle: "normal",
              fontVariantCaps: "normal",
              fontWeight: 400,
              letterSpacing: "normal",
              orphans: "auto",
              textAlign: "start",
              textTransform: "none",
              whiteSpace: "normal",
              widows: "auto",
              wordSpacing: 0,
              WebkitTextStrokeWidth: 0,
              textDecoration: "none",
            }}
          >
            <span style={{ lineHeight: "14.4px" }}>
              (c)<span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>
              to not use the Platform for any purpose other than for the purpose
              of making arrangements to provide or receive services, including
              by not using the Platform:
            </span>
          </div>
          <div
            className="s17"
            style={{
              marginTop: 0,
              marginBottom: 0,
              textIndent: "-42px",
              marginLeft: 127,
              caretColor: "rgb(0, 0, 0)",
              color: "rgb(0, 0, 0)",
              fontFamily: "-webkit-standard",
              fontSize: 16,
              fontStyle: "normal",
              fontVariantCaps: "normal",
              fontWeight: 400,
              letterSpacing: "normal",
              orphans: "auto",
              textAlign: "start",
              textTransform: "none",
              whiteSpace: "normal",
              widows: "auto",
              wordSpacing: 0,
              WebkitTextStrokeWidth: 0,
              textDecoration: "none",
            }}
          >
            <span style={{ lineHeight: "14.4px" }}>
              (i)<span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>
              in a manner that is illegal or fraudulent or facilitates illegal
              or fraudulent activity (including requesting or accepting a job
              which includes illegal activities or purposes); and
            </span>
          </div>
          <div
            className="s17"
            style={{
              marginTop: 0,
              marginBottom: 0,
              textIndent: "-42px",
              marginLeft: 127,
              caretColor: "rgb(0, 0, 0)",
              color: "rgb(0, 0, 0)",
              fontFamily: "-webkit-standard",
              fontSize: 16,
              fontStyle: "normal",
              fontVariantCaps: "normal",
              fontWeight: 400,
              letterSpacing: "normal",
              orphans: "auto",
              textAlign: "start",
              textTransform: "none",
              whiteSpace: "normal",
              widows: "auto",
              wordSpacing: 0,
              WebkitTextStrokeWidth: 0,
              textDecoration: "none",
            }}
          >
            <span style={{ lineHeight: "14.4px" }}>
              (ii)<span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>
              in connection with any commercial or money making or other
              promotional or marketing endeavours except those that are endorsed
              herein, or as approved in writing by
              <span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>As Is Auctions</span>
            <span style={{ lineHeight: "14.4px" }}>
              ;<span className="Apple-converted-space">&nbsp;</span>
            </span>
          </div>
          <div
            className="s16"
            style={{
              marginTop: 0,
              marginBottom: 0,
              textIndent: "-42px",
              marginLeft: 85,
              caretColor: "rgb(0, 0, 0)",
              color: "rgb(0, 0, 0)",
              fontFamily: "-webkit-standard",
              fontSize: 16,
              fontStyle: "normal",
              fontVariantCaps: "normal",
              fontWeight: 400,
              letterSpacing: "normal",
              orphans: "auto",
              textAlign: "start",
              textTransform: "none",
              whiteSpace: "normal",
              widows: "auto",
              wordSpacing: 0,
              WebkitTextStrokeWidth: 0,
              textDecoration: "none",
            }}
          >
            <span style={{ lineHeight: "14.4px" }}>
              (d)<span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>
              not to act in any way that may harm the reputation of
              <span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>As Is Auctions</span>
            <span style={{ lineHeight: "14.4px" }}>
              <span className="Apple-converted-space">&nbsp;</span>or associated
              or interested parties or do anything at all contrary to the
              interests of
              <span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>As Is Auctions</span>
            <span style={{ lineHeight: "14.4px" }}>
              or the Platform;
              <span className="Apple-converted-space">&nbsp;</span>
            </span>
          </div>
          <div
            className="s16"
            style={{
              marginTop: 0,
              marginBottom: 0,
              textIndent: "-42px",
              marginLeft: 85,
              caretColor: "rgb(0, 0, 0)",
              color: "rgb(0, 0, 0)",
              fontFamily: "-webkit-standard",
              fontSize: 16,
              fontStyle: "normal",
              fontVariantCaps: "normal",
              fontWeight: 400,
              letterSpacing: "normal",
              orphans: "auto",
              textAlign: "start",
              textTransform: "none",
              whiteSpace: "normal",
              widows: "auto",
              wordSpacing: 0,
              WebkitTextStrokeWidth: 0,
              textDecoration: "none",
            }}
          >
            <span style={{ lineHeight: "14.4px" }}>
              (e)<span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>
              not to make any automated use of the Platform and you must not
              copy, reproduce, translate, adapt, vary or modify the Platform
              without the express written consent of
              <span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>As Is Auctions</span>
            <span style={{ lineHeight: "14.4px" }}>
              ;<span className="Apple-converted-space">&nbsp;</span>
            </span>
          </div>
          <div
            className="s16"
            style={{
              marginTop: 0,
              marginBottom: 0,
              textIndent: "-42px",
              marginLeft: 85,
              caretColor: "rgb(0, 0, 0)",
              color: "rgb(0, 0, 0)",
              fontFamily: "-webkit-standard",
              fontSize: 16,
              fontStyle: "normal",
              fontVariantCaps: "normal",
              fontWeight: 400,
              letterSpacing: "normal",
              orphans: "auto",
              textAlign: "start",
              textTransform: "none",
              whiteSpace: "normal",
              widows: "auto",
              wordSpacing: 0,
              WebkitTextStrokeWidth: 0,
              textDecoration: "none",
            }}
          >
            <span style={{ lineHeight: "14.4px" }}>
              (f)<span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>
              that<span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>As Is Auctions</span>
            <span style={{ lineHeight: "14.4px" }}>
              <span className="Apple-converted-space">&nbsp;</span>may change
              any features of the Platform or Services offered through the
              Platform at any time without notice to you;
            </span>
          </div>
          <div
            className="s16"
            style={{
              marginTop: 0,
              marginBottom: 0,
              textIndent: "-42px",
              marginLeft: 85,
              caretColor: "rgb(0, 0, 0)",
              color: "rgb(0, 0, 0)",
              fontFamily: "-webkit-standard",
              fontSize: 16,
              fontStyle: "normal",
              fontVariantCaps: "normal",
              fontWeight: 400,
              letterSpacing: "normal",
              orphans: "auto",
              textAlign: "start",
              textTransform: "none",
              whiteSpace: "normal",
              widows: "auto",
              wordSpacing: 0,
              WebkitTextStrokeWidth: 0,
              textDecoration: "none",
            }}
          >
            <span style={{ lineHeight: "14.4px" }}>
              (g)<span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>
              that information given to you through the Platform, by
              <span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>As Is Auctions</span>
            <span style={{ lineHeight: "14.4px" }}>
              <span className="Apple-converted-space">&nbsp;</span>or another
              User including a
              <span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>Seller</span>
            <span style={{ lineHeight: "14.4px" }}>
              , is general in nature and we take no responsibility for anything
              caused by any actions you take in reliance on that information;
              and
            </span>
          </div>
          <div
            className="s16"
            style={{
              marginTop: 0,
              marginBottom: 0,
              textIndent: "-42px",
              marginLeft: 85,
              caretColor: "rgb(0, 0, 0)",
              color: "rgb(0, 0, 0)",
              fontFamily: "-webkit-standard",
              fontSize: 16,
              fontStyle: "normal",
              fontVariantCaps: "normal",
              fontWeight: 400,
              letterSpacing: "normal",
              orphans: "auto",
              textAlign: "start",
              textTransform: "none",
              whiteSpace: "normal",
              widows: "auto",
              wordSpacing: 0,
              WebkitTextStrokeWidth: 0,
              textDecoration: "none",
            }}
          >
            <span style={{ lineHeight: "14.4px" }}>
              (h)<span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>
              that<span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>As Is Auctions</span>
            <span style={{ lineHeight: "14.4px" }}>
              <span className="Apple-converted-space">&nbsp;</span>may cancel
              your account at any time if it considers, in its absolute
              discretion, that you are in breach or are likely to breach this
              clause
              <span className="Apple-converted-space">&nbsp;</span>
            </span>
            <a>
              <span style={{ lineHeight: "14.4px" }}>3</span>
            </a>
            <span style={{ lineHeight: "14.4px" }}>.</span>
          </div>
          <div
            className="s15"
            style={{
              marginTop: 18,
              marginBottom: 0,
              textIndent: "-42px",
              marginLeft: 42,
              caretColor: "rgb(0, 0, 0)",
              color: "rgb(0, 0, 0)",
              fontFamily: "-webkit-standard",
              fontSize: 16,
              fontStyle: "normal",
              fontVariantCaps: "normal",
              fontWeight: 400,
              letterSpacing: "normal",
              orphans: "auto",
              textAlign: "start",
              textTransform: "none",
              whiteSpace: "normal",
              widows: "auto",
              wordSpacing: 0,
              WebkitTextStrokeWidth: 0,
              textDecoration: "none",
            }}
          >
            <span
              className="s14"
              style={{
                lineHeight: "14.4px",
                textTransform: "uppercase",
                fontWeight: "bold",
              }}
            >
              4<span className="Apple-converted-space">&nbsp;</span>
            </span>
            <a name="_heading=h.2et92p0" />
            <span
              className="s14"
              style={{
                lineHeight: "14.4px",
                textTransform: "uppercase",
                fontWeight: "bold",
              }}
            >
              POSTED MATERIALS
            </span>
          </div>
          <div
            className="s20"
            style={{
              marginTop: 12,
              marginBottom: 0,
              textIndent: "-42px",
              marginLeft: 42,
              caretColor: "rgb(0, 0, 0)",
              color: "rgb(0, 0, 0)",
              fontFamily: "-webkit-standard",
              fontSize: 16,
              fontStyle: "normal",
              fontVariantCaps: "normal",
              fontWeight: 400,
              letterSpacing: "normal",
              orphans: "auto",
              textAlign: "start",
              textTransform: "none",
              whiteSpace: "normal",
              widows: "auto",
              wordSpacing: 0,
              WebkitTextStrokeWidth: 0,
              textDecoration: "none",
            }}
          >
            <span
              className="s19"
              style={{ lineHeight: "14.4px", textTransform: "uppercase" }}
            >
              4.1<span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span
              className="s19"
              style={{ lineHeight: "14.4px", textTransform: "uppercase" }}
            >
              WARRANTIES
            </span>
          </div>
          <p
            className="s18"
            style={{
              marginTop: 0,
              marginBottom: 0,
              lineHeight: "14.4px",
              marginLeft: 42,
              caretColor: "rgb(0, 0, 0)",
              color: "rgb(0, 0, 0)",
              fontFamily: "-webkit-standard",
              fontSize: 16,
              fontStyle: "normal",
              fontVariantCaps: "normal",
              fontWeight: 400,
              letterSpacing: "normal",
              orphans: "auto",
              textAlign: "start",
              textIndent: 0,
              textTransform: "none",
              whiteSpace: "normal",
              widows: "auto",
              wordSpacing: 0,
              WebkitTextStrokeWidth: 0,
              textDecoration: "none",
            }}
          >
            <span style={{ lineHeight: "14.4px" }}>
              By providing or posting any information, materials or other
              content on the Platform (
            </span>
            <span
              className="s9"
              style={{ lineHeight: "14.4px", fontWeight: "bold" }}
            >
              Posted Material
            </span>
            <span style={{ lineHeight: "14.4px" }}>
              ), you represent and warrant that:
            </span>
          </p>
          <div
            className="s16"
            style={{
              marginTop: 0,
              marginBottom: 0,
              textIndent: "-42px",
              marginLeft: 85,
              caretColor: "rgb(0, 0, 0)",
              color: "rgb(0, 0, 0)",
              fontFamily: "-webkit-standard",
              fontSize: 16,
              fontStyle: "normal",
              fontVariantCaps: "normal",
              fontWeight: 400,
              letterSpacing: "normal",
              orphans: "auto",
              textAlign: "start",
              textTransform: "none",
              whiteSpace: "normal",
              widows: "auto",
              wordSpacing: 0,
              WebkitTextStrokeWidth: 0,
              textDecoration: "none",
            }}
          >
            <span style={{ lineHeight: "14.4px" }}>
              (a)<span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>
              you are authorised to provide the Posted Material (including by
              being authorised to
              <span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>provide</span>
            <span style={{ lineHeight: "14.4px" }}>
              <span className="Apple-converted-space">&nbsp;</span>any
              <span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>services</span>
            <span style={{ lineHeight: "14.4px" }}>
              <span className="Apple-converted-space">&nbsp;</span>that you
              represent you
              <span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>provide</span>
            <span style={{ lineHeight: "14.4px" }}>);</span>
          </div>
          <div
            className="s16"
            style={{
              marginTop: 0,
              marginBottom: 0,
              textIndent: "-42px",
              marginLeft: 85,
              caretColor: "rgb(0, 0, 0)",
              color: "rgb(0, 0, 0)",
              fontFamily: "-webkit-standard",
              fontSize: 16,
              fontStyle: "normal",
              fontVariantCaps: "normal",
              fontWeight: 400,
              letterSpacing: "normal",
              orphans: "auto",
              textAlign: "start",
              textTransform: "none",
              whiteSpace: "normal",
              widows: "auto",
              wordSpacing: 0,
              WebkitTextStrokeWidth: 0,
              textDecoration: "none",
            }}
          >
            <span style={{ lineHeight: "14.4px" }}>
              (b)<span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>
              the Posted Material is accurate and true at the time it is
              provided;
            </span>
          </div>
          <div
            className="s16"
            style={{
              marginTop: 0,
              marginBottom: 0,
              textIndent: "-42px",
              marginLeft: 85,
              caretColor: "rgb(0, 0, 0)",
              color: "rgb(0, 0, 0)",
              fontFamily: "-webkit-standard",
              fontSize: 16,
              fontStyle: "normal",
              fontVariantCaps: "normal",
              fontWeight: 400,
              letterSpacing: "normal",
              orphans: "auto",
              textAlign: "start",
              textTransform: "none",
              whiteSpace: "normal",
              widows: "auto",
              wordSpacing: 0,
              WebkitTextStrokeWidth: 0,
              textDecoration: "none",
            }}
          >
            <span style={{ lineHeight: "14.4px" }}>
              (c)<span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>
              any Posted Material which is in the form of a review or feedback
              is honest, accurate and presents a fair view of the relevant
              person and/or your experience;
            </span>
          </div>
          <div
            className="s16"
            style={{
              marginTop: 0,
              marginBottom: 0,
              textIndent: "-42px",
              marginLeft: 85,
              caretColor: "rgb(0, 0, 0)",
              color: "rgb(0, 0, 0)",
              fontFamily: "-webkit-standard",
              fontSize: 16,
              fontStyle: "normal",
              fontVariantCaps: "normal",
              fontWeight: 400,
              letterSpacing: "normal",
              orphans: "auto",
              textAlign: "start",
              textTransform: "none",
              whiteSpace: "normal",
              widows: "auto",
              wordSpacing: 0,
              WebkitTextStrokeWidth: 0,
              textDecoration: "none",
            }}
          >
            <span style={{ lineHeight: "14.4px" }}>
              (d)<span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>
              the Posted Material is free from any harmful, discriminatory,
              defamatory or maliciously false implications and does not contain
              any offensive or explicit material;
            </span>
          </div>
          <div
            className="s16"
            style={{
              marginTop: 0,
              marginBottom: 0,
              textIndent: "-42px",
              marginLeft: 85,
              caretColor: "rgb(0, 0, 0)",
              color: "rgb(0, 0, 0)",
              fontFamily: "-webkit-standard",
              fontSize: 16,
              fontStyle: "normal",
              fontVariantCaps: "normal",
              fontWeight: 400,
              letterSpacing: "normal",
              orphans: "auto",
              textAlign: "start",
              textTransform: "none",
              whiteSpace: "normal",
              widows: "auto",
              wordSpacing: 0,
              WebkitTextStrokeWidth: 0,
              textDecoration: "none",
            }}
          >
            <span style={{ lineHeight: "14.4px" }}>
              (e)<span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>
              the Posted Material is not “passing off” of any
              <span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>
              product or service and does not constitute unfair competition;
            </span>
          </div>
          <div
            className="s16"
            style={{
              marginTop: 0,
              marginBottom: 0,
              textIndent: "-42px",
              marginLeft: 85,
              caretColor: "rgb(0, 0, 0)",
              color: "rgb(0, 0, 0)",
              fontFamily: "-webkit-standard",
              fontSize: 16,
              fontStyle: "normal",
              fontVariantCaps: "normal",
              fontWeight: 400,
              letterSpacing: "normal",
              orphans: "auto",
              textAlign: "start",
              textTransform: "none",
              whiteSpace: "normal",
              widows: "auto",
              wordSpacing: 0,
              WebkitTextStrokeWidth: 0,
              textDecoration: "none",
            }}
          >
            <span style={{ lineHeight: "14.4px" }}>
              (f)<span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>
              the Posted Material does not infringe any Intellectual Property
              Rights, including copyright, trademarks, business names, patents,
              confidential information or any other similar proprietary rights,
              whether registered or unregistered, anywhere in the world;
            </span>
          </div>
          <div
            className="s16"
            style={{
              marginTop: 0,
              marginBottom: 0,
              textIndent: "-42px",
              marginLeft: 85,
              caretColor: "rgb(0, 0, 0)",
              color: "rgb(0, 0, 0)",
              fontFamily: "-webkit-standard",
              fontSize: 16,
              fontStyle: "normal",
              fontVariantCaps: "normal",
              fontWeight: 400,
              letterSpacing: "normal",
              orphans: "auto",
              textAlign: "start",
              textTransform: "none",
              whiteSpace: "normal",
              widows: "auto",
              wordSpacing: 0,
              WebkitTextStrokeWidth: 0,
              textDecoration: "none",
            }}
          >
            <span style={{ lineHeight: "14.4px" }}>
              (g)<span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>
              the Posted Material does not contain any viruses or other harmful
              code, or otherwise compromise the security or integrity of the
              Platform or any network or system; and
            </span>
          </div>
          <div
            className="s16"
            style={{
              marginTop: 0,
              marginBottom: 0,
              textIndent: "-42px",
              marginLeft: 85,
              caretColor: "rgb(0, 0, 0)",
              color: "rgb(0, 0, 0)",
              fontFamily: "-webkit-standard",
              fontSize: 16,
              fontStyle: "normal",
              fontVariantCaps: "normal",
              fontWeight: 400,
              letterSpacing: "normal",
              orphans: "auto",
              textAlign: "start",
              textTransform: "none",
              whiteSpace: "normal",
              widows: "auto",
              wordSpacing: 0,
              WebkitTextStrokeWidth: 0,
              textDecoration: "none",
            }}
          >
            <span style={{ lineHeight: "14.4px" }}>
              (h)<span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>
              the Posted Material does not breach or infringe any applicable
              laws.
            </span>
          </div>
          <div
            className="s20"
            style={{
              marginTop: 12,
              marginBottom: 0,
              textIndent: "-42px",
              marginLeft: 42,
              caretColor: "rgb(0, 0, 0)",
              color: "rgb(0, 0, 0)",
              fontFamily: "-webkit-standard",
              fontSize: 16,
              fontStyle: "normal",
              fontVariantCaps: "normal",
              fontWeight: 400,
              letterSpacing: "normal",
              orphans: "auto",
              textAlign: "start",
              textTransform: "none",
              whiteSpace: "normal",
              widows: "auto",
              wordSpacing: 0,
              WebkitTextStrokeWidth: 0,
              textDecoration: "none",
            }}
          >
            <span
              className="s19"
              style={{ lineHeight: "14.4px", textTransform: "uppercase" }}
            >
              4.2<span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span
              className="s19"
              style={{ lineHeight: "14.4px", textTransform: "uppercase" }}
            >
              LICENCE
            </span>
          </div>
          <div
            className="s16"
            style={{
              marginTop: 0,
              marginBottom: 0,
              textIndent: "-42px",
              marginLeft: 85,
              caretColor: "rgb(0, 0, 0)",
              color: "rgb(0, 0, 0)",
              fontFamily: "-webkit-standard",
              fontSize: 16,
              fontStyle: "normal",
              fontVariantCaps: "normal",
              fontWeight: 400,
              letterSpacing: "normal",
              orphans: "auto",
              textAlign: "start",
              textTransform: "none",
              whiteSpace: "normal",
              widows: "auto",
              wordSpacing: 0,
              WebkitTextStrokeWidth: 0,
              textDecoration: "none",
            }}
          >
            <span style={{ lineHeight: "14.4px" }}>
              (a)<span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>
              You grant to
              <span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>As Is Auctions</span>
            <span style={{ lineHeight: "14.4px" }}>
              <span className="Apple-converted-space">&nbsp;</span>a perpetual,
              irrevocable, transferable, worldwide and royalty-free licence
              (including the right to sublicense) to use, copy, modify,
              reproduce and adapt any Intellectual Property Rights in any Posted
              Material in order for
              <span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>As Is Auctions</span>
            <span style={{ lineHeight: "14.4px" }}>
              <span className="Apple-converted-space">&nbsp;</span>to use,
              exploit or otherwise enjoy the benefit of such Posted Material
            </span>
            <span style={{ lineHeight: "14.4px" }}>.</span>
          </div>
          <div
            className="s16"
            style={{
              marginTop: 0,
              marginBottom: 0,
              textIndent: "-42px",
              marginLeft: 85,
              caretColor: "rgb(0, 0, 0)",
              color: "rgb(0, 0, 0)",
              fontFamily: "-webkit-standard",
              fontSize: 16,
              fontStyle: "normal",
              fontVariantCaps: "normal",
              fontWeight: 400,
              letterSpacing: "normal",
              orphans: "auto",
              textAlign: "start",
              textTransform: "none",
              whiteSpace: "normal",
              widows: "auto",
              wordSpacing: 0,
              WebkitTextStrokeWidth: 0,
              textDecoration: "none",
            }}
          >
            <span style={{ lineHeight: "14.4px" }}>
              (b)<span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>
              If it is determined that you retain moral rights (including rights
              of attribution or integrity) in any Posted Material, you forever
              release
              <span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>As Is Auctions</span>
            <span style={{ lineHeight: "14.4px" }}>
              <span className="Apple-converted-space">&nbsp;</span>from any and
              all claims that you could assert against
              <span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>As Is Auctions</span>
            <span style={{ lineHeight: "14.4px" }}>
              <span className="Apple-converted-space">&nbsp;</span>by virtue of
              any such moral rights.
            </span>
          </div>
          <div
            className="s16"
            style={{
              marginTop: 0,
              marginBottom: 0,
              textIndent: "-42px",
              marginLeft: 85,
              caretColor: "rgb(0, 0, 0)",
              color: "rgb(0, 0, 0)",
              fontFamily: "-webkit-standard",
              fontSize: 16,
              fontStyle: "normal",
              fontVariantCaps: "normal",
              fontWeight: 400,
              letterSpacing: "normal",
              orphans: "auto",
              textAlign: "start",
              textTransform: "none",
              whiteSpace: "normal",
              widows: "auto",
              wordSpacing: 0,
              WebkitTextStrokeWidth: 0,
              textDecoration: "none",
            }}
          >
            <span style={{ lineHeight: "14.4px" }}>
              (c)<span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>
              You indemnify
              <span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>As Is Auctions</span>
            <span style={{ lineHeight: "14.4px" }}>
              <span className="Apple-converted-space">&nbsp;</span>against all
              damages, losses, costs and expenses incurred by
              <span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>As Is Auctions</span>
            <span style={{ lineHeight: "14.4px" }}>
              <span className="Apple-converted-space">&nbsp;</span>arising out
              of any third party claim that your Posted Material infringes any
              third party’s Intellectual Property Rights.
            </span>
          </div>
          <div
            className="s20"
            style={{
              marginTop: 12,
              marginBottom: 0,
              textIndent: "-42px",
              marginLeft: 42,
              caretColor: "rgb(0, 0, 0)",
              color: "rgb(0, 0, 0)",
              fontFamily: "-webkit-standard",
              fontSize: 16,
              fontStyle: "normal",
              fontVariantCaps: "normal",
              fontWeight: 400,
              letterSpacing: "normal",
              orphans: "auto",
              textAlign: "start",
              textTransform: "none",
              whiteSpace: "normal",
              widows: "auto",
              wordSpacing: 0,
              WebkitTextStrokeWidth: 0,
              textDecoration: "none",
            }}
          >
            <span
              className="s19"
              style={{ lineHeight: "14.4px", textTransform: "uppercase" }}
            >
              4.3<span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span
              className="s19"
              style={{ lineHeight: "14.4px", textTransform: "uppercase" }}
            >
              REMOVAL
            </span>
          </div>
          <div
            className="s16"
            style={{
              marginTop: 0,
              marginBottom: 0,
              textIndent: "-42px",
              marginLeft: 85,
              caretColor: "rgb(0, 0, 0)",
              color: "rgb(0, 0, 0)",
              fontFamily: "-webkit-standard",
              fontSize: 16,
              fontStyle: "normal",
              fontVariantCaps: "normal",
              fontWeight: 400,
              letterSpacing: "normal",
              orphans: "auto",
              textAlign: "start",
              textTransform: "none",
              whiteSpace: "normal",
              widows: "auto",
              wordSpacing: 0,
              WebkitTextStrokeWidth: 0,
              textDecoration: "none",
            }}
          >
            <span style={{ lineHeight: "14.4px" }}>
              (a)<span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>As Is Auctions</span>
            <span style={{ lineHeight: "14.4px" }}>
              <span className="Apple-converted-space">&nbsp;</span>acts as a
              passive conduit for the online distribution of Posted Material and
              has no obligation to screen Posted Material in advance of it being
              posted. However,
              <span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>As Is Auctions</span>
            <span style={{ lineHeight: "14.4px" }}>
              <span className="Apple-converted-space">&nbsp;</span>may, in its
              absolute discretion, review and remove any Posted Material
              (including links to you, your profile or listings you have posted
              on the Platform) at any time without giving any explanation or
              justification for removing the Posted Material.
            </span>
          </div>
          <div
            className="s16"
            style={{
              marginTop: 0,
              marginBottom: 0,
              textIndent: "-42px",
              marginLeft: 85,
              caretColor: "rgb(0, 0, 0)",
              color: "rgb(0, 0, 0)",
              fontFamily: "-webkit-standard",
              fontSize: 16,
              fontStyle: "normal",
              fontVariantCaps: "normal",
              fontWeight: 400,
              letterSpacing: "normal",
              orphans: "auto",
              textAlign: "start",
              textTransform: "none",
              whiteSpace: "normal",
              widows: "auto",
              wordSpacing: 0,
              WebkitTextStrokeWidth: 0,
              textDecoration: "none",
            }}
          >
            <span style={{ lineHeight: "14.4px" }}>
              (b)<span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>
              You agree that you are responsible for keeping and maintaining
              records of Posted Material.
            </span>
          </div>
          <div
            className="s15"
            style={{
              marginTop: 18,
              marginBottom: 0,
              textIndent: "-42px",
              marginLeft: 42,
              caretColor: "rgb(0, 0, 0)",
              color: "rgb(0, 0, 0)",
              fontFamily: "-webkit-standard",
              fontSize: 16,
              fontStyle: "normal",
              fontVariantCaps: "normal",
              fontWeight: 400,
              letterSpacing: "normal",
              orphans: "auto",
              textAlign: "start",
              textTransform: "none",
              whiteSpace: "normal",
              widows: "auto",
              wordSpacing: 0,
              WebkitTextStrokeWidth: 0,
              textDecoration: "none",
            }}
          >
            <span
              className="s14"
              style={{
                lineHeight: "14.4px",
                textTransform: "uppercase",
                fontWeight: "bold",
              }}
            >
              5<span className="Apple-converted-space">&nbsp;</span>
            </span>
            <a name="_heading=h.tyjcwt" />
            <span
              className="s14"
              style={{
                lineHeight: "14.4px",
                textTransform: "uppercase",
                fontWeight: "bold",
              }}
            >
              REFUNDS, SERVICE INTERRUPTIONS AND CANCELLATIONS
            </span>
          </div>
          <p
            className="s18"
            style={{
              marginTop: 0,
              marginBottom: 0,
              lineHeight: "14.4px",
              marginLeft: 42,
              caretColor: "rgb(0, 0, 0)",
              color: "rgb(0, 0, 0)",
              fontFamily: "-webkit-standard",
              fontSize: 16,
              fontStyle: "normal",
              fontVariantCaps: "normal",
              fontWeight: 400,
              letterSpacing: "normal",
              orphans: "auto",
              textAlign: "start",
              textIndent: 0,
              textTransform: "none",
              whiteSpace: "normal",
              widows: "auto",
              wordSpacing: 0,
              WebkitTextStrokeWidth: 0,
              textDecoration: "none",
            }}
          >
            <span style={{ lineHeight: "14.4px" }}>
              To the maximum extent permitted by law,
              <span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>As Is Auctions</span>
            <span style={{ lineHeight: "14.4px" }}>
              <span className="Apple-converted-space">&nbsp;</span>will have no
              liability or obligation to you if:
            </span>
          </p>
          <div
            className="s16"
            style={{
              marginTop: 0,
              marginBottom: 0,
              textIndent: "-42px",
              marginLeft: 85,
              caretColor: "rgb(0, 0, 0)",
              color: "rgb(0, 0, 0)",
              fontFamily: "-webkit-standard",
              fontSize: 16,
              fontStyle: "normal",
              fontVariantCaps: "normal",
              fontWeight: 400,
              letterSpacing: "normal",
              orphans: "auto",
              textAlign: "start",
              textTransform: "none",
              whiteSpace: "normal",
              widows: "auto",
              wordSpacing: 0,
              WebkitTextStrokeWidth: 0,
              textDecoration: "none",
            }}
          >
            <span style={{ lineHeight: "14.4px" }}>
              (a)<span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>
              a<span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>Buyer</span>
            <span style={{ lineHeight: "14.4px" }}>
              <span className="Apple-converted-space">&nbsp;</span>or
              <span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>Seller</span>
            <span style={{ lineHeight: "14.4px" }}>
              <span className="Apple-converted-space">&nbsp;</span>cancels at
              any time after the
              <span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>auction</span>
            <span style={{ lineHeight: "14.4px" }}>
              <span className="Apple-converted-space">&nbsp;</span>or sale
            </span>
            <span style={{ lineHeight: "14.4px" }}>
              <span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>of</span>
            <span style={{ lineHeight: "14.4px" }}>
              <span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>an Item</span>
            <span style={{ lineHeight: "14.4px" }}>
              <span className="Apple-converted-space">&nbsp;</span>has ended
            </span>
            <span style={{ lineHeight: "14.4px" }}>
              ; or<span className="Apple-converted-space">&nbsp;</span>
            </span>
          </div>
          <div
            className="s16"
            style={{
              marginTop: 0,
              marginBottom: 0,
              textIndent: "-42px",
              marginLeft: 85,
              caretColor: "rgb(0, 0, 0)",
              color: "rgb(0, 0, 0)",
              fontFamily: "-webkit-standard",
              fontSize: 16,
              fontStyle: "normal",
              fontVariantCaps: "normal",
              fontWeight: 400,
              letterSpacing: "normal",
              orphans: "auto",
              textAlign: "start",
              textTransform: "none",
              whiteSpace: "normal",
              widows: "auto",
              wordSpacing: 0,
              WebkitTextStrokeWidth: 0,
              textDecoration: "none",
            }}
          >
            <span style={{ lineHeight: "14.4px" }}>
              (b)<span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>
              for whatever reason, including technical faults, the
              <span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>Item</span>
            <span style={{ lineHeight: "14.4px" }}>
              <span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>
              in a<span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>Listing</span>
            <span style={{ lineHeight: "14.4px" }}>
              <span className="Apple-converted-space">&nbsp;</span>cannot be
              <span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>delivered or collected</span>
            <span style={{ lineHeight: "14.4px" }}>,</span>
          </div>
          <p
            className="s18"
            style={{
              marginTop: 0,
              marginBottom: 0,
              lineHeight: "14.4px",
              marginLeft: 42,
              caretColor: "rgb(0, 0, 0)",
              color: "rgb(0, 0, 0)",
              fontFamily: "-webkit-standard",
              fontSize: 16,
              fontStyle: "normal",
              fontVariantCaps: "normal",
              fontWeight: 400,
              letterSpacing: "normal",
              orphans: "auto",
              textAlign: "start",
              textIndent: 0,
              textTransform: "none",
              whiteSpace: "normal",
              widows: "auto",
              wordSpacing: 0,
              WebkitTextStrokeWidth: 0,
              textDecoration: "none",
            }}
          >
            <span style={{ lineHeight: "14.4px" }}>
              and you will not be entitled to any compensation from
              <span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>As Is Auctions</span>
            <span style={{ lineHeight: "14.4px" }}>.</span>
          </p>
          <div
            className="s15"
            style={{
              marginTop: 18,
              marginBottom: 0,
              textIndent: "-42px",
              marginLeft: 42,
              caretColor: "rgb(0, 0, 0)",
              color: "rgb(0, 0, 0)",
              fontFamily: "-webkit-standard",
              fontSize: 16,
              fontStyle: "normal",
              fontVariantCaps: "normal",
              fontWeight: 400,
              letterSpacing: "normal",
              orphans: "auto",
              textAlign: "start",
              textTransform: "none",
              whiteSpace: "normal",
              widows: "auto",
              wordSpacing: 0,
              WebkitTextStrokeWidth: 0,
              textDecoration: "none",
            }}
          >
            <span
              className="s14"
              style={{
                lineHeight: "14.4px",
                textTransform: "uppercase",
                fontWeight: "bold",
              }}
            >
              6<span className="Apple-converted-space">&nbsp;</span>
            </span>
            <a name="_heading=h.3dy6vkm" />
            <span
              className="s14"
              style={{
                lineHeight: "14.4px",
                textTransform: "uppercase",
                fontWeight: "bold",
              }}
            >
              IDENTITY VERIFICATION
            </span>
          </div>
          <div
            className="s16"
            style={{
              marginTop: 0,
              marginBottom: 0,
              textIndent: "-42px",
              marginLeft: 85,
              caretColor: "rgb(0, 0, 0)",
              color: "rgb(0, 0, 0)",
              fontFamily: "-webkit-standard",
              fontSize: 16,
              fontStyle: "normal",
              fontVariantCaps: "normal",
              fontWeight: 400,
              letterSpacing: "normal",
              orphans: "auto",
              textAlign: "start",
              textTransform: "none",
              whiteSpace: "normal",
              widows: "auto",
              wordSpacing: 0,
              WebkitTextStrokeWidth: 0,
              textDecoration: "none",
            }}
          >
            <span style={{ lineHeight: "14.4px" }}>
              (a)<span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>(</span>
            <span
              className="s9"
              style={{ lineHeight: "14.4px", fontWeight: "bold" }}
            >
              Verification
            </span>
            <span style={{ lineHeight: "14.4px" }}>
              ) We may offer or require Users to verify their details (including
              <span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>their identity</span>
            <span style={{ lineHeight: "14.4px" }}>
              ) using our processes or an external identity verification service
              as applicable (
            </span>
            <span
              className="s9"
              style={{ lineHeight: "14.4px", fontWeight: "bold" }}
            >
              Verification Service
            </span>
            <span style={{ lineHeight: "14.4px" }}>). &nbsp;</span>
          </div>
          <div
            className="s16"
            style={{
              marginTop: 0,
              marginBottom: 0,
              textIndent: "-42px",
              marginLeft: 85,
              caretColor: "rgb(0, 0, 0)",
              color: "rgb(0, 0, 0)",
              fontFamily: "-webkit-standard",
              fontSize: 16,
              fontStyle: "normal",
              fontVariantCaps: "normal",
              fontWeight: 400,
              letterSpacing: "normal",
              orphans: "auto",
              textAlign: "start",
              textTransform: "none",
              whiteSpace: "normal",
              widows: "auto",
              wordSpacing: 0,
              WebkitTextStrokeWidth: 0,
              textDecoration: "none",
            }}
          >
            <span style={{ lineHeight: "14.4px" }}>
              (b)<span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>(</span>
            <span
              className="s9"
              style={{ lineHeight: "14.4px", fontWeight: "bold" }}
            >
              Your personal information and privacy
            </span>
            <span style={{ lineHeight: "14.4px" }}>
              ) We will collect your personal information in accordance with our
              Privacy Policy as set out in clause
              <span className="Apple-converted-space">&nbsp;</span>
            </span>
            <a>
              <span style={{ lineHeight: "14.4px" }}>17</span>
            </a>
            <span style={{ lineHeight: "14.4px" }}>
              . Where a Verification Service is used, you acknowledge and agree
              that:<span className="Apple-converted-space">&nbsp;</span>
            </span>
          </div>
          <div
            className="s17"
            style={{
              marginTop: 0,
              marginBottom: 0,
              textIndent: "-42px",
              marginLeft: 127,
              caretColor: "rgb(0, 0, 0)",
              color: "rgb(0, 0, 0)",
              fontFamily: "-webkit-standard",
              fontSize: 16,
              fontStyle: "normal",
              fontVariantCaps: "normal",
              fontWeight: 400,
              letterSpacing: "normal",
              orphans: "auto",
              textAlign: "start",
              textTransform: "none",
              whiteSpace: "normal",
              widows: "auto",
              wordSpacing: 0,
              WebkitTextStrokeWidth: 0,
              textDecoration: "none",
            }}
          >
            <span style={{ lineHeight: "14.4px" }}>
              (i)<span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>
              we may contact and share your personal information with a
              Verification Service to verify your details;
              <span className="Apple-converted-space">&nbsp;</span>
            </span>
          </div>
          <div
            className="s17"
            style={{
              marginTop: 0,
              marginBottom: 0,
              textIndent: "-42px",
              marginLeft: 127,
              caretColor: "rgb(0, 0, 0)",
              color: "rgb(0, 0, 0)",
              fontFamily: "-webkit-standard",
              fontSize: 16,
              fontStyle: "normal",
              fontVariantCaps: "normal",
              fontWeight: 400,
              letterSpacing: "normal",
              orphans: "auto",
              textAlign: "start",
              textTransform: "none",
              whiteSpace: "normal",
              widows: "auto",
              wordSpacing: 0,
              WebkitTextStrokeWidth: 0,
              textDecoration: "none",
            }}
          >
            <span style={{ lineHeight: "14.4px" }}>
              (ii)<span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>
              you consent to us receiving, sharing and using this information to
              enable us to carry out the Verification Service.
            </span>
          </div>
          <div
            className="s16"
            style={{
              marginTop: 0,
              marginBottom: 0,
              textIndent: "-42px",
              marginLeft: 85,
              caretColor: "rgb(0, 0, 0)",
              color: "rgb(0, 0, 0)",
              fontFamily: "-webkit-standard",
              fontSize: 16,
              fontStyle: "normal",
              fontVariantCaps: "normal",
              fontWeight: 400,
              letterSpacing: "normal",
              orphans: "auto",
              textAlign: "start",
              textTransform: "none",
              whiteSpace: "normal",
              widows: "auto",
              wordSpacing: 0,
              WebkitTextStrokeWidth: 0,
              textDecoration: "none",
            }}
          >
            <span style={{ lineHeight: "14.4px" }}>
              (c)<span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>(</span>
            <span
              className="s9"
              style={{ lineHeight: "14.4px", fontWeight: "bold" }}
            >
              Fees
            </span>
            <span style={{ lineHeight: "14.4px" }}>
              ) We may charge non-refundable fees for the Verification Service,
              as set out on the Platform. &nbsp;
            </span>
          </div>
          <div
            className="s16"
            style={{
              marginTop: 0,
              marginBottom: 0,
              textIndent: "-42px",
              marginLeft: 85,
              caretColor: "rgb(0, 0, 0)",
              color: "rgb(0, 0, 0)",
              fontFamily: "-webkit-standard",
              fontSize: 16,
              fontStyle: "normal",
              fontVariantCaps: "normal",
              fontWeight: 400,
              letterSpacing: "normal",
              orphans: "auto",
              textAlign: "start",
              textTransform: "none",
              whiteSpace: "normal",
              widows: "auto",
              wordSpacing: 0,
              WebkitTextStrokeWidth: 0,
              textDecoration: "none",
            }}
          >
            <span style={{ lineHeight: "14.4px" }}>
              (d)<span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>(</span>
            <span
              className="s9"
              style={{ lineHeight: "14.4px", fontWeight: "bold" }}
            >
              Warranty and Indemnity
            </span>
            <span style={{ lineHeight: "14.4px" }}>
              ) You acknowledge and agree that:
            </span>
          </div>
          <div
            className="s17"
            style={{
              marginTop: 0,
              marginBottom: 0,
              textIndent: "-42px",
              marginLeft: 127,
              caretColor: "rgb(0, 0, 0)",
              color: "rgb(0, 0, 0)",
              fontFamily: "-webkit-standard",
              fontSize: 16,
              fontStyle: "normal",
              fontVariantCaps: "normal",
              fontWeight: 400,
              letterSpacing: "normal",
              orphans: "auto",
              textAlign: "start",
              textTransform: "none",
              whiteSpace: "normal",
              widows: "auto",
              wordSpacing: 0,
              WebkitTextStrokeWidth: 0,
              textDecoration: "none",
            }}
          >
            <span style={{ lineHeight: "14.4px" }}>
              (i)<span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>
              we are reliant on the information provided by the Verification
              Service to verify your identity and to the extent permitted by
              law, we disclaim all warranties that the Verification Service will
              be accurate or guarantee that the Verification Service will ensure
              you contract with a suitable User;
              <span className="Apple-converted-space">&nbsp;</span>
            </span>
          </div>
          <div
            className="s17"
            style={{
              marginTop: 0,
              marginBottom: 0,
              textIndent: "-42px",
              marginLeft: 127,
              caretColor: "rgb(0, 0, 0)",
              color: "rgb(0, 0, 0)",
              fontFamily: "-webkit-standard",
              fontSize: 16,
              fontStyle: "normal",
              fontVariantCaps: "normal",
              fontWeight: 400,
              letterSpacing: "normal",
              orphans: "auto",
              textAlign: "start",
              textTransform: "none",
              whiteSpace: "normal",
              widows: "auto",
              wordSpacing: 0,
              WebkitTextStrokeWidth: 0,
              textDecoration: "none",
            }}
          >
            <span style={{ lineHeight: "14.4px" }}>
              (ii)<span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>
              you should make your own inquiries as to other Users’ identities
              before engaging in contracts with those Users; and
            </span>
          </div>
          <div
            className="s17"
            style={{
              marginTop: 0,
              marginBottom: 0,
              textIndent: "-42px",
              marginLeft: 127,
              caretColor: "rgb(0, 0, 0)",
              color: "rgb(0, 0, 0)",
              fontFamily: "-webkit-standard",
              fontSize: 16,
              fontStyle: "normal",
              fontVariantCaps: "normal",
              fontWeight: 400,
              letterSpacing: "normal",
              orphans: "auto",
              textAlign: "start",
              textTransform: "none",
              whiteSpace: "normal",
              widows: "auto",
              wordSpacing: 0,
              WebkitTextStrokeWidth: 0,
              textDecoration: "none",
            }}
          >
            <span style={{ lineHeight: "14.4px" }}>
              (iii)<span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>
              we do not endorse any User,
              <span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>Listing</span>
            <span style={{ lineHeight: "14.4px" }}>
              <span className="Apple-converted-space">&nbsp;</span>or
              Verification Service.
            </span>
          </div>
          <div
            className="s15"
            style={{
              marginTop: 18,
              marginBottom: 0,
              textIndent: "-42px",
              marginLeft: 42,
              caretColor: "rgb(0, 0, 0)",
              color: "rgb(0, 0, 0)",
              fontFamily: "-webkit-standard",
              fontSize: 16,
              fontStyle: "normal",
              fontVariantCaps: "normal",
              fontWeight: 400,
              letterSpacing: "normal",
              orphans: "auto",
              textAlign: "start",
              textTransform: "none",
              whiteSpace: "normal",
              widows: "auto",
              wordSpacing: 0,
              WebkitTextStrokeWidth: 0,
              textDecoration: "none",
            }}
          >
            <span
              className="s14"
              style={{
                lineHeight: "14.4px",
                textTransform: "uppercase",
                fontWeight: "bold",
              }}
            >
              7<span className="Apple-converted-space">&nbsp;</span>
            </span>
            <a name="_heading=h.1t3h5sf" />
            <span
              className="s14"
              style={{
                lineHeight: "14.4px",
                textTransform: "uppercase",
                fontWeight: "bold",
              }}
            >
              ONLINE PAYMENT PARTNER
            </span>
          </div>
          <div
            className="s16"
            style={{
              marginTop: 0,
              marginBottom: 0,
              textIndent: "-42px",
              marginLeft: 85,
              caretColor: "rgb(0, 0, 0)",
              color: "rgb(0, 0, 0)",
              fontFamily: "-webkit-standard",
              fontSize: 16,
              fontStyle: "normal",
              fontVariantCaps: "normal",
              fontWeight: 400,
              letterSpacing: "normal",
              orphans: "auto",
              textAlign: "start",
              textTransform: "none",
              whiteSpace: "normal",
              widows: "auto",
              wordSpacing: 0,
              WebkitTextStrokeWidth: 0,
              textDecoration: "none",
            }}
          >
            <span style={{ lineHeight: "14.4px" }}>
              (a)<span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>
              We may use third-party online payment partner, currently
            </span>
            <span style={{ lineHeight: "14.4px" }}>
              <span className="Apple-converted-space">&nbsp;</span>PayPal
            </span>
            <span style={{ lineHeight: "14.4px" }}>
              <span className="Apple-converted-space">&nbsp;</span>(
            </span>
            <span
              className="s9"
              style={{ lineHeight: "14.4px", fontWeight: "bold" }}
            >
              Online Payment Partner
            </span>
            <span style={{ lineHeight: "14.4px" }}>
              ) to collect payments on the Platform, including for our Services.
            </span>
          </div>
          <div
            className="s16"
            style={{
              marginTop: 0,
              marginBottom: 0,
              textIndent: "-42px",
              marginLeft: 85,
              caretColor: "rgb(0, 0, 0)",
              color: "rgb(0, 0, 0)",
              fontFamily: "-webkit-standard",
              fontSize: 16,
              fontStyle: "normal",
              fontVariantCaps: "normal",
              fontWeight: 400,
              letterSpacing: "normal",
              orphans: "auto",
              textAlign: "start",
              textTransform: "none",
              whiteSpace: "normal",
              widows: "auto",
              wordSpacing: 0,
              WebkitTextStrokeWidth: 0,
              textDecoration: "none",
            }}
          >
            <span style={{ lineHeight: "14.4px" }}>
              (b)<span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>
              The processing of payments by the Online Payment Partner will be,
              in addition to this agreement, subject to the terms, conditions
              and privacy policies of the Online Payment Partner.
            </span>
          </div>
          <div
            className="s16"
            style={{
              marginTop: 0,
              marginBottom: 0,
              textIndent: "-42px",
              marginLeft: 85,
              caretColor: "rgb(0, 0, 0)",
              color: "rgb(0, 0, 0)",
              fontFamily: "-webkit-standard",
              fontSize: 16,
              fontStyle: "normal",
              fontVariantCaps: "normal",
              fontWeight: 400,
              letterSpacing: "normal",
              orphans: "auto",
              textAlign: "start",
              textTransform: "none",
              whiteSpace: "normal",
              widows: "auto",
              wordSpacing: 0,
              WebkitTextStrokeWidth: 0,
              textDecoration: "none",
            }}
          >
            <span style={{ lineHeight: "14.4px" }}>
              (c)<span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>
              You agree to release
              <span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>As Is Auctions</span>
            <span style={{ lineHeight: "14.4px" }}>
              <span className="Apple-converted-space">&nbsp;</span>and its
              employees and agents in respect of all liability for loss, damage
              or injury which may be suffered by any person arising from any act
              or omission of the Online Payment Partner, including any issue
              with security or performance of the Online Payment Partner’s
              platform or any error or mistake in processing your payment.
            </span>
          </div>
          <div
            className="s16"
            style={{
              marginTop: 0,
              marginBottom: 0,
              textIndent: "-42px",
              marginLeft: 85,
              caretColor: "rgb(0, 0, 0)",
              color: "rgb(0, 0, 0)",
              fontFamily: "-webkit-standard",
              fontSize: 16,
              fontStyle: "normal",
              fontVariantCaps: "normal",
              fontWeight: 400,
              letterSpacing: "normal",
              orphans: "auto",
              textAlign: "start",
              textTransform: "none",
              whiteSpace: "normal",
              widows: "auto",
              wordSpacing: 0,
              WebkitTextStrokeWidth: 0,
              textDecoration: "none",
            }}
          >
            <span style={{ lineHeight: "14.4px" }}>
              (d)<span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>
              We reserve the right to correct, or to instruct our Online Payment
              Partner to correct, any errors or mistakes in collecting your
              payment.
              <span className="Apple-converted-space">&nbsp;</span>
            </span>
          </div>
          <div
            className="s15"
            style={{
              marginTop: 18,
              marginBottom: 0,
              textIndent: "-42px",
              marginLeft: 42,
              caretColor: "rgb(0, 0, 0)",
              color: "rgb(0, 0, 0)",
              fontFamily: "-webkit-standard",
              fontSize: 16,
              fontStyle: "normal",
              fontVariantCaps: "normal",
              fontWeight: 400,
              letterSpacing: "normal",
              orphans: "auto",
              textAlign: "start",
              textTransform: "none",
              whiteSpace: "normal",
              widows: "auto",
              wordSpacing: 0,
              WebkitTextStrokeWidth: 0,
              textDecoration: "none",
            }}
          >
            <span
              className="s14"
              style={{
                lineHeight: "14.4px",
                textTransform: "uppercase",
                fontWeight: "bold",
              }}
            >
              8<span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span
              className="s14"
              style={{
                lineHeight: "14.4px",
                textTransform: "uppercase",
                fontWeight: "bold",
              }}
            >
              SERVICE LIMITATIONS
            </span>
          </div>
          <p
            className="s18"
            style={{
              marginTop: 0,
              marginBottom: 0,
              lineHeight: "14.4px",
              marginLeft: 42,
              caretColor: "rgb(0, 0, 0)",
              color: "rgb(0, 0, 0)",
              fontFamily: "-webkit-standard",
              fontSize: 16,
              fontStyle: "normal",
              fontVariantCaps: "normal",
              fontWeight: 400,
              letterSpacing: "normal",
              orphans: "auto",
              textAlign: "start",
              textIndent: 0,
              textTransform: "none",
              whiteSpace: "normal",
              widows: "auto",
              wordSpacing: 0,
              WebkitTextStrokeWidth: 0,
              textDecoration: "none",
            }}
          >
            <span style={{ lineHeight: "14.4px" }}>
              While we make every effort to ensure that the information on the
              Platform is as up-to-date and accurate as possible, you
              acknowledge and agree that from time to time, you may encounter
              the following issues:
            </span>
          </p>
          <div
            className="s16"
            style={{
              marginTop: 0,
              marginBottom: 0,
              textIndent: "-42px",
              marginLeft: 85,
              caretColor: "rgb(0, 0, 0)",
              color: "rgb(0, 0, 0)",
              fontFamily: "-webkit-standard",
              fontSize: 16,
              fontStyle: "normal",
              fontVariantCaps: "normal",
              fontWeight: 400,
              letterSpacing: "normal",
              orphans: "auto",
              textAlign: "start",
              textTransform: "none",
              whiteSpace: "normal",
              widows: "auto",
              wordSpacing: 0,
              WebkitTextStrokeWidth: 0,
              textDecoration: "none",
            }}
          >
            <span style={{ lineHeight: "14.4px" }}>
              (a)<span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>
              the Platform may have errors or defects (or both, as the case may
              be);
            </span>
          </div>
          <div
            className="s16"
            style={{
              marginTop: 0,
              marginBottom: 0,
              textIndent: "-42px",
              marginLeft: 85,
              caretColor: "rgb(0, 0, 0)",
              color: "rgb(0, 0, 0)",
              fontFamily: "-webkit-standard",
              fontSize: 16,
              fontStyle: "normal",
              fontVariantCaps: "normal",
              fontWeight: 400,
              letterSpacing: "normal",
              orphans: "auto",
              textAlign: "start",
              textTransform: "none",
              whiteSpace: "normal",
              widows: "auto",
              wordSpacing: 0,
              WebkitTextStrokeWidth: 0,
              textDecoration: "none",
            }}
          >
            <span style={{ lineHeight: "14.4px" }}>
              (b)<span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>
              the Platform may not be accessible at times;
            </span>
          </div>
          <div
            className="s16"
            style={{
              marginTop: 0,
              marginBottom: 0,
              textIndent: "-42px",
              marginLeft: 85,
              caretColor: "rgb(0, 0, 0)",
              color: "rgb(0, 0, 0)",
              fontFamily: "-webkit-standard",
              fontSize: 16,
              fontStyle: "normal",
              fontVariantCaps: "normal",
              fontWeight: 400,
              letterSpacing: "normal",
              orphans: "auto",
              textAlign: "start",
              textTransform: "none",
              whiteSpace: "normal",
              widows: "auto",
              wordSpacing: 0,
              WebkitTextStrokeWidth: 0,
              textDecoration: "none",
            }}
          >
            <span style={{ lineHeight: "14.4px" }}>
              (c)<span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>
              messages sent through the Platform may not be delivered promptly,
              or delivered at all;
            </span>
          </div>
          <div
            className="s16"
            style={{
              marginTop: 0,
              marginBottom: 0,
              textIndent: "-42px",
              marginLeft: 85,
              caretColor: "rgb(0, 0, 0)",
              color: "rgb(0, 0, 0)",
              fontFamily: "-webkit-standard",
              fontSize: 16,
              fontStyle: "normal",
              fontVariantCaps: "normal",
              fontWeight: 400,
              letterSpacing: "normal",
              orphans: "auto",
              textAlign: "start",
              textTransform: "none",
              whiteSpace: "normal",
              widows: "auto",
              wordSpacing: 0,
              WebkitTextStrokeWidth: 0,
              textDecoration: "none",
            }}
          >
            <span style={{ lineHeight: "14.4px" }}>
              (d)<span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>
              information you receive or supply through the Platform may not be
              secure or confidential; and
            </span>
          </div>
          <div
            className="s16"
            style={{
              marginTop: 0,
              marginBottom: 0,
              textIndent: "-42px",
              marginLeft: 85,
              caretColor: "rgb(0, 0, 0)",
              color: "rgb(0, 0, 0)",
              fontFamily: "-webkit-standard",
              fontSize: 16,
              fontStyle: "normal",
              fontVariantCaps: "normal",
              fontWeight: 400,
              letterSpacing: "normal",
              orphans: "auto",
              textAlign: "start",
              textTransform: "none",
              whiteSpace: "normal",
              widows: "auto",
              wordSpacing: 0,
              WebkitTextStrokeWidth: 0,
              textDecoration: "none",
            }}
          >
            <span style={{ lineHeight: "14.4px" }}>
              (e)<span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>
              any information provided through the Platform may not be accurate
              or true.
            </span>
          </div>
          <div
            className="s15"
            style={{
              marginTop: 18,
              marginBottom: 0,
              textIndent: "-42px",
              marginLeft: 42,
              caretColor: "rgb(0, 0, 0)",
              color: "rgb(0, 0, 0)",
              fontFamily: "-webkit-standard",
              fontSize: 16,
              fontStyle: "normal",
              fontVariantCaps: "normal",
              fontWeight: 400,
              letterSpacing: "normal",
              orphans: "auto",
              textAlign: "start",
              textTransform: "none",
              whiteSpace: "normal",
              widows: "auto",
              wordSpacing: 0,
              WebkitTextStrokeWidth: 0,
              textDecoration: "none",
            }}
          >
            <span
              className="s14"
              style={{
                lineHeight: "14.4px",
                textTransform: "uppercase",
                fontWeight: "bold",
              }}
            >
              9<span className="Apple-converted-space">&nbsp;</span>
            </span>
            <a name="_heading=h.4d34og8" />
            <a name="_Ref124435152" />
            <span
              className="s14"
              style={{
                lineHeight: "14.4px",
                textTransform: "uppercase",
                fontWeight: "bold",
              }}
            >
              INTELLECTUAL PROPERTY
            </span>
          </div>
          <div
            className="s16"
            style={{
              marginTop: 0,
              marginBottom: 0,
              textIndent: "-42px",
              marginLeft: 85,
              caretColor: "rgb(0, 0, 0)",
              color: "rgb(0, 0, 0)",
              fontFamily: "-webkit-standard",
              fontSize: 16,
              fontStyle: "normal",
              fontVariantCaps: "normal",
              fontWeight: 400,
              letterSpacing: "normal",
              orphans: "auto",
              textAlign: "start",
              textTransform: "none",
              whiteSpace: "normal",
              widows: "auto",
              wordSpacing: 0,
              WebkitTextStrokeWidth: 0,
              textDecoration: "none",
            }}
          >
            <span style={{ lineHeight: "14.4px" }}>
              (a)<span className="Apple-converted-space">&nbsp;</span>
            </span>
            <a name="_heading=h.2s8eyo1" />
            <span style={{ lineHeight: "14.4px" }}>As Is Auctions</span>
            <span style={{ lineHeight: "14.4px" }}>
              <span className="Apple-converted-space">&nbsp;</span>retains
              ownership
              <span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>of all</span>
            <span style={{ lineHeight: "14.4px" }}>
              <span className="Apple-converted-space">&nbsp;</span>materials
              developed or provided (or both, as the case may be) in connection
              with the Services (including
              <span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span
              className="s9"
              style={{ lineHeight: "14.4px", fontWeight: "bold" }}
            >
              text, graphics, logos, design, icons, images, sound and video
              recordings, pricing, downloads and software
            </span>
            <span style={{ lineHeight: "14.4px" }}>)</span>
            <span style={{ lineHeight: "14.4px" }}>
              <span className="Apple-converted-space">&nbsp;</span>(
            </span>
            <span
              className="s9"
              style={{ lineHeight: "14.4px", fontWeight: "bold" }}
            >
              Service
            </span>
            <span style={{ lineHeight: "14.4px" }}>
              <span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span
              className="s9"
              style={{ lineHeight: "14.4px", fontWeight: "bold" }}
            >
              Content
            </span>
            <span style={{ lineHeight: "14.4px" }}>
              ) and reserves all rights in any Intellectual Property Rights
              owned or licensed by it not expressly granted to you.
            </span>
          </div>
          <div
            className="s16"
            style={{
              marginTop: 0,
              marginBottom: 0,
              textIndent: "-42px",
              marginLeft: 85,
              caretColor: "rgb(0, 0, 0)",
              color: "rgb(0, 0, 0)",
              fontFamily: "-webkit-standard",
              fontSize: 16,
              fontStyle: "normal",
              fontVariantCaps: "normal",
              fontWeight: 400,
              letterSpacing: "normal",
              orphans: "auto",
              textAlign: "start",
              textTransform: "none",
              whiteSpace: "normal",
              widows: "auto",
              wordSpacing: 0,
              WebkitTextStrokeWidth: 0,
              textDecoration: "none",
            }}
          >
            <span style={{ lineHeight: "14.4px" }}>
              (b)<span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>
              You may make a temporary electronic copy of all or part of the
              Service Content for the sole purpose of viewing it and adapting it
              for the purpose of
              <span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>utilising the Services</span>
            <span style={{ lineHeight: "14.4px" }}>
              . You must not otherwise reproduce, transmit, adapt, distribute,
              sell, modify or publish the Service Content without prior written
              consent from
              <span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>As Is Auctions</span>
            <span style={{ lineHeight: "14.4px" }}>
              <span className="Apple-converted-space">&nbsp;</span>or as
              permitted by law.
            </span>
          </div>
          <div
            className="s16"
            style={{
              marginTop: 0,
              marginBottom: 0,
              textIndent: "-42px",
              marginLeft: 85,
              caretColor: "rgb(0, 0, 0)",
              color: "rgb(0, 0, 0)",
              fontFamily: "-webkit-standard",
              fontSize: 16,
              fontStyle: "normal",
              fontVariantCaps: "normal",
              fontWeight: 400,
              letterSpacing: "normal",
              orphans: "auto",
              textAlign: "start",
              textTransform: "none",
              whiteSpace: "normal",
              widows: "auto",
              wordSpacing: 0,
              WebkitTextStrokeWidth: 0,
              textDecoration: "none",
            }}
          >
            <span style={{ lineHeight: "14.4px" }}>
              (c)<span className="Apple-converted-space">&nbsp;</span>
            </span>
            <a name="_heading=h.17dp8vu" />
            <span style={{ lineHeight: "14.4px" }}>
              In this clause
              <span className="Apple-converted-space">&nbsp;</span>
            </span>
            <a>
              <span style={{ lineHeight: "14.4px" }}>9</span>
            </a>
            <span style={{ lineHeight: "14.4px" }}>, “</span>
            <span
              className="s9"
              style={{ lineHeight: "14.4px", fontWeight: "bold" }}
            >
              Intellectual Property Rights
            </span>
            <span style={{ lineHeight: "14.4px" }}>
              ” means all copyright, trade mark, design, patent, semiconductor
              and circuit layout rights, trade, business, company and domain
              names, confidential and other proprietary rights, and any other
              rights to registration of such rights whether created before or
              after the date of this agreement both in Australia and throughout
              the world.
            </span>
          </div>
          <div
            className="s15"
            style={{
              marginTop: 18,
              marginBottom: 0,
              textIndent: "-42px",
              marginLeft: 42,
              caretColor: "rgb(0, 0, 0)",
              color: "rgb(0, 0, 0)",
              fontFamily: "-webkit-standard",
              fontSize: 16,
              fontStyle: "normal",
              fontVariantCaps: "normal",
              fontWeight: 400,
              letterSpacing: "normal",
              orphans: "auto",
              textAlign: "start",
              textTransform: "none",
              whiteSpace: "normal",
              widows: "auto",
              wordSpacing: 0,
              WebkitTextStrokeWidth: 0,
              textDecoration: "none",
            }}
          >
            <span
              className="s14"
              style={{
                lineHeight: "14.4px",
                textTransform: "uppercase",
                fontWeight: "bold",
              }}
            >
              10<span className="Apple-converted-space">&nbsp;</span>
            </span>
            <a name="_heading=h.3rdcrjn" />
            <span
              className="s14"
              style={{
                lineHeight: "14.4px",
                textTransform: "uppercase",
                fontWeight: "bold",
              }}
            >
              THIRD PARTY CONTENT
            </span>
          </div>
          <p
            className="s18"
            style={{
              marginTop: 0,
              marginBottom: 0,
              lineHeight: "14.4px",
              marginLeft: 42,
              caretColor: "rgb(0, 0, 0)",
              color: "rgb(0, 0, 0)",
              fontFamily: "-webkit-standard",
              fontSize: 16,
              fontStyle: "normal",
              fontVariantCaps: "normal",
              fontWeight: 400,
              letterSpacing: "normal",
              orphans: "auto",
              textAlign: "start",
              textIndent: 0,
              textTransform: "none",
              whiteSpace: "normal",
              widows: "auto",
              wordSpacing: 0,
              WebkitTextStrokeWidth: 0,
              textDecoration: "none",
            }}
          >
            <a name="_heading=h.26in1rg" />
            <span style={{ lineHeight: "14.4px" }}>
              The Platform may contain text, images, data and other content
              provided by a third party and displayed on the Platform (
            </span>
            <span
              className="s9"
              style={{ lineHeight: "14.4px", fontWeight: "bold" }}
            >
              Third Party Content
            </span>
            <span style={{ lineHeight: "14.4px" }}>
              ).<span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>As Is Auctions</span>
            <span style={{ lineHeight: "14.4px" }}>
              <span className="Apple-converted-space">&nbsp;</span>accepts no
              responsibility for Third Party Content and makes no
              representation, warranty or guarantee about the quality,
              suitability, accuracy, reliability, currency or completeness of
              Third Party Content.
            </span>
          </p>
          <div
            className="s15"
            style={{
              marginTop: 18,
              marginBottom: 0,
              textIndent: "-42px",
              marginLeft: 42,
              caretColor: "rgb(0, 0, 0)",
              color: "rgb(0, 0, 0)",
              fontFamily: "-webkit-standard",
              fontSize: 16,
              fontStyle: "normal",
              fontVariantCaps: "normal",
              fontWeight: 400,
              letterSpacing: "normal",
              orphans: "auto",
              textAlign: "start",
              textTransform: "none",
              whiteSpace: "normal",
              widows: "auto",
              wordSpacing: 0,
              WebkitTextStrokeWidth: 0,
              textDecoration: "none",
            }}
          >
            <span
              className="s14"
              style={{
                lineHeight: "14.4px",
                textTransform: "uppercase",
                fontWeight: "bold",
              }}
            >
              11<span className="Apple-converted-space">&nbsp;</span>
            </span>
            <a name="_heading=h.lnxbz9" />
            <span
              className="s14"
              style={{
                lineHeight: "14.4px",
                textTransform: "uppercase",
                fontWeight: "bold",
              }}
            >
              THIRD PARTY TERMS SUPPLIERS
            </span>
          </div>
          <div
            className="s16"
            style={{
              marginTop: 0,
              marginBottom: 0,
              textIndent: "-42px",
              marginLeft: 85,
              caretColor: "rgb(0, 0, 0)",
              color: "rgb(0, 0, 0)",
              fontFamily: "-webkit-standard",
              fontSize: 16,
              fontStyle: "normal",
              fontVariantCaps: "normal",
              fontWeight: 400,
              letterSpacing: "normal",
              orphans: "auto",
              textAlign: "start",
              textTransform: "none",
              whiteSpace: "normal",
              widows: "auto",
              wordSpacing: 0,
              WebkitTextStrokeWidth: 0,
              textDecoration: "none",
            }}
          >
            <span style={{ lineHeight: "14.4px" }}>
              (a)<span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>
              If<span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>we</span>
            <span style={{ lineHeight: "14.4px" }}>
              <span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>need</span>
            <span style={{ lineHeight: "14.4px" }}>
              <span className="Apple-converted-space">&nbsp;</span>to acquire
              goods or services supplied by a third party,
              <span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>you</span>
            <span style={{ lineHeight: "14.4px" }}>
              <span className="Apple-converted-space">&nbsp;</span>may be
              subject to the terms and conditions of that third party (
            </span>
            <span
              className="s9"
              style={{ lineHeight: "14.4px", fontWeight: "bold" }}
            >
              Third Party Terms
            </span>
            <span style={{ lineHeight: "14.4px" }}>).</span>
          </div>
          <div
            className="s16"
            style={{
              marginTop: 0,
              marginBottom: 0,
              textIndent: "-42px",
              marginLeft: 85,
              caretColor: "rgb(0, 0, 0)",
              color: "rgb(0, 0, 0)",
              fontFamily: "-webkit-standard",
              fontSize: 16,
              fontStyle: "normal",
              fontVariantCaps: "normal",
              fontWeight: 400,
              letterSpacing: "normal",
              orphans: "auto",
              textAlign: "start",
              textTransform: "none",
              whiteSpace: "normal",
              widows: "auto",
              wordSpacing: 0,
              WebkitTextStrokeWidth: 0,
              textDecoration: "none",
            }}
          >
            <span style={{ lineHeight: "14.4px" }}>
              (b)<span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>
              Provided that we have notified you of such Third Party Terms and
              provided you with a copy of those terms (for example, a link on
              our Platform
            </span>
            <span style={{ lineHeight: "14.4px" }}>
              <span className="Apple-converted-space">&nbsp;</span>or otherwise
              set out in these terms
            </span>
            <span style={{ lineHeight: "14.4px" }}>)</span>
            <span style={{ lineHeight: "14.4px" }}>,</span>
            <span style={{ lineHeight: "14.4px" }}>
              <span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>you agree</span>
            <span style={{ lineHeight: "14.4px" }}>
              <span className="Apple-converted-space">&nbsp;</span>to any Third
              Party Terms applicable to any goods or services supplied by a
              third party
              <span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>
              that we use to provide you with the Platform or any services
              related to Platform
            </span>
            <span style={{ lineHeight: "14.4px" }}>
              <span className="Apple-converted-space">&nbsp;</span>and
              <span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>
              we<span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>
              will not be liable for any loss or damage suffered
              <span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>by you</span>
            <span style={{ lineHeight: "14.4px" }}>
              <span className="Apple-converted-space">&nbsp;</span>in connection
              with such Third Party Terms.
            </span>
          </div>
          <div
            className="s16"
            style={{
              marginTop: 0,
              marginBottom: 0,
              textIndent: "-42px",
              marginLeft: 85,
              caretColor: "rgb(0, 0, 0)",
              color: "rgb(0, 0, 0)",
              fontFamily: "-webkit-standard",
              fontSize: 16,
              fontStyle: "normal",
              fontVariantCaps: "normal",
              fontWeight: 400,
              letterSpacing: "normal",
              orphans: "auto",
              textAlign: "start",
              textTransform: "none",
              whiteSpace: "normal",
              widows: "auto",
              wordSpacing: 0,
              WebkitTextStrokeWidth: 0,
              textDecoration: "none",
            }}
          >
            <span style={{ lineHeight: "14.4px" }}>
              (c)<span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>
              You have the right to reject any Third Party Terms. If you reject
              the Third Party Terms, we cannot provide you with the services
              associated with the Platform and you can stop using the Platform.
              <span className="Apple-converted-space">&nbsp;</span>
            </span>
          </div>
          <div
            className="s15"
            style={{
              marginTop: 18,
              marginBottom: 0,
              textIndent: "-42px",
              marginLeft: 42,
              caretColor: "rgb(0, 0, 0)",
              color: "rgb(0, 0, 0)",
              fontFamily: "-webkit-standard",
              fontSize: 16,
              fontStyle: "normal",
              fontVariantCaps: "normal",
              fontWeight: 400,
              letterSpacing: "normal",
              orphans: "auto",
              textAlign: "start",
              textTransform: "none",
              whiteSpace: "normal",
              widows: "auto",
              wordSpacing: 0,
              WebkitTextStrokeWidth: 0,
              textDecoration: "none",
            }}
          >
            <span
              className="s14"
              style={{
                lineHeight: "14.4px",
                textTransform: "uppercase",
                fontWeight: "bold",
              }}
            >
              12<span className="Apple-converted-space">&nbsp;</span>
            </span>
            <a name="_heading=h.35nkun2" />
            <a name="_Ref124842936" />
            <span
              className="s14"
              style={{
                lineHeight: "14.4px",
                textTransform: "uppercase",
                fontWeight: "bold",
              }}
            >
              REVIEWS &amp; COMMENTS
            </span>
          </div>
          <div
            className="s16"
            style={{
              marginTop: 0,
              marginBottom: 0,
              textIndent: "-42px",
              marginLeft: 85,
              caretColor: "rgb(0, 0, 0)",
              color: "rgb(0, 0, 0)",
              fontFamily: "-webkit-standard",
              fontSize: 16,
              fontStyle: "normal",
              fontVariantCaps: "normal",
              fontWeight: 400,
              letterSpacing: "normal",
              orphans: "auto",
              textAlign: "start",
              textTransform: "none",
              whiteSpace: "normal",
              widows: "auto",
              wordSpacing: 0,
              WebkitTextStrokeWidth: 0,
              textDecoration: "none",
            }}
          >
            <span style={{ lineHeight: "14.4px" }}>
              (a)<span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>
              Users may be given an opportunity to:
            </span>
          </div>
          <div
            className="s17"
            style={{
              marginTop: 0,
              marginBottom: 0,
              textIndent: "-42px",
              marginLeft: 127,
              caretColor: "rgb(0, 0, 0)",
              color: "rgb(0, 0, 0)",
              fontFamily: "-webkit-standard",
              fontSize: 16,
              fontStyle: "normal",
              fontVariantCaps: "normal",
              fontWeight: 400,
              letterSpacing: "normal",
              orphans: "auto",
              textAlign: "start",
              textTransform: "none",
              whiteSpace: "normal",
              widows: "auto",
              wordSpacing: 0,
              WebkitTextStrokeWidth: 0,
              textDecoration: "none",
            }}
          >
            <span style={{ lineHeight: "14.4px" }}>
              (i)<span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>
              review other Users whom they have purchased or sold
              <span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>Item</span>
            <span style={{ lineHeight: "14.4px" }}>
              s from/to on the
              <span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>Platform</span>
            <span style={{ lineHeight: "14.4px" }}>; and</span>
          </div>
          <div
            className="s17"
            style={{
              marginTop: 0,
              marginBottom: 0,
              textIndent: "-42px",
              marginLeft: 127,
              caretColor: "rgb(0, 0, 0)",
              color: "rgb(0, 0, 0)",
              fontFamily: "-webkit-standard",
              fontSize: 16,
              fontStyle: "normal",
              fontVariantCaps: "normal",
              fontWeight: 400,
              letterSpacing: "normal",
              orphans: "auto",
              textAlign: "start",
              textTransform: "none",
              whiteSpace: "normal",
              widows: "auto",
              wordSpacing: 0,
              WebkitTextStrokeWidth: 0,
              textDecoration: "none",
            }}
          >
            <span style={{ lineHeight: "14.4px" }}>
              (ii)<span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>
              write comments on Listings.
            </span>
          </div>
          <div
            className="s16"
            style={{
              marginTop: 0,
              marginBottom: 0,
              textIndent: "-42px",
              marginLeft: 85,
              caretColor: "rgb(0, 0, 0)",
              color: "rgb(0, 0, 0)",
              fontFamily: "-webkit-standard",
              fontSize: 16,
              fontStyle: "normal",
              fontVariantCaps: "normal",
              fontWeight: 400,
              letterSpacing: "normal",
              orphans: "auto",
              textAlign: "start",
              textTransform: "none",
              whiteSpace: "normal",
              widows: "auto",
              wordSpacing: 0,
              WebkitTextStrokeWidth: 0,
              textDecoration: "none",
            }}
          >
            <span style={{ lineHeight: "14.4px" }}>
              (b)<span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>
              Reviews and comments must be honest and accurate, and not contain
              unlawful or objectionable content, including but not limited to,
              content that is defamatory, libellous, abusive, offensive or
              contain obscene language.
            </span>
          </div>
          <div
            className="s16"
            style={{
              marginTop: 0,
              marginBottom: 0,
              textIndent: "-42px",
              marginLeft: 85,
              caretColor: "rgb(0, 0, 0)",
              color: "rgb(0, 0, 0)",
              fontFamily: "-webkit-standard",
              fontSize: 16,
              fontStyle: "normal",
              fontVariantCaps: "normal",
              fontWeight: 400,
              letterSpacing: "normal",
              orphans: "auto",
              textAlign: "start",
              textTransform: "none",
              whiteSpace: "normal",
              widows: "auto",
              wordSpacing: 0,
              WebkitTextStrokeWidth: 0,
              textDecoration: "none",
            }}
          >
            <span style={{ lineHeight: "14.4px" }}>
              (c)<span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>
              We reserve the right not to publish reviews or comments, to amend
              content of reviews or comments at any time and to remove reviews
              or comments from the
              <span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>Platform</span>
            <span style={{ lineHeight: "14.4px" }}>
              without notice in our discretion.
            </span>
          </div>
          <div
            className="s16"
            style={{
              marginTop: 0,
              marginBottom: 0,
              textIndent: "-42px",
              marginLeft: 85,
              caretColor: "rgb(0, 0, 0)",
              color: "rgb(0, 0, 0)",
              fontFamily: "-webkit-standard",
              fontSize: 16,
              fontStyle: "normal",
              fontVariantCaps: "normal",
              fontWeight: 400,
              letterSpacing: "normal",
              orphans: "auto",
              textAlign: "start",
              textTransform: "none",
              whiteSpace: "normal",
              widows: "auto",
              wordSpacing: 0,
              WebkitTextStrokeWidth: 0,
              textDecoration: "none",
            }}
          >
            <span style={{ lineHeight: "14.4px" }}>
              (d)<span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>
              We are in no way responsible or liable for the information that is
              contained in any reviews or comments published and do not make any
              representation or warranty as to the accuracy or reliability of
              any information or content that is published in the review or
              comment. We are under no obligation to remove any review or
              comment published by or about you, and you agree that we are not
              liable under any laws (including defamation and misleading and
              deceptive conduct) simply through the publication of reviews or
              comments on the
              <span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>Platform</span>
            <span style={{ lineHeight: "14.4px" }}>
              .<span className="Apple-converted-space">&nbsp;</span>
            </span>
          </div>
          <div
            className="s15"
            style={{
              marginTop: 18,
              marginBottom: 0,
              textIndent: "-42px",
              marginLeft: 42,
              caretColor: "rgb(0, 0, 0)",
              color: "rgb(0, 0, 0)",
              fontFamily: "-webkit-standard",
              fontSize: 16,
              fontStyle: "normal",
              fontVariantCaps: "normal",
              fontWeight: 400,
              letterSpacing: "normal",
              orphans: "auto",
              textAlign: "start",
              textTransform: "none",
              whiteSpace: "normal",
              widows: "auto",
              wordSpacing: 0,
              WebkitTextStrokeWidth: 0,
              textDecoration: "none",
            }}
          >
            <span
              className="s14"
              style={{
                lineHeight: "14.4px",
                textTransform: "uppercase",
                fontWeight: "bold",
              }}
            >
              13<span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span
              className="s14"
              style={{
                lineHeight: "14.4px",
                textTransform: "uppercase",
                fontWeight: "bold",
              }}
            >
              DISPUTES BETWEEN USERS
            </span>
          </div>
          <div
            className="s16"
            style={{
              marginTop: 0,
              marginBottom: 0,
              textIndent: "-42px",
              marginLeft: 85,
              caretColor: "rgb(0, 0, 0)",
              color: "rgb(0, 0, 0)",
              fontFamily: "-webkit-standard",
              fontSize: 16,
              fontStyle: "normal",
              fontVariantCaps: "normal",
              fontWeight: 400,
              letterSpacing: "normal",
              orphans: "auto",
              textAlign: "start",
              textTransform: "none",
              whiteSpace: "normal",
              widows: "auto",
              wordSpacing: 0,
              WebkitTextStrokeWidth: 0,
              textDecoration: "none",
            }}
          >
            <span style={{ lineHeight: "14.4px" }}>
              (a)<span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>
              You should direct any complaint relating to another User to that
              User. Users must take all reasonable steps to resolve any dispute
              with another User with that User.
            </span>
          </div>
          <div
            className="s16"
            style={{
              marginTop: 0,
              marginBottom: 0,
              textIndent: "-42px",
              marginLeft: 85,
              caretColor: "rgb(0, 0, 0)",
              color: "rgb(0, 0, 0)",
              fontFamily: "-webkit-standard",
              fontSize: 16,
              fontStyle: "normal",
              fontVariantCaps: "normal",
              fontWeight: 400,
              letterSpacing: "normal",
              orphans: "auto",
              textAlign: "start",
              textTransform: "none",
              whiteSpace: "normal",
              widows: "auto",
              wordSpacing: 0,
              WebkitTextStrokeWidth: 0,
              textDecoration: "none",
            }}
          >
            <span style={{ lineHeight: "14.4px" }}>
              (b)<span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>
              If any issue or problem relating to the Platform remains
              unresolved after directing a complaint to a relevant User, or if
              the complaint does not relate to another User, you must report it
              to
              <span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>As Is Auctions</span>
            <span style={{ lineHeight: "14.4px" }}>
              <span className="Apple-converted-space">&nbsp;</span>via
              <span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>the Platform</span>
            <span style={{ lineHeight: "14.4px" }}>
              . We will assess the complaint and attempt to quickly and
              satisfactorily resolve it.
            </span>
          </div>
          <div
            className="s16"
            style={{
              marginTop: 0,
              marginBottom: 0,
              textIndent: "-42px",
              marginLeft: 85,
              caretColor: "rgb(0, 0, 0)",
              color: "rgb(0, 0, 0)",
              fontFamily: "-webkit-standard",
              fontSize: 16,
              fontStyle: "normal",
              fontVariantCaps: "normal",
              fontWeight: 400,
              letterSpacing: "normal",
              orphans: "auto",
              textAlign: "start",
              textTransform: "none",
              whiteSpace: "normal",
              widows: "auto",
              wordSpacing: 0,
              WebkitTextStrokeWidth: 0,
              textDecoration: "none",
            }}
          >
            <span style={{ lineHeight: "14.4px" }}>
              (c)<span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>
              Any costs you incur in relation to a complaint or dispute will be
              your responsibility.
            </span>
          </div>
          <div
            className="s16"
            style={{
              marginTop: 0,
              marginBottom: 0,
              textIndent: "-42px",
              marginLeft: 85,
              caretColor: "rgb(0, 0, 0)",
              color: "rgb(0, 0, 0)",
              fontFamily: "-webkit-standard",
              fontSize: 16,
              fontStyle: "normal",
              fontVariantCaps: "normal",
              fontWeight: 400,
              letterSpacing: "normal",
              orphans: "auto",
              textAlign: "start",
              textTransform: "none",
              whiteSpace: "normal",
              widows: "auto",
              wordSpacing: 0,
              WebkitTextStrokeWidth: 0,
              textDecoration: "none",
            }}
          >
            <span style={{ lineHeight: "14.4px" }}>
              (d)<span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>As Is Auctions</span>
            <span style={{ lineHeight: "14.4px" }}>
              <span className="Apple-converted-space">&nbsp;</span>has the
              option to appoint an independent mediator or arbitrator if needed.
              The cost of any mediator or arbitrator must be shared equally
              between each of the parties to the dispute.
            </span>
          </div>
          <div
            className="s16"
            style={{
              marginTop: 0,
              marginBottom: 0,
              textIndent: "-42px",
              marginLeft: 85,
              caretColor: "rgb(0, 0, 0)",
              color: "rgb(0, 0, 0)",
              fontFamily: "-webkit-standard",
              fontSize: 16,
              fontStyle: "normal",
              fontVariantCaps: "normal",
              fontWeight: 400,
              letterSpacing: "normal",
              orphans: "auto",
              textAlign: "start",
              textTransform: "none",
              whiteSpace: "normal",
              widows: "auto",
              wordSpacing: 0,
              WebkitTextStrokeWidth: 0,
              textDecoration: "none",
            }}
          >
            <span style={{ lineHeight: "14.4px" }}>
              (e)<span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>As Is Auctions</span>
            <span style={{ lineHeight: "14.4px" }}>
              <span className="Apple-converted-space">&nbsp;</span>reserves the
              right to hold funds in relation to a dispute until the dispute is
              resolved by the relevant parties or by a mediator or arbitrator.
            </span>
          </div>
          <div
            className="s16"
            style={{
              marginTop: 0,
              marginBottom: 0,
              textIndent: "-42px",
              marginLeft: 85,
              caretColor: "rgb(0, 0, 0)",
              color: "rgb(0, 0, 0)",
              fontFamily: "-webkit-standard",
              fontSize: 16,
              fontStyle: "normal",
              fontVariantCaps: "normal",
              fontWeight: 400,
              letterSpacing: "normal",
              orphans: "auto",
              textAlign: "start",
              textTransform: "none",
              whiteSpace: "normal",
              widows: "auto",
              wordSpacing: 0,
              WebkitTextStrokeWidth: 0,
              textDecoration: "none",
            }}
          >
            <span style={{ lineHeight: "14.4px" }}>
              (f)<span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>
              If you have a dispute with
              <span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>As Is Auctions</span>
            <span style={{ lineHeight: "14.4px" }}>
              , you agree to notify us first and enter into discussion,
              mediation or arbitration with us for a minimum of a 120-day period
              before pursuing any other proceedings.
              <span className="Apple-converted-space">&nbsp;</span>
            </span>
          </div>
          <div
            className="s16"
            style={{
              marginTop: 0,
              marginBottom: 0,
              textIndent: "-42px",
              marginLeft: 85,
              caretColor: "rgb(0, 0, 0)",
              color: "rgb(0, 0, 0)",
              fontFamily: "-webkit-standard",
              fontSize: 16,
              fontStyle: "normal",
              fontVariantCaps: "normal",
              fontWeight: 400,
              letterSpacing: "normal",
              orphans: "auto",
              textAlign: "start",
              textTransform: "none",
              whiteSpace: "normal",
              widows: "auto",
              wordSpacing: 0,
              WebkitTextStrokeWidth: 0,
              textDecoration: "none",
            }}
          >
            <span style={{ lineHeight: "14.4px" }}>
              (g)<span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>
              Notwithstanding any other provision of this clause
              <span className="Apple-converted-space">&nbsp;</span>
            </span>
            <a>
              <span style={{ lineHeight: "14.4px" }}>12</span>
            </a>
            <span style={{ lineHeight: "14.4px" }}>
              , you or<span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>As Is Auctions</span>
            <span style={{ lineHeight: "14.4px" }}>
              <span className="Apple-converted-space">&nbsp;</span>may at any
              time cancel your Account or discontinue your use of the Platform.
            </span>
          </div>
          <div
            className="s15"
            style={{
              marginTop: 18,
              marginBottom: 0,
              textIndent: "-42px",
              marginLeft: 42,
              caretColor: "rgb(0, 0, 0)",
              color: "rgb(0, 0, 0)",
              fontFamily: "-webkit-standard",
              fontSize: 16,
              fontStyle: "normal",
              fontVariantCaps: "normal",
              fontWeight: 400,
              letterSpacing: "normal",
              orphans: "auto",
              textAlign: "start",
              textTransform: "none",
              whiteSpace: "normal",
              widows: "auto",
              wordSpacing: 0,
              WebkitTextStrokeWidth: 0,
              textDecoration: "none",
            }}
          >
            <span
              className="s14"
              style={{
                lineHeight: "14.4px",
                textTransform: "uppercase",
                fontWeight: "bold",
              }}
            >
              14<span className="Apple-converted-space">&nbsp;</span>
            </span>
            <a name="_heading=h.1ksv4uv" />
            <span
              className="s14"
              style={{
                lineHeight: "14.4px",
                textTransform: "uppercase",
                fontWeight: "bold",
              }}
            >
              SECURITY
            </span>
          </div>
          <p
            className="s18"
            style={{
              marginTop: 0,
              marginBottom: 0,
              lineHeight: "14.4px",
              marginLeft: 42,
              caretColor: "rgb(0, 0, 0)",
              color: "rgb(0, 0, 0)",
              fontFamily: "-webkit-standard",
              fontSize: 16,
              fontStyle: "normal",
              fontVariantCaps: "normal",
              fontWeight: 400,
              letterSpacing: "normal",
              orphans: "auto",
              textAlign: "start",
              textIndent: 0,
              textTransform: "none",
              whiteSpace: "normal",
              widows: "auto",
              wordSpacing: 0,
              WebkitTextStrokeWidth: 0,
              textDecoration: "none",
            }}
          >
            <a name="_heading=h.44sinio" />
            <span style={{ lineHeight: "14.4px" }}>As Is Auctions</span>
            <span style={{ lineHeight: "14.4px" }}>
              <span className="Apple-converted-space">&nbsp;</span>does not
              accept responsibility for loss or damage to computer systems,
              mobile phones or other electronic devices arising in connection
              with your use of the Platform. You should take your own
              precautions to ensure that the process you employ to access the
              Platform does not expose you to the risk of viruses, malicious
              computer code or other forms of interference.
            </span>
          </p>
          <div
            className="s15"
            style={{
              marginTop: 18,
              marginBottom: 0,
              textIndent: "-42px",
              marginLeft: 42,
              caretColor: "rgb(0, 0, 0)",
              color: "rgb(0, 0, 0)",
              fontFamily: "-webkit-standard",
              fontSize: 16,
              fontStyle: "normal",
              fontVariantCaps: "normal",
              fontWeight: 400,
              letterSpacing: "normal",
              orphans: "auto",
              textAlign: "start",
              textTransform: "none",
              whiteSpace: "normal",
              widows: "auto",
              wordSpacing: 0,
              WebkitTextStrokeWidth: 0,
              textDecoration: "none",
            }}
          >
            <span
              className="s14"
              style={{
                lineHeight: "14.4px",
                textTransform: "uppercase",
                fontWeight: "bold",
              }}
            >
              15<span className="Apple-converted-space">&nbsp;</span>
            </span>
            <a name="_heading=h.2jxsxqh" />
            <span
              className="s14"
              style={{
                lineHeight: "14.4px",
                textTransform: "uppercase",
                fontWeight: "bold",
              }}
            >
              DISCLAIMER
            </span>
          </div>
          <div
            className="s16"
            style={{
              marginTop: 0,
              marginBottom: 0,
              textIndent: "-42px",
              marginLeft: 85,
              caretColor: "rgb(0, 0, 0)",
              color: "rgb(0, 0, 0)",
              fontFamily: "-webkit-standard",
              fontSize: 16,
              fontStyle: "normal",
              fontVariantCaps: "normal",
              fontWeight: 400,
              letterSpacing: "normal",
              orphans: "auto",
              textAlign: "start",
              textTransform: "none",
              whiteSpace: "normal",
              widows: "auto",
              wordSpacing: 0,
              WebkitTextStrokeWidth: 0,
              textDecoration: "none",
            }}
          >
            <span style={{ lineHeight: "14.4px" }}>
              (a)<span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>(</span>
            <span
              className="s9"
              style={{ lineHeight: "14.4px", fontWeight: "bold" }}
            >
              Introduction service
            </span>
            <span style={{ lineHeight: "14.4px" }}>
              )<span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>As Is Auctions</span>
            <span style={{ lineHeight: "14.4px" }}>
              <span className="Apple-converted-space">&nbsp;</span>is a medium
              that facilitates the introduction of
              <span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>Buyers</span>
            <span style={{ lineHeight: "14.4px" }}>
              <span className="Apple-converted-space">&nbsp;</span>and
              <span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>Seller</span>
            <span style={{ lineHeight: "14.4px" }}>
              s for the purposes of
              <span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>
              selling<span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>Item</span>
            <span style={{ lineHeight: "14.4px" }}>s</span>
            <span style={{ lineHeight: "14.4px" }}>
              .<span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>As Is Auctions</span>
            <span style={{ lineHeight: "14.4px" }}>
              <span className="Apple-converted-space">&nbsp;</span>simply
              collects a service fee in consideration for providing this
              introduction service and does not have any obligations or
              liabilities to, and is not a party to any contract between,
              <span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>Buyers</span>
            <span style={{ lineHeight: "14.4px" }}>
              <span className="Apple-converted-space">&nbsp;</span>and
              <span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>Seller</span>
            <span style={{ lineHeight: "14.4px" }}>
              s in relation to such services or otherwise resulting from the
              introduction.
            </span>
          </div>
          <div
            className="s16"
            style={{
              marginTop: 0,
              marginBottom: 0,
              textIndent: "-42px",
              marginLeft: 85,
              caretColor: "rgb(0, 0, 0)",
              color: "rgb(0, 0, 0)",
              fontFamily: "-webkit-standard",
              fontSize: 16,
              fontStyle: "normal",
              fontVariantCaps: "normal",
              fontWeight: 400,
              letterSpacing: "normal",
              orphans: "auto",
              textAlign: "start",
              textTransform: "none",
              whiteSpace: "normal",
              widows: "auto",
              wordSpacing: 0,
              WebkitTextStrokeWidth: 0,
              textDecoration: "none",
            }}
          >
            <span style={{ lineHeight: "14.4px" }}>
              (b)<span className="Apple-converted-space">&nbsp;</span>
            </span>
            <a name="_Ref147747720" />
            <span style={{ lineHeight: "14.4px" }}>(</span>
            <span
              className="s9"
              style={{ lineHeight: "14.4px", fontWeight: "bold" }}
            >
              Limitation of Liability
            </span>
            <span style={{ lineHeight: "14.4px" }}>
              ) To the maximum
              <span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>extent</span>
            <span style={{ lineHeight: "14.4px" }}>
              <span className="Apple-converted-space">&nbsp;</span>permitted by
              law, the total liability of each party (being you, the User and
              us,
              <span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>As Is Auctions</span>
            <span style={{ lineHeight: "14.4px" }}>
              ) in respect of loss or damage sustained by the other party in
              connection with this agreement is limited to the
              <span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>
              amount paid by you
              <span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>
              to As Is Auctions
              <span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>on the Platform in the</span>
            <span style={{ lineHeight: "14.4px" }}>
              <span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>
              3 months preceding the date of the event giving rise to the
              relevant liability
            </span>
            <span style={{ lineHeight: "14.4px" }}>.</span>
          </div>
          <div
            className="s16"
            style={{
              marginTop: 0,
              marginBottom: 0,
              textIndent: "-42px",
              marginLeft: 85,
              caretColor: "rgb(0, 0, 0)",
              color: "rgb(0, 0, 0)",
              fontFamily: "-webkit-standard",
              fontSize: 16,
              fontStyle: "normal",
              fontVariantCaps: "normal",
              fontWeight: 400,
              letterSpacing: "normal",
              orphans: "auto",
              textAlign: "start",
              textTransform: "none",
              whiteSpace: "normal",
              widows: "auto",
              wordSpacing: 0,
              WebkitTextStrokeWidth: 0,
              textDecoration: "none",
            }}
          >
            <span style={{ lineHeight: "14.4px" }}>
              (c)<span className="Apple-converted-space">&nbsp;</span>
            </span>
            <a name="_Ref152241610" />
            <span style={{ lineHeight: "14.4px" }}>(</span>
            <span
              className="s9"
              style={{ lineHeight: "14.4px", fontWeight: "bold" }}
            >
              Disclaimer
            </span>
            <span style={{ lineHeight: "14.4px" }}>
              )<span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>
              To the maximum extent permitted by applicable law, all express or
              implied representations and warranties not expressly stated in
              this agreement are excluded.
            </span>
          </div>
          <div
            className="s16"
            style={{
              marginTop: 0,
              marginBottom: 0,
              textIndent: "-42px",
              marginLeft: 85,
              caretColor: "rgb(0, 0, 0)",
              color: "rgb(0, 0, 0)",
              fontFamily: "-webkit-standard",
              fontSize: 16,
              fontStyle: "normal",
              fontVariantCaps: "normal",
              fontWeight: 400,
              letterSpacing: "normal",
              orphans: "auto",
              textAlign: "start",
              textTransform: "none",
              whiteSpace: "normal",
              widows: "auto",
              wordSpacing: 0,
              WebkitTextStrokeWidth: 0,
              textDecoration: "none",
            }}
          >
            <span style={{ lineHeight: "14.4px" }}>
              (d)<span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>(</span>
            <span
              className="s9"
              style={{ lineHeight: "14.4px", fontWeight: "bold" }}
            >
              Consumer Law
            </span>
            <span style={{ lineHeight: "14.4px" }}>
              )<span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>
              Nothing in this agreement is intended to limit the operation of
              the Australian Consumer Law contained in the
              <span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span
              className="s21"
              style={{ lineHeight: "14.4px", fontStyle: "italic" }}
            >
              Competition and Consumer Act 2010
            </span>
            <span style={{ lineHeight: "14.4px" }}>
              (Cth)<span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>(</span>
            <span
              className="s9"
              style={{ lineHeight: "14.4px", fontWeight: "bold" }}
            >
              ACL
            </span>
            <span style={{ lineHeight: "14.4px" }}>
              ). Under the ACL,
              <span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>you</span>
            <span style={{ lineHeight: "14.4px" }}>
              <span className="Apple-converted-space">&nbsp;</span>may be
              entitled to certain remedies (like a refund, replacement or
              repair) if there is a failure with the goods or services provided.
            </span>
          </div>
          <div
            className="s16"
            style={{
              marginTop: 0,
              marginBottom: 0,
              textIndent: "-42px",
              marginLeft: 85,
              caretColor: "rgb(0, 0, 0)",
              color: "rgb(0, 0, 0)",
              fontFamily: "-webkit-standard",
              fontSize: 16,
              fontStyle: "normal",
              fontVariantCaps: "normal",
              fontWeight: 400,
              letterSpacing: "normal",
              orphans: "auto",
              textAlign: "start",
              textTransform: "none",
              whiteSpace: "normal",
              widows: "auto",
              wordSpacing: 0,
              WebkitTextStrokeWidth: 0,
              textDecoration: "none",
            }}
          >
            <span style={{ lineHeight: "14.4px" }}>
              (e)<span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>(</span>
            <span
              className="s9"
              style={{ lineHeight: "14.4px", fontWeight: "bold" }}
            >
              Consequential Loss
            </span>
            <span style={{ lineHeight: "14.4px" }}>
              ) To the maximum extent permitted by law, neither party will be
              liable for any incidental, special or consequential loss or
              damages, or damages for loss of data, business or business
              <span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>opportunity</span>
            <span style={{ lineHeight: "14.4px" }}>
              , goodwill, anticipated savings, profits or revenue in connection
              with this agreement or any goods or services provided by
              <span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>As Is Auctions</span>
            <span style={{ lineHeight: "14.4px" }}>, except:</span>
          </div>
          <div
            className="s17"
            style={{
              marginTop: 0,
              marginBottom: 0,
              textIndent: "-42px",
              marginLeft: 127,
              caretColor: "rgb(0, 0, 0)",
              color: "rgb(0, 0, 0)",
              fontFamily: "-webkit-standard",
              fontSize: 16,
              fontStyle: "normal",
              fontVariantCaps: "normal",
              fontWeight: 400,
              letterSpacing: "normal",
              orphans: "auto",
              textAlign: "start",
              textTransform: "none",
              whiteSpace: "normal",
              widows: "auto",
              wordSpacing: 0,
              WebkitTextStrokeWidth: 0,
              textDecoration: "none",
            }}
          >
            <span style={{ lineHeight: "14.4px" }}>
              (i)<span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>
              in relation to a party’s liability for fraud, personal injury,
              death or loss or damage to tangible property; or
            </span>
          </div>
          <div
            className="s17"
            style={{
              marginTop: 0,
              marginBottom: 0,
              textIndent: "-42px",
              marginLeft: 127,
              caretColor: "rgb(0, 0, 0)",
              color: "rgb(0, 0, 0)",
              fontFamily: "-webkit-standard",
              fontSize: 16,
              fontStyle: "normal",
              fontVariantCaps: "normal",
              fontWeight: 400,
              letterSpacing: "normal",
              orphans: "auto",
              textAlign: "start",
              textTransform: "none",
              whiteSpace: "normal",
              widows: "auto",
              wordSpacing: 0,
              WebkitTextStrokeWidth: 0,
              textDecoration: "none",
            }}
          >
            <span style={{ lineHeight: "14.4px" }}>
              (ii)<span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>
              to the extent this liability cannot be excluded under the
              <span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span
              className="s21"
              style={{ lineHeight: "14.4px", fontStyle: "italic" }}
            >
              Competition and Consumer Act 2010
            </span>
            <span style={{ lineHeight: "14.4px" }}>
              <span className="Apple-converted-space">&nbsp;</span>(Cth).
            </span>
          </div>
          <div
            className="s16"
            style={{
              marginTop: 0,
              marginBottom: 0,
              textIndent: "-42px",
              marginLeft: 85,
              caretColor: "rgb(0, 0, 0)",
              color: "rgb(0, 0, 0)",
              fontFamily: "-webkit-standard",
              fontSize: 16,
              fontStyle: "normal",
              fontVariantCaps: "normal",
              fontWeight: 400,
              letterSpacing: "normal",
              orphans: "auto",
              textAlign: "start",
              textTransform: "none",
              whiteSpace: "normal",
              widows: "auto",
              wordSpacing: 0,
              WebkitTextStrokeWidth: 0,
              textDecoration: "none",
            }}
          >
            <span style={{ lineHeight: "14.4px" }}>
              (f)<span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>
              You acknowledge and agree that:
            </span>
          </div>
          <div
            className="s17"
            style={{
              marginTop: 0,
              marginBottom: 0,
              textIndent: "-42px",
              marginLeft: 127,
              caretColor: "rgb(0, 0, 0)",
              color: "rgb(0, 0, 0)",
              fontFamily: "-webkit-standard",
              fontSize: 16,
              fontStyle: "normal",
              fontVariantCaps: "normal",
              fontWeight: 400,
              letterSpacing: "normal",
              orphans: "auto",
              textAlign: "start",
              textTransform: "none",
              whiteSpace: "normal",
              widows: "auto",
              wordSpacing: 0,
              WebkitTextStrokeWidth: 0,
              textDecoration: "none",
            }}
          >
            <span style={{ lineHeight: "14.4px" }}>
              (i)<span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>
              Users are a third party unrelated to us. We are not a party to any
              agreement arising or entered into between Users and Users are
              solely responsible for the supply of
              <span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>Item</span>
            <span style={{ lineHeight: "14.4px" }}>
              s on the terms and conditions as may be agreed between the Users.
              Your legal rights in connection with the supply of the
              <span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>Item</span>
            <span style={{ lineHeight: "14.4px" }}>
              s are against the other Users and not us;
              <span className="Apple-converted-space">&nbsp;</span>
            </span>
          </div>
          <div
            className="s17"
            style={{
              marginTop: 0,
              marginBottom: 0,
              textIndent: "-42px",
              marginLeft: 127,
              caretColor: "rgb(0, 0, 0)",
              color: "rgb(0, 0, 0)",
              fontFamily: "-webkit-standard",
              fontSize: 16,
              fontStyle: "normal",
              fontVariantCaps: "normal",
              fontWeight: 400,
              letterSpacing: "normal",
              orphans: "auto",
              textAlign: "start",
              textTransform: "none",
              whiteSpace: "normal",
              widows: "auto",
              wordSpacing: 0,
              WebkitTextStrokeWidth: 0,
              textDecoration: "none",
            }}
          >
            <span style={{ lineHeight: "14.4px" }}>
              (ii)<span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>
              we do not supply, provide, manage or control the Users on the
              <span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>Platform</span>
            <span style={{ lineHeight: "14.4px" }}>
              <span className="Apple-converted-space">&nbsp;</span>or their
              <span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>Item</span>
            <span style={{ lineHeight: "14.4px" }}>
              s and are not responsible for their advertising (including the
              contents of a Listing), supply or otherwise. If you have any
              enquiries about
              <span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>Item</span>
            <span style={{ lineHeight: "14.4px" }}>
              <span className="Apple-converted-space">&nbsp;</span>you must
              contact the relevant User. We have no control over, and do not
              ensure, guarantee or provide any insurance, warranty or indemnity
              in respect of the quality, fitness for purpose, legality,
              accuracy, completeness or otherwise of:
            </span>
          </div>
          <div
            className="s22"
            style={{
              marginTop: 0,
              marginBottom: 0,
              textIndent: "-42px",
              marginLeft: 170,
              caretColor: "rgb(0, 0, 0)",
              color: "rgb(0, 0, 0)",
              fontFamily: "-webkit-standard",
              fontSize: 16,
              fontStyle: "normal",
              fontVariantCaps: "normal",
              fontWeight: 400,
              letterSpacing: "normal",
              orphans: "auto",
              textAlign: "start",
              textTransform: "none",
              whiteSpace: "normal",
              widows: "auto",
              wordSpacing: 0,
              WebkitTextStrokeWidth: 0,
              textDecoration: "none",
            }}
          >
            <span style={{ lineHeight: "14.4px" }}>
              (A)<span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>
              any<span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>Item</span>
            <span style={{ lineHeight: "14.4px" }}>
              s (including without limitation that such
              <span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>Item</span>
            <span style={{ lineHeight: "14.4px" }}>
              s will be suitable to your specific requirements or the standards
              of such
              <span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>Item</span>
            <span style={{ lineHeight: "14.4px" }}>s);</span>
          </div>
          <div
            className="s22"
            style={{
              marginTop: 0,
              marginBottom: 0,
              textIndent: "-42px",
              marginLeft: 170,
              caretColor: "rgb(0, 0, 0)",
              color: "rgb(0, 0, 0)",
              fontFamily: "-webkit-standard",
              fontSize: 16,
              fontStyle: "normal",
              fontVariantCaps: "normal",
              fontWeight: 400,
              letterSpacing: "normal",
              orphans: "auto",
              textAlign: "start",
              textTransform: "none",
              whiteSpace: "normal",
              widows: "auto",
              wordSpacing: 0,
              WebkitTextStrokeWidth: 0,
              textDecoration: "none",
            }}
          >
            <span style={{ lineHeight: "14.4px" }}>
              (B)<span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>
              any representations made or information provided by Users to each
              other (including without limitation with respect to the quality,
              age or state of repair of any
              <span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>Item</span>
            <span style={{ lineHeight: "14.4px" }}>
              s or its registration status);&nbsp;or
            </span>
          </div>
          <div
            className="s22"
            style={{
              marginTop: 0,
              marginBottom: 0,
              textIndent: "-42px",
              marginLeft: 170,
              caretColor: "rgb(0, 0, 0)",
              color: "rgb(0, 0, 0)",
              fontFamily: "-webkit-standard",
              fontSize: 16,
              fontStyle: "normal",
              fontVariantCaps: "normal",
              fontWeight: 400,
              letterSpacing: "normal",
              orphans: "auto",
              textAlign: "start",
              textTransform: "none",
              whiteSpace: "normal",
              widows: "auto",
              wordSpacing: 0,
              WebkitTextStrokeWidth: 0,
              textDecoration: "none",
            }}
          >
            <span style={{ lineHeight: "14.4px" }}>
              (C)<span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>
              the ability of Users to undertake their respective obligations
              (including the ability of the Seller to supply the
              <span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>Item</span>
            <span style={{ lineHeight: "14.4px" }}>
              <span className="Apple-converted-space">&nbsp;</span>or the
              ability of the Buyer to pay for the
              <span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>Item</span>
            <span style={{ lineHeight: "14.4px" }}>s).</span>
          </div>
          <div
            className="s17"
            style={{
              marginTop: 0,
              marginBottom: 0,
              textIndent: "-42px",
              marginLeft: 127,
              caretColor: "rgb(0, 0, 0)",
              color: "rgb(0, 0, 0)",
              fontFamily: "-webkit-standard",
              fontSize: 16,
              fontStyle: "normal",
              fontVariantCaps: "normal",
              fontWeight: 400,
              letterSpacing: "normal",
              orphans: "auto",
              textAlign: "start",
              textTransform: "none",
              whiteSpace: "normal",
              widows: "auto",
              wordSpacing: 0,
              WebkitTextStrokeWidth: 0,
              textDecoration: "none",
            }}
          >
            <span style={{ lineHeight: "14.4px" }}>
              (iii)<span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>Sellers or Buyers</span>
            <span style={{ lineHeight: "14.4px" }}>
              <span className="Apple-converted-space">&nbsp;</span>of the
              <span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>Item</span>
            <span style={{ lineHeight: "14.4px" }}>
              s offered for sale on the
              <span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>Platform</span>
            <span style={{ lineHeight: "14.4px" }}>
              <span className="Apple-converted-space">&nbsp;</span>will
              ultimately be liable for any and all motor
              <span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>vehicle</span>
            <span style={{ lineHeight: "14.4px" }}>
              <span className="Apple-converted-space">&nbsp;</span>
              registrations, transfer documentation, motor
              <span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>vehicle</span>
            <span style={{ lineHeight: "14.4px" }}>
              <span className="Apple-converted-space">&nbsp;</span>dealer
              licenses (as appropriate) and/or payment of any stamp duties
              and/or official fees to relevant road and traffic authorities;
            </span>
          </div>
          <div
            className="s17"
            style={{
              marginTop: 0,
              marginBottom: 0,
              textIndent: "-42px",
              marginLeft: 127,
              caretColor: "rgb(0, 0, 0)",
              color: "rgb(0, 0, 0)",
              fontFamily: "-webkit-standard",
              fontSize: 16,
              fontStyle: "normal",
              fontVariantCaps: "normal",
              fontWeight: 400,
              letterSpacing: "normal",
              orphans: "auto",
              textAlign: "start",
              textTransform: "none",
              whiteSpace: "normal",
              widows: "auto",
              wordSpacing: 0,
              WebkitTextStrokeWidth: 0,
              textDecoration: "none",
            }}
          >
            <span style={{ lineHeight: "14.4px" }}>
              (iv)<span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>
              no information provided by us constitutes legal or financial
              advice, particularly in respect of your obligations to comply with
              any Applicable Laws;
            </span>
          </div>
          <div
            className="s17"
            style={{
              marginTop: 0,
              marginBottom: 0,
              textIndent: "-42px",
              marginLeft: 127,
              caretColor: "rgb(0, 0, 0)",
              color: "rgb(0, 0, 0)",
              fontFamily: "-webkit-standard",
              fontSize: 16,
              fontStyle: "normal",
              fontVariantCaps: "normal",
              fontWeight: 400,
              letterSpacing: "normal",
              orphans: "auto",
              textAlign: "start",
              textTransform: "none",
              whiteSpace: "normal",
              widows: "auto",
              wordSpacing: 0,
              WebkitTextStrokeWidth: 0,
              textDecoration: "none",
            }}
          >
            <span style={{ lineHeight: "14.4px" }}>
              (v)<span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>
              we do not guarantee that the
              <span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>Platform</span>
            <span style={{ lineHeight: "14.4px" }}>
              <span className="Apple-converted-space">&nbsp;</span>will be
              error-free or uninterrupted, or that your use of the
              <span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>Platform</span>
            <span style={{ lineHeight: "14.4px" }}>
              <span className="Apple-converted-space">&nbsp;</span>will provide
              any specific results; and
            </span>
          </div>
          <div
            className="s17"
            style={{
              marginTop: 0,
              marginBottom: 0,
              textIndent: "-42px",
              marginLeft: 127,
              caretColor: "rgb(0, 0, 0)",
              color: "rgb(0, 0, 0)",
              fontFamily: "-webkit-standard",
              fontSize: 16,
              fontStyle: "normal",
              fontVariantCaps: "normal",
              fontWeight: 400,
              letterSpacing: "normal",
              orphans: "auto",
              textAlign: "start",
              textTransform: "none",
              whiteSpace: "normal",
              widows: "auto",
              wordSpacing: 0,
              WebkitTextStrokeWidth: 0,
              textDecoration: "none",
            }}
          >
            <span style={{ lineHeight: "14.4px" }}>
              (vi)<span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>
              we do not represent or warrant that your use of the Services will
              meet your particular requirements, whether those requirements are
              disclosed to us or not.
            </span>
          </div>
          <div
            className="s15"
            style={{
              marginTop: 18,
              marginBottom: 0,
              textIndent: "-42px",
              marginLeft: 42,
              caretColor: "rgb(0, 0, 0)",
              color: "rgb(0, 0, 0)",
              fontFamily: "-webkit-standard",
              fontSize: 16,
              fontStyle: "normal",
              fontVariantCaps: "normal",
              fontWeight: 400,
              letterSpacing: "normal",
              orphans: "auto",
              textAlign: "start",
              textTransform: "none",
              whiteSpace: "normal",
              widows: "auto",
              wordSpacing: 0,
              WebkitTextStrokeWidth: 0,
              textDecoration: "none",
            }}
          >
            <span
              className="s14"
              style={{
                lineHeight: "14.4px",
                textTransform: "uppercase",
                fontWeight: "bold",
              }}
            >
              16<span className="Apple-converted-space">&nbsp;</span>
            </span>
            <a name="_heading=h.z337ya" />
            <span
              className="s14"
              style={{
                lineHeight: "14.4px",
                textTransform: "uppercase",
                fontWeight: "bold",
              }}
            >
              CONFIDENTIALITY
            </span>
          </div>
          <p
            className="s18"
            style={{
              marginTop: 0,
              marginBottom: 0,
              lineHeight: "14.4px",
              marginLeft: 42,
              caretColor: "rgb(0, 0, 0)",
              color: "rgb(0, 0, 0)",
              fontFamily: "-webkit-standard",
              fontSize: 16,
              fontStyle: "normal",
              fontVariantCaps: "normal",
              fontWeight: 400,
              letterSpacing: "normal",
              orphans: "auto",
              textAlign: "start",
              textIndent: 0,
              textTransform: "none",
              whiteSpace: "normal",
              widows: "auto",
              wordSpacing: 0,
              WebkitTextStrokeWidth: 0,
              textDecoration: "none",
            }}
          >
            <span style={{ lineHeight: "14.4px" }}>You agree that:</span>
          </p>
          <div
            className="s16"
            style={{
              marginTop: 0,
              marginBottom: 0,
              textIndent: "-42px",
              marginLeft: 85,
              caretColor: "rgb(0, 0, 0)",
              color: "rgb(0, 0, 0)",
              fontFamily: "-webkit-standard",
              fontSize: 16,
              fontStyle: "normal",
              fontVariantCaps: "normal",
              fontWeight: 400,
              letterSpacing: "normal",
              orphans: "auto",
              textAlign: "start",
              textTransform: "none",
              whiteSpace: "normal",
              widows: "auto",
              wordSpacing: 0,
              WebkitTextStrokeWidth: 0,
              textDecoration: "none",
            }}
          >
            <span style={{ lineHeight: "14.4px" }}>
              (a)<span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>
              no information owned by
              <span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>As Is Auctions</span>
            <span style={{ lineHeight: "14.4px" }}>
              , including system operations, documents, marketing strategies,
              staff information and client information, may be disclosed or made
              available to any third parties; and
            </span>
          </div>
          <div
            className="s16"
            style={{
              marginTop: 0,
              marginBottom: 0,
              textIndent: "-42px",
              marginLeft: 85,
              caretColor: "rgb(0, 0, 0)",
              color: "rgb(0, 0, 0)",
              fontFamily: "-webkit-standard",
              fontSize: 16,
              fontStyle: "normal",
              fontVariantCaps: "normal",
              fontWeight: 400,
              letterSpacing: "normal",
              orphans: "auto",
              textAlign: "start",
              textTransform: "none",
              whiteSpace: "normal",
              widows: "auto",
              wordSpacing: 0,
              WebkitTextStrokeWidth: 0,
              textDecoration: "none",
            }}
          >
            <span style={{ lineHeight: "14.4px" }}>
              (b)<span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>
              all communications involving the details of other
              <span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>users</span>
            <span style={{ lineHeight: "14.4px" }}>
              <span className="Apple-converted-space">&nbsp;</span>on this
              Platform
            </span>
            <span style={{ lineHeight: "14.4px" }}>
              <span className="Apple-converted-space">&nbsp;</span>and of the
              <span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>
              Buyer and<span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>Seller</span>
            <span style={{ lineHeight: "14.4px" }}>
              <span className="Apple-converted-space">&nbsp;</span>are
              confidential, and must be kept as such by you and must not be
              distributed nor disclosed to any third party.
            </span>
          </div>
          <div
            className="s15"
            style={{
              marginTop: 18,
              marginBottom: 0,
              textIndent: "-42px",
              marginLeft: 42,
              caretColor: "rgb(0, 0, 0)",
              color: "rgb(0, 0, 0)",
              fontFamily: "-webkit-standard",
              fontSize: 16,
              fontStyle: "normal",
              fontVariantCaps: "normal",
              fontWeight: 400,
              letterSpacing: "normal",
              orphans: "auto",
              textAlign: "start",
              textTransform: "none",
              whiteSpace: "normal",
              widows: "auto",
              wordSpacing: 0,
              WebkitTextStrokeWidth: 0,
              textDecoration: "none",
            }}
          >
            <span
              className="s14"
              style={{
                lineHeight: "14.4px",
                textTransform: "uppercase",
                fontWeight: "bold",
              }}
            >
              17<span className="Apple-converted-space">&nbsp;</span>
            </span>
            <a name="_heading=h.3j2qqm3" />
            <a name="_Ref124842982" />
            <span
              className="s14"
              style={{
                lineHeight: "14.4px",
                textTransform: "uppercase",
                fontWeight: "bold",
              }}
            >
              PRIVACY
            </span>
          </div>
          <p
            className="s18"
            style={{
              marginTop: 0,
              marginBottom: 0,
              lineHeight: "14.4px",
              marginLeft: 42,
              caretColor: "rgb(0, 0, 0)",
              color: "rgb(0, 0, 0)",
              fontFamily: "-webkit-standard",
              fontSize: 16,
              fontStyle: "normal",
              fontVariantCaps: "normal",
              fontWeight: 400,
              letterSpacing: "normal",
              orphans: "auto",
              textAlign: "start",
              textIndent: 0,
              textTransform: "none",
              whiteSpace: "normal",
              widows: "auto",
              wordSpacing: 0,
              WebkitTextStrokeWidth: 0,
              textDecoration: "none",
            }}
          >
            <span style={{ lineHeight: "14.4px" }}>
              You agree to be bound by the clauses outlined in
              <span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>As Is Auctions’</span>
            <span style={{ lineHeight: "14.4px" }}>
              <span className="Apple-converted-space">&nbsp;</span>Privacy
              Policy, which can be accessed here
              <span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>&lt;</span>
            <span
              className="s9"
              style={{ lineHeight: "14.4px", fontWeight: "bold" }}
            >
              link
            </span>
            <span style={{ lineHeight: "14.4px" }}>
              &gt;.<span className="Apple-converted-space">&nbsp;</span>
            </span>
          </p>
          <div
            className="s15"
            style={{
              marginTop: 18,
              marginBottom: 0,
              textIndent: "-42px",
              marginLeft: 42,
              caretColor: "rgb(0, 0, 0)",
              color: "rgb(0, 0, 0)",
              fontFamily: "-webkit-standard",
              fontSize: 16,
              fontStyle: "normal",
              fontVariantCaps: "normal",
              fontWeight: 400,
              letterSpacing: "normal",
              orphans: "auto",
              textAlign: "start",
              textTransform: "none",
              whiteSpace: "normal",
              widows: "auto",
              wordSpacing: 0,
              WebkitTextStrokeWidth: 0,
              textDecoration: "none",
            }}
          >
            <span
              className="s14"
              style={{
                lineHeight: "14.4px",
                textTransform: "uppercase",
                fontWeight: "bold",
              }}
            >
              18<span className="Apple-converted-space">&nbsp;</span>
            </span>
            <a name="_Ref124842866" />
            <span
              className="s14"
              style={{
                lineHeight: "14.4px",
                textTransform: "uppercase",
                fontWeight: "bold",
              }}
            >
              COLLECTION NOTICE
            </span>
          </div>
          <div
            className="s16"
            style={{
              marginTop: 0,
              marginBottom: 0,
              textIndent: "-42px",
              marginLeft: 85,
              caretColor: "rgb(0, 0, 0)",
              color: "rgb(0, 0, 0)",
              fontFamily: "-webkit-standard",
              fontSize: 16,
              fontStyle: "normal",
              fontVariantCaps: "normal",
              fontWeight: 400,
              letterSpacing: "normal",
              orphans: "auto",
              textAlign: "start",
              textTransform: "none",
              whiteSpace: "normal",
              widows: "auto",
              wordSpacing: 0,
              WebkitTextStrokeWidth: 0,
              textDecoration: "none",
            }}
          >
            <span style={{ lineHeight: "14.4px" }}>
              (a)<span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>
              We collect personal information about you in order
              <span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>
              to enable you to access and use the Platform, to contact and
              communicate with you, to respond to your enquiries
            </span>
            <span style={{ lineHeight: "14.4px" }}>
              <span className="Apple-converted-space">&nbsp;</span>and for other
              purposes set out in our Privacy Policy.
            </span>
          </div>
          <div
            className="s16"
            style={{
              marginTop: 0,
              marginBottom: 0,
              textIndent: "-42px",
              marginLeft: 85,
              caretColor: "rgb(0, 0, 0)",
              color: "rgb(0, 0, 0)",
              fontFamily: "-webkit-standard",
              fontSize: 16,
              fontStyle: "normal",
              fontVariantCaps: "normal",
              fontWeight: 400,
              letterSpacing: "normal",
              orphans: "auto",
              textAlign: "start",
              textTransform: "none",
              whiteSpace: "normal",
              widows: "auto",
              wordSpacing: 0,
              WebkitTextStrokeWidth: 0,
              textDecoration: "none",
            }}
          >
            <span style={{ lineHeight: "14.4px" }}>
              (b)<span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>
              Our Privacy Policy contains more information about how we use,
              disclose and store your information and details how you can access
              and correct your personal information.
              <span className="Apple-converted-space">&nbsp;</span>
            </span>
          </div>
          <div
            className="s15"
            style={{
              marginTop: 18,
              marginBottom: 0,
              textIndent: "-42px",
              marginLeft: 42,
              caretColor: "rgb(0, 0, 0)",
              color: "rgb(0, 0, 0)",
              fontFamily: "-webkit-standard",
              fontSize: 16,
              fontStyle: "normal",
              fontVariantCaps: "normal",
              fontWeight: 400,
              letterSpacing: "normal",
              orphans: "auto",
              textAlign: "start",
              textTransform: "none",
              whiteSpace: "normal",
              widows: "auto",
              wordSpacing: 0,
              WebkitTextStrokeWidth: 0,
              textDecoration: "none",
            }}
          >
            <span
              className="s14"
              style={{
                lineHeight: "14.4px",
                textTransform: "uppercase",
                fontWeight: "bold",
              }}
            >
              19<span className="Apple-converted-space">&nbsp;</span>
            </span>
            <a name="_heading=h.1y810tw" />
            <span
              className="s14"
              style={{
                lineHeight: "14.4px",
                textTransform: "uppercase",
                fontWeight: "bold",
              }}
            >
              TERMINATION
            </span>
          </div>
          <div
            className="s16"
            style={{
              marginTop: 0,
              marginBottom: 0,
              textIndent: "-42px",
              marginLeft: 85,
              caretColor: "rgb(0, 0, 0)",
              color: "rgb(0, 0, 0)",
              fontFamily: "-webkit-standard",
              fontSize: 16,
              fontStyle: "normal",
              fontVariantCaps: "normal",
              fontWeight: 400,
              letterSpacing: "normal",
              orphans: "auto",
              textAlign: "start",
              textTransform: "none",
              whiteSpace: "normal",
              widows: "auto",
              wordSpacing: 0,
              WebkitTextStrokeWidth: 0,
              textDecoration: "none",
            }}
          >
            <span style={{ lineHeight: "14.4px" }}>
              (a)<span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>
              Either<span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>As Is Auctions</span>
            <span style={{ lineHeight: "14.4px" }}>
              <span className="Apple-converted-space">&nbsp;</span>or the User
              may terminate the User’s account at any time (including any
              listings, memberships or Accounts) for any reason.
              <span className="Apple-converted-space">&nbsp;</span>
            </span>
          </div>
          <div
            className="s16"
            style={{
              marginTop: 0,
              marginBottom: 0,
              textIndent: "-42px",
              marginLeft: 85,
              caretColor: "rgb(0, 0, 0)",
              color: "rgb(0, 0, 0)",
              fontFamily: "-webkit-standard",
              fontSize: 16,
              fontStyle: "normal",
              fontVariantCaps: "normal",
              fontWeight: 400,
              letterSpacing: "normal",
              orphans: "auto",
              textAlign: "start",
              textTransform: "none",
              whiteSpace: "normal",
              widows: "auto",
              wordSpacing: 0,
              WebkitTextStrokeWidth: 0,
              textDecoration: "none",
            }}
          >
            <span style={{ lineHeight: "14.4px" }}>
              (b)<span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>
              If a User wants to terminate their account (and/or
              <span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>
              any other membership they hold in connection with the Platform
            </span>
            <span style={{ lineHeight: "14.4px" }}>
              ), they can do so by using the Platform’s functionality where
              available. Where
              <span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>
              such functionality is not available,
              <span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>As Is Auctions</span>
            <span style={{ lineHeight: "14.4px" }}>
              <span className="Apple-converted-space">&nbsp;</span>will
              <span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>affect</span>
            <span style={{ lineHeight: "14.4px" }}>
              <span className="Apple-converted-space">&nbsp;</span>such
              termination within a reasonable time after receiving written
              notice from the User.
            </span>
          </div>
          <div
            className="s16"
            style={{
              marginTop: 0,
              marginBottom: 0,
              textIndent: "-42px",
              marginLeft: 85,
              caretColor: "rgb(0, 0, 0)",
              color: "rgb(0, 0, 0)",
              fontFamily: "-webkit-standard",
              fontSize: 16,
              fontStyle: "normal",
              fontVariantCaps: "normal",
              fontWeight: 400,
              letterSpacing: "normal",
              orphans: "auto",
              textAlign: "start",
              textTransform: "none",
              whiteSpace: "normal",
              widows: "auto",
              wordSpacing: 0,
              WebkitTextStrokeWidth: 0,
              textDecoration: "none",
            }}
          >
            <span style={{ lineHeight: "14.4px" }}>
              (c)<span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>
              In the event that a User’s Account is terminated:
            </span>
          </div>
          <div
            className="s17"
            style={{
              marginTop: 0,
              marginBottom: 0,
              textIndent: "-42px",
              marginLeft: 127,
              caretColor: "rgb(0, 0, 0)",
              color: "rgb(0, 0, 0)",
              fontFamily: "-webkit-standard",
              fontSize: 16,
              fontStyle: "normal",
              fontVariantCaps: "normal",
              fontWeight: 400,
              letterSpacing: "normal",
              orphans: "auto",
              textAlign: "start",
              textTransform: "none",
              whiteSpace: "normal",
              widows: "auto",
              wordSpacing: 0,
              WebkitTextStrokeWidth: 0,
              textDecoration: "none",
            }}
          >
            <span style={{ lineHeight: "14.4px" }}>
              (i)<span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>
              the User’s access to all posting
              <span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>
              and communication
              <span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>
              tools on the Platform will be revoked;
            </span>
          </div>
          <div
            className="s17"
            style={{
              marginTop: 0,
              marginBottom: 0,
              textIndent: "-42px",
              marginLeft: 127,
              caretColor: "rgb(0, 0, 0)",
              color: "rgb(0, 0, 0)",
              fontFamily: "-webkit-standard",
              fontSize: 16,
              fontStyle: "normal",
              fontVariantCaps: "normal",
              fontWeight: 400,
              letterSpacing: "normal",
              orphans: "auto",
              textAlign: "start",
              textTransform: "none",
              whiteSpace: "normal",
              widows: "auto",
              wordSpacing: 0,
              WebkitTextStrokeWidth: 0,
              textDecoration: "none",
            }}
          >
            <span style={{ lineHeight: "14.4px" }}>
              (ii)<span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>
              the User will be unable to view the details of all other Users
              (including contact details, geographic details, any other personal
              details and
              <span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>Listing</span>
            <span style={{ lineHeight: "14.4px" }}>s or requests); and</span>
          </div>
          <div
            className="s17"
            style={{
              marginTop: 0,
              marginBottom: 0,
              textIndent: "-42px",
              marginLeft: 127,
              caretColor: "rgb(0, 0, 0)",
              color: "rgb(0, 0, 0)",
              fontFamily: "-webkit-standard",
              fontSize: 16,
              fontStyle: "normal",
              fontVariantCaps: "normal",
              fontWeight: 400,
              letterSpacing: "normal",
              orphans: "auto",
              textAlign: "start",
              textTransform: "none",
              whiteSpace: "normal",
              widows: "auto",
              wordSpacing: 0,
              WebkitTextStrokeWidth: 0,
              textDecoration: "none",
            }}
          >
            <span style={{ lineHeight: "14.4px" }}>
              (iii)<span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>
              the User may be unable to view the details of other
              <span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>
              Buyer or<span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>Seller</span>
            <span style={{ lineHeight: "14.4px" }}>
              s (including contact details, geographic details and any other
              details), and all
              <span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>Listing</span>
            <span style={{ lineHeight: "14.4px" }}>
              s previously posted by the respective User will also be removed
              from the Platform.
            </span>
          </div>
          <div
            className="s16"
            style={{
              marginTop: 0,
              marginBottom: 0,
              textIndent: "-42px",
              marginLeft: 85,
              caretColor: "rgb(0, 0, 0)",
              color: "rgb(0, 0, 0)",
              fontFamily: "-webkit-standard",
              fontSize: 16,
              fontStyle: "normal",
              fontVariantCaps: "normal",
              fontWeight: 400,
              letterSpacing: "normal",
              orphans: "auto",
              textAlign: "start",
              textTransform: "none",
              whiteSpace: "normal",
              widows: "auto",
              wordSpacing: 0,
              WebkitTextStrokeWidth: 0,
              textDecoration: "none",
            }}
          >
            <span style={{ lineHeight: "14.4px" }}>
              (d)<span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>
              Notwithstanding termination or expiry of your Account, this
              agreement, or any other membership you hold in connection with the
              Platform, the provisions of Part A and any other provision in this
              agreement which by its nature would reasonably be expected to be
              complied with after termination or expiry, will continue to apply.
            </span>
          </div>
          <div
            className="s15"
            style={{
              marginTop: 18,
              marginBottom: 0,
              textIndent: "-42px",
              marginLeft: 42,
              caretColor: "rgb(0, 0, 0)",
              color: "rgb(0, 0, 0)",
              fontFamily: "-webkit-standard",
              fontSize: 16,
              fontStyle: "normal",
              fontVariantCaps: "normal",
              fontWeight: 400,
              letterSpacing: "normal",
              orphans: "auto",
              textAlign: "start",
              textTransform: "none",
              whiteSpace: "normal",
              widows: "auto",
              wordSpacing: 0,
              WebkitTextStrokeWidth: 0,
              textDecoration: "none",
            }}
          >
            <span
              className="s14"
              style={{
                lineHeight: "14.4px",
                textTransform: "uppercase",
                fontWeight: "bold",
              }}
            >
              20<span className="Apple-converted-space">&nbsp;</span>
            </span>
            <a name="_heading=h.4i7ojhp" />
            <span
              className="s14"
              style={{
                lineHeight: "14.4px",
                textTransform: "uppercase",
                fontWeight: "bold",
              }}
            >
              TAX
            </span>
          </div>
          <p
            className="s18"
            style={{
              marginTop: 0,
              marginBottom: 0,
              lineHeight: "14.4px",
              marginLeft: 42,
              caretColor: "rgb(0, 0, 0)",
              color: "rgb(0, 0, 0)",
              fontFamily: "-webkit-standard",
              fontSize: 16,
              fontStyle: "normal",
              fontVariantCaps: "normal",
              fontWeight: 400,
              letterSpacing: "normal",
              orphans: "auto",
              textAlign: "start",
              textIndent: 0,
              textTransform: "none",
              whiteSpace: "normal",
              widows: "auto",
              wordSpacing: 0,
              WebkitTextStrokeWidth: 0,
              textDecoration: "none",
            }}
          >
            <span style={{ lineHeight: "14.4px" }}>
              You are responsible for the collection and remission of all taxes
              associated with the
              <span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>
              goods or<span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>
              services you provide or receive or any transactions through your
              use of the Platform, and
              <span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>As Is Auctions</span>
            <span style={{ lineHeight: "14.4px" }}>
              <span className="Apple-converted-space">&nbsp;</span>will not be
              held accountable in relation to any transactions between
              <span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>Buyers</span>
            <span style={{ lineHeight: "14.4px" }}>
              <span className="Apple-converted-space">&nbsp;</span>and
              <span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>Seller</span>
            <span style={{ lineHeight: "14.4px" }}>
              s where tax related misconduct has occurred.
            </span>
          </p>
          <div
            className="s15"
            style={{
              marginTop: 18,
              marginBottom: 0,
              textIndent: "-42px",
              marginLeft: 42,
              caretColor: "rgb(0, 0, 0)",
              color: "rgb(0, 0, 0)",
              fontFamily: "-webkit-standard",
              fontSize: 16,
              fontStyle: "normal",
              fontVariantCaps: "normal",
              fontWeight: 400,
              letterSpacing: "normal",
              orphans: "auto",
              textAlign: "start",
              textTransform: "none",
              whiteSpace: "normal",
              widows: "auto",
              wordSpacing: 0,
              WebkitTextStrokeWidth: 0,
              textDecoration: "none",
            }}
          >
            <span
              className="s14"
              style={{
                lineHeight: "14.4px",
                textTransform: "uppercase",
                fontWeight: "bold",
              }}
            >
              21<span className="Apple-converted-space">&nbsp;</span>
            </span>
            <a name="_heading=h.2xcytpi" />
            <span
              className="s14"
              style={{
                lineHeight: "14.4px",
                textTransform: "uppercase",
                fontWeight: "bold",
              }}
            >
              RECORD / AUDIT
            </span>
          </div>
          <p
            className="s18"
            style={{
              marginTop: 0,
              marginBottom: 0,
              lineHeight: "14.4px",
              marginLeft: 42,
              caretColor: "rgb(0, 0, 0)",
              color: "rgb(0, 0, 0)",
              fontFamily: "-webkit-standard",
              fontSize: 16,
              fontStyle: "normal",
              fontVariantCaps: "normal",
              fontWeight: 400,
              letterSpacing: "normal",
              orphans: "auto",
              textAlign: "start",
              textIndent: 0,
              textTransform: "none",
              whiteSpace: "normal",
              widows: "auto",
              wordSpacing: 0,
              WebkitTextStrokeWidth: 0,
              textDecoration: "none",
            }}
          >
            <span style={{ lineHeight: "14.4px" }}>
              To the extent permitted by law,
              <span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>As Is Auctions</span>
            <span style={{ lineHeight: "14.4px" }}>
              <span className="Apple-converted-space">&nbsp;</span>reserves the
              right to keep all records of any and all transactions and
              communications made through this Platform between you and other
              Users (including conversations, user posts, job request bids,
              comments, feedback, cookies, and I.P. address information) for
              administration purposes and also holds the right to produce these
              records in the event of any legal dispute involving
              <span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>As Is Auctions</span>
            <span style={{ lineHeight: "14.4px" }}>.</span>
          </p>
          <div
            className="s15"
            style={{
              marginTop: 18,
              marginBottom: 0,
              textIndent: "-42px",
              marginLeft: 42,
              caretColor: "rgb(0, 0, 0)",
              color: "rgb(0, 0, 0)",
              fontFamily: "-webkit-standard",
              fontSize: 16,
              fontStyle: "normal",
              fontVariantCaps: "normal",
              fontWeight: 400,
              letterSpacing: "normal",
              orphans: "auto",
              textAlign: "start",
              textTransform: "none",
              whiteSpace: "normal",
              widows: "auto",
              wordSpacing: 0,
              WebkitTextStrokeWidth: 0,
              textDecoration: "none",
            }}
          >
            <span
              className="s14"
              style={{
                lineHeight: "14.4px",
                textTransform: "uppercase",
                fontWeight: "bold",
              }}
            >
              22<span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span
              className="s14"
              style={{
                lineHeight: "14.4px",
                textTransform: "uppercase",
                fontWeight: "bold",
              }}
            >
              NOTICES
            </span>
          </div>
          <div
            className="s24"
            style={{
              marginTop: 0,
              marginBottom: 0,
              textIndent: "-42px",
              borderBottomStyle: "solid",
              borderBottomWidth: "thin",
              borderColor: "rgb(0, 0, 0)",
              marginLeft: 85,
              caretColor: "rgb(0, 0, 0)",
              color: "rgb(0, 0, 0)",
              fontFamily: "-webkit-standard",
              fontSize: 16,
              fontStyle: "normal",
              fontVariantCaps: "normal",
              fontWeight: 400,
              letterSpacing: "normal",
              orphans: "auto",
              textAlign: "start",
              textTransform: "none",
              whiteSpace: "normal",
              widows: "auto",
              wordSpacing: 0,
              WebkitTextStrokeWidth: 0,
              textDecoration: "none",
            }}
          >
            <span style={{ lineHeight: "14.4px" }}>
              (a)<span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span
              className="s23"
              style={{ lineHeight: "14.4px", color: "rgb(0, 0, 0)" }}
            >
              A notice or other communication to a party under this agreement
              must be:
            </span>
          </div>
          <div
            className="s17"
            style={{
              marginTop: 0,
              marginBottom: 0,
              textIndent: "-42px",
              marginLeft: 127,
              caretColor: "rgb(0, 0, 0)",
              color: "rgb(0, 0, 0)",
              fontFamily: "-webkit-standard",
              fontSize: 16,
              fontStyle: "normal",
              fontVariantCaps: "normal",
              fontWeight: 400,
              letterSpacing: "normal",
              orphans: "auto",
              textAlign: "start",
              textTransform: "none",
              whiteSpace: "normal",
              widows: "auto",
              wordSpacing: 0,
              WebkitTextStrokeWidth: 0,
              textDecoration: "none",
            }}
          >
            <span style={{ lineHeight: "14.4px" }}>
              (i)<span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>
              in writing and in English; and
            </span>
          </div>
          <div
            className="s17"
            style={{
              marginTop: 0,
              marginBottom: 0,
              textIndent: "-42px",
              marginLeft: 127,
              caretColor: "rgb(0, 0, 0)",
              color: "rgb(0, 0, 0)",
              fontFamily: "-webkit-standard",
              fontSize: 16,
              fontStyle: "normal",
              fontVariantCaps: "normal",
              fontWeight: 400,
              letterSpacing: "normal",
              orphans: "auto",
              textAlign: "start",
              textTransform: "none",
              whiteSpace: "normal",
              widows: "auto",
              wordSpacing: 0,
              WebkitTextStrokeWidth: 0,
              textDecoration: "none",
            }}
          >
            <span style={{ lineHeight: "14.4px" }}>
              (ii)<span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>
              delivered via email to the other party, to the email address
              specified in this agreement, or if no email address is specified
              in this agreement, then the email address most regularly used by
              the parties to correspond regarding the subject
              <span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>
              matter of this agreement as at the date of this agreement (
            </span>
            <span
              className="s9"
              style={{ lineHeight: "14.4px", fontWeight: "bold" }}
            >
              Email Address
            </span>
            <span style={{ lineHeight: "14.4px" }}>
              ). The parties may update their Email Address by notice to the
              other party.
            </span>
          </div>
          <div
            className="s16"
            style={{
              marginTop: 0,
              marginBottom: 0,
              textIndent: "-42px",
              marginLeft: 85,
              caretColor: "rgb(0, 0, 0)",
              color: "rgb(0, 0, 0)",
              fontFamily: "-webkit-standard",
              fontSize: 16,
              fontStyle: "normal",
              fontVariantCaps: "normal",
              fontWeight: 400,
              letterSpacing: "normal",
              orphans: "auto",
              textAlign: "start",
              textTransform: "none",
              whiteSpace: "normal",
              widows: "auto",
              wordSpacing: 0,
              WebkitTextStrokeWidth: 0,
              textDecoration: "none",
            }}
          >
            <span style={{ lineHeight: "14.4px" }}>
              (b)<span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>
              Unless the party sending the notice knows or reasonably ought to
              suspect that an email was not delivered to the other party’s Email
              Address, notice will be taken to be given:
            </span>
          </div>
          <div
            className="s17"
            style={{
              marginTop: 0,
              marginBottom: 0,
              textIndent: "-42px",
              marginLeft: 127,
              caretColor: "rgb(0, 0, 0)",
              color: "rgb(0, 0, 0)",
              fontFamily: "-webkit-standard",
              fontSize: 16,
              fontStyle: "normal",
              fontVariantCaps: "normal",
              fontWeight: 400,
              letterSpacing: "normal",
              orphans: "auto",
              textAlign: "start",
              textTransform: "none",
              whiteSpace: "normal",
              widows: "auto",
              wordSpacing: 0,
              WebkitTextStrokeWidth: 0,
              textDecoration: "none",
            }}
          >
            <span style={{ lineHeight: "14.4px" }}>
              (i)<span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>
              24 hours after the email was sent, unless that falls on a
              Saturday, Sunday or a public holiday in the state or territory
              whose laws govern this agreement, in which case the notice will be
              taken to be given on the next occurring business day in that state
              or territory; or
            </span>
          </div>
          <div
            className="s17"
            style={{
              marginTop: 0,
              marginBottom: 0,
              textIndent: "-42px",
              marginLeft: 127,
              caretColor: "rgb(0, 0, 0)",
              color: "rgb(0, 0, 0)",
              fontFamily: "-webkit-standard",
              fontSize: 16,
              fontStyle: "normal",
              fontVariantCaps: "normal",
              fontWeight: 400,
              letterSpacing: "normal",
              orphans: "auto",
              textAlign: "start",
              textTransform: "none",
              whiteSpace: "normal",
              widows: "auto",
              wordSpacing: 0,
              WebkitTextStrokeWidth: 0,
              textDecoration: "none",
            }}
          >
            <span style={{ lineHeight: "14.4px" }}>
              (ii)<span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>
              when replied to by the other party,
            </span>
          </div>
          <p
            className="s25"
            style={{
              marginTop: 0,
              marginBottom: 0,
              lineHeight: "14.4px",
              marginLeft: 85,
              caretColor: "rgb(0, 0, 0)",
              color: "rgb(0, 0, 0)",
              fontFamily: "-webkit-standard",
              fontSize: 16,
              fontStyle: "normal",
              fontVariantCaps: "normal",
              fontWeight: 400,
              letterSpacing: "normal",
              orphans: "auto",
              textAlign: "start",
              textIndent: 0,
              textTransform: "none",
              whiteSpace: "normal",
              widows: "auto",
              wordSpacing: 0,
              WebkitTextStrokeWidth: 0,
              textDecoration: "none",
            }}
          >
            <span style={{ lineHeight: "14.4px" }}>whichever is earlier.</span>
          </p>
          <div
            className="s15"
            style={{
              marginTop: 18,
              marginBottom: 0,
              textIndent: "-42px",
              marginLeft: 42,
              caretColor: "rgb(0, 0, 0)",
              color: "rgb(0, 0, 0)",
              fontFamily: "-webkit-standard",
              fontSize: 16,
              fontStyle: "normal",
              fontVariantCaps: "normal",
              fontWeight: 400,
              letterSpacing: "normal",
              orphans: "auto",
              textAlign: "start",
              textTransform: "none",
              whiteSpace: "normal",
              widows: "auto",
              wordSpacing: 0,
              WebkitTextStrokeWidth: 0,
              textDecoration: "none",
            }}
          >
            <span
              className="s14"
              style={{
                lineHeight: "14.4px",
                textTransform: "uppercase",
                fontWeight: "bold",
              }}
            >
              23<span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span
              className="s14"
              style={{
                lineHeight: "14.4px",
                textTransform: "uppercase",
                fontWeight: "bold",
              }}
            >
              GENERAL
            </span>
          </div>
          <div
            className="s20"
            style={{
              marginTop: 20,
              marginBottom: "20px",
              textIndent: "-42px",
              marginLeft: 42,
              caretColor: "rgb(0, 0, 0)",
              color: "rgb(0, 0, 0)",
              fontFamily: "-webkit-standard",
              fontSize: 16,
              fontStyle: "normal",
              fontVariantCaps: "normal",
              fontWeight: 400,
              letterSpacing: "normal",
              orphans: "auto",
              textAlign: "start",
              textTransform: "none",
              whiteSpace: "normal",
              widows: "auto",
              wordSpacing: 0,
              WebkitTextStrokeWidth: 0,
              textDecoration: "none",
            }}
          >
            <span
              className="s19"
              style={{ lineHeight: "14.4px", textTransform: "uppercase" }}
            >
              23.1<span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span
              className="s19"
              style={{ lineHeight: "14.4px", textTransform: "uppercase" }}
            >
              GOVERNING LAW AND JURISDICTION
            </span>
          </div>
          <p
            className="s18"
            style={{
              marginTop: 0,
              marginBottom: 0,
              lineHeight: "14.4px",
              marginLeft: 42,
              caretColor: "rgb(0, 0, 0)",
              color: "rgb(0, 0, 0)",
              fontFamily: "-webkit-standard",
              fontSize: 16,
              fontStyle: "normal",
              fontVariantCaps: "normal",
              fontWeight: 400,
              letterSpacing: "normal",
              orphans: "auto",
              textAlign: "start",
              textIndent: 0,
              textTransform: "none",
              whiteSpace: "normal",
              widows: "auto",
              wordSpacing: 0,
              WebkitTextStrokeWidth: 0,
              textDecoration: "none",
            }}
          >
            <span style={{ lineHeight: "14.4px" }}>
              This agreement is governed by the law applying in
              <span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>
              South Australia, Australia.
            </span>
            <span style={{ lineHeight: "14.4px" }}>
              <span className="Apple-converted-space">&nbsp;</span>Each party
              irrevocably submits to the exclusive jurisdiction of the courts of
              <span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>
              South Australia, Australia
            </span>
            <span style={{ lineHeight: "14.4px" }}>
              and courts of appeal from them in respect of any proceedings
              arising out of or in connection with this agreement. Each party
              irrevocably waives any objection to the venue of any legal process
              on the basis that the process has been brought in an inconvenient
              forum.
            </span>
          </p>
          <div
            className="s20"
            style={{
              marginTop: 20,
              marginBottom: 0,
              textIndent: "-42px",
              marginLeft: 42,
              caretColor: "rgb(0, 0, 0)",
              color: "rgb(0, 0, 0)",
              fontFamily: "-webkit-standard",
              fontSize: 16,
              fontStyle: "normal",
              fontVariantCaps: "normal",
              fontWeight: 400,
              letterSpacing: "normal",
              orphans: "auto",
              textAlign: "start",
              textTransform: "none",
              whiteSpace: "normal",
              widows: "auto",
              wordSpacing: 0,
              WebkitTextStrokeWidth: 0,
              textDecoration: "none",
            }}
          >
            <span
              className="s19"
              style={{ lineHeight: "14.4px", textTransform: "uppercase" }}
            >
              23.2<span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span
              className="s19"
              style={{ lineHeight: "14.4px", textTransform: "uppercase" }}
            >
              WAIVER
            </span>
          </div>
          <p
            className="s18"
            style={{
              marginTop: 0,
              marginBottom: 0,
              lineHeight: "14.4px",
              marginLeft: 42,
              caretColor: "rgb(0, 0, 0)",
              color: "rgb(0, 0, 0)",
              fontFamily: "-webkit-standard",
              fontSize: 16,
              fontStyle: "normal",
              fontVariantCaps: "normal",
              fontWeight: 400,
              letterSpacing: "normal",
              orphans: "auto",
              textAlign: "start",
              textIndent: 0,
              textTransform: "none",
              whiteSpace: "normal",
              widows: "auto",
              wordSpacing: 0,
              WebkitTextStrokeWidth: 0,
              textDecoration: "none",
            }}
          >
            <span style={{ lineHeight: "14.4px" }}>
              No party to this agreement may rely on the words or conduct of any
              other party as a waiver of any right unless the waiver is in
              writing and signed by the party granting the waiver.
            </span>
          </p>
          <div
            className="s20"
            style={{
              marginTop: 12,
              marginBottom: 0,
              textIndent: "-42px",
              marginLeft: 42,
              caretColor: "rgb(0, 0, 0)",
              color: "rgb(0, 0, 0)",
              fontFamily: "-webkit-standard",
              fontSize: 16,
              fontStyle: "normal",
              fontVariantCaps: "normal",
              fontWeight: 400,
              letterSpacing: "normal",
              orphans: "auto",
              textAlign: "start",
              textTransform: "none",
              whiteSpace: "normal",
              widows: "auto",
              wordSpacing: 0,
              WebkitTextStrokeWidth: 0,
              textDecoration: "none",
            }}
          >
            <span
              className="s19"
              style={{ lineHeight: "14.4px", textTransform: "uppercase" }}
            >
              23.3<span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span
              className="s19"
              style={{ lineHeight: "14.4px", textTransform: "uppercase" }}
            >
              SEVERANCE
            </span>
          </div>
          <p
            className="s18"
            style={{
              marginTop: 0,
              marginBottom: 0,
              lineHeight: "14.4px",
              marginLeft: 42,
              caretColor: "rgb(0, 0, 0)",
              color: "rgb(0, 0, 0)",
              fontFamily: "-webkit-standard",
              fontSize: 16,
              fontStyle: "normal",
              fontVariantCaps: "normal",
              fontWeight: 400,
              letterSpacing: "normal",
              orphans: "auto",
              textAlign: "start",
              textIndent: 0,
              textTransform: "none",
              whiteSpace: "normal",
              widows: "auto",
              wordSpacing: 0,
              WebkitTextStrokeWidth: 0,
              textDecoration: "none",
            }}
          >
            <span style={{ lineHeight: "14.4px" }}>
              Any term of this agreement which is wholly or partially void or
              unenforceable is severed to the extent that it is void or
              unenforceable. The validity and enforceability of the remainder of
              this agreement is not limited or otherwise affected.
            </span>
          </p>
          <div
            className="s20"
            style={{
              marginTop: 12,
              marginBottom: 0,
              textIndent: "-42px",
              marginLeft: 42,
              caretColor: "rgb(0, 0, 0)",
              color: "rgb(0, 0, 0)",
              fontFamily: "-webkit-standard",
              fontSize: 16,
              fontStyle: "normal",
              fontVariantCaps: "normal",
              fontWeight: 400,
              letterSpacing: "normal",
              orphans: "auto",
              textAlign: "start",
              textTransform: "none",
              whiteSpace: "normal",
              widows: "auto",
              wordSpacing: 0,
              WebkitTextStrokeWidth: 0,
              textDecoration: "none",
            }}
          >
            <span
              className="s19"
              style={{ lineHeight: "14.4px", textTransform: "uppercase" }}
            >
              23.4<span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span
              className="s19"
              style={{ lineHeight: "14.4px", textTransform: "uppercase" }}
            >
              JOINT AND SEVERAL LIABILITY
            </span>
          </div>
          <p
            className="s18"
            style={{
              marginTop: 0,
              marginBottom: 0,
              lineHeight: "14.4px",
              marginLeft: 42,
              caretColor: "rgb(0, 0, 0)",
              color: "rgb(0, 0, 0)",
              fontFamily: "-webkit-standard",
              fontSize: 16,
              fontStyle: "normal",
              fontVariantCaps: "normal",
              fontWeight: 400,
              letterSpacing: "normal",
              orphans: "auto",
              textAlign: "start",
              textIndent: 0,
              textTransform: "none",
              whiteSpace: "normal",
              widows: "auto",
              wordSpacing: 0,
              WebkitTextStrokeWidth: 0,
              textDecoration: "none",
            }}
          >
            <span style={{ lineHeight: "14.4px" }}>
              An obligation or a liability assumed by, or a right conferred on,
              two or more persons binds or benefits them jointly and severally.
            </span>
          </p>
          <div
            className="s20"
            style={{
              marginTop: 12,
              marginBottom: 0,
              textIndent: "-42px",
              marginLeft: 42,
              caretColor: "rgb(0, 0, 0)",
              color: "rgb(0, 0, 0)",
              fontFamily: "-webkit-standard",
              fontSize: 16,
              fontStyle: "normal",
              fontVariantCaps: "normal",
              fontWeight: 400,
              letterSpacing: "normal",
              orphans: "auto",
              textAlign: "start",
              textTransform: "none",
              whiteSpace: "normal",
              widows: "auto",
              wordSpacing: 0,
              WebkitTextStrokeWidth: 0,
              textDecoration: "none",
            }}
          >
            <span
              className="s19"
              style={{ lineHeight: "14.4px", textTransform: "uppercase" }}
            >
              23.5<span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span
              className="s19"
              style={{ lineHeight: "14.4px", textTransform: "uppercase" }}
            >
              ASSIGNMENT
            </span>
          </div>
          <p
            className="s18"
            style={{
              marginTop: 0,
              marginBottom: 0,
              lineHeight: "14.4px",
              marginLeft: 42,
              caretColor: "rgb(0, 0, 0)",
              color: "rgb(0, 0, 0)",
              fontFamily: "-webkit-standard",
              fontSize: 16,
              fontStyle: "normal",
              fontVariantCaps: "normal",
              fontWeight: 400,
              letterSpacing: "normal",
              orphans: "auto",
              textAlign: "start",
              textIndent: 0,
              textTransform: "none",
              whiteSpace: "normal",
              widows: "auto",
              wordSpacing: 0,
              WebkitTextStrokeWidth: 0,
              textDecoration: "none",
            }}
          >
            <span style={{ lineHeight: "14.4px" }}>
              A party cannot assign, novate or otherwise transfer any of its
              rights or obligations under this agreement without the prior
              written consent of the other party.
            </span>
          </p>
          <div
            className="s20"
            style={{
              marginTop: 12,
              marginBottom: 0,
              textIndent: "-42px",
              marginLeft: 42,
              caretColor: "rgb(0, 0, 0)",
              color: "rgb(0, 0, 0)",
              fontFamily: "-webkit-standard",
              fontSize: 16,
              fontStyle: "normal",
              fontVariantCaps: "normal",
              fontWeight: 400,
              letterSpacing: "normal",
              orphans: "auto",
              textAlign: "start",
              textTransform: "none",
              whiteSpace: "normal",
              widows: "auto",
              wordSpacing: 0,
              WebkitTextStrokeWidth: 0,
              textDecoration: "none",
            }}
          >
            <span
              className="s19"
              style={{ lineHeight: "14.4px", textTransform: "uppercase" }}
            >
              23.6<span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span
              className="s19"
              style={{ lineHeight: "14.4px", textTransform: "uppercase" }}
            >
              COSTS
            </span>
          </div>
          <p
            className="s18"
            style={{
              marginTop: 0,
              marginBottom: 0,
              lineHeight: "14.4px",
              marginLeft: 42,
              caretColor: "rgb(0, 0, 0)",
              color: "rgb(0, 0, 0)",
              fontFamily: "-webkit-standard",
              fontSize: 16,
              fontStyle: "normal",
              fontVariantCaps: "normal",
              fontWeight: 400,
              letterSpacing: "normal",
              orphans: "auto",
              textAlign: "start",
              textIndent: 0,
              textTransform: "none",
              whiteSpace: "normal",
              widows: "auto",
              wordSpacing: 0,
              WebkitTextStrokeWidth: 0,
              textDecoration: "none",
            }}
          >
            <span style={{ lineHeight: "14.4px" }}>
              Except as otherwise provided in this agreement, each party must
              pay its own costs and expenses in connection with negotiating,
              preparing, executing and performing this agreement.
            </span>
          </p>
          <div
            className="s20"
            style={{
              marginTop: 12,
              marginBottom: 0,
              textIndent: "-42px",
              marginLeft: 42,
              caretColor: "rgb(0, 0, 0)",
              color: "rgb(0, 0, 0)",
              fontFamily: "-webkit-standard",
              fontSize: 16,
              fontStyle: "normal",
              fontVariantCaps: "normal",
              fontWeight: 400,
              letterSpacing: "normal",
              orphans: "auto",
              textAlign: "start",
              textTransform: "none",
              whiteSpace: "normal",
              widows: "auto",
              wordSpacing: 0,
              WebkitTextStrokeWidth: 0,
              textDecoration: "none",
            }}
          >
            <span
              className="s19"
              style={{ lineHeight: "14.4px", textTransform: "uppercase" }}
            >
              23.7<span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span
              className="s19"
              style={{ lineHeight: "14.4px", textTransform: "uppercase" }}
            >
              ENTIRE AGREEMENT
            </span>
          </div>
          <p
            className="s18"
            style={{
              marginTop: 0,
              marginBottom: 0,
              lineHeight: "14.4px",
              marginLeft: 42,
              caretColor: "rgb(0, 0, 0)",
              color: "rgb(0, 0, 0)",
              fontFamily: "-webkit-standard",
              fontSize: 16,
              fontStyle: "normal",
              fontVariantCaps: "normal",
              fontWeight: 400,
              letterSpacing: "normal",
              orphans: "auto",
              textAlign: "start",
              textIndent: 0,
              textTransform: "none",
              whiteSpace: "normal",
              widows: "auto",
              wordSpacing: 0,
              WebkitTextStrokeWidth: 0,
              textDecoration: "none",
            }}
          >
            <span style={{ lineHeight: "14.4px" }}>
              This agreement embodies the entire agreement between the parties
              and supersedes any prior negotiation, conduct, arrangement,
              understanding or agreement, express or implied, in relation to the
              subject matter of this agreement.
            </span>
          </p>
          <div
            className="s20"
            style={{
              marginTop: 12,
              marginBottom: 0,
              textIndent: "-42px",
              marginLeft: 42,
              caretColor: "rgb(0, 0, 0)",
              color: "rgb(0, 0, 0)",
              fontFamily: "-webkit-standard",
              fontSize: 16,
              fontStyle: "normal",
              fontVariantCaps: "normal",
              fontWeight: 400,
              letterSpacing: "normal",
              orphans: "auto",
              textAlign: "start",
              textTransform: "none",
              whiteSpace: "normal",
              widows: "auto",
              wordSpacing: 0,
              WebkitTextStrokeWidth: 0,
              textDecoration: "none",
            }}
          >
            <span
              className="s19"
              style={{ lineHeight: "14.4px", textTransform: "uppercase" }}
            >
              23.8<span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span
              className="s19"
              style={{ lineHeight: "14.4px", textTransform: "uppercase" }}
            >
              INTERPRETATION
            </span>
          </div>
          <div
            className="s16"
            style={{
              marginTop: 0,
              marginBottom: 0,
              textIndent: "-42px",
              marginLeft: 85,
              caretColor: "rgb(0, 0, 0)",
              color: "rgb(0, 0, 0)",
              fontFamily: "-webkit-standard",
              fontSize: 16,
              fontStyle: "normal",
              fontVariantCaps: "normal",
              fontWeight: 400,
              letterSpacing: "normal",
              orphans: "auto",
              textAlign: "start",
              textTransform: "none",
              whiteSpace: "normal",
              widows: "auto",
              wordSpacing: 0,
              WebkitTextStrokeWidth: 0,
              textDecoration: "none",
            }}
          >
            <span style={{ lineHeight: "14.4px" }}>
              (a)<span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>(</span>
            <span
              className="s9"
              style={{ lineHeight: "14.4px", fontWeight: "bold" }}
            >
              singular and plural
            </span>
            <span style={{ lineHeight: "14.4px" }}>
              ) words in the singular includes the plural (and vice versa);
            </span>
          </div>
          <div
            className="s16"
            style={{
              marginTop: 0,
              marginBottom: 0,
              textIndent: "-42px",
              marginLeft: 85,
              caretColor: "rgb(0, 0, 0)",
              color: "rgb(0, 0, 0)",
              fontFamily: "-webkit-standard",
              fontSize: 16,
              fontStyle: "normal",
              fontVariantCaps: "normal",
              fontWeight: 400,
              letterSpacing: "normal",
              orphans: "auto",
              textAlign: "start",
              textTransform: "none",
              whiteSpace: "normal",
              widows: "auto",
              wordSpacing: 0,
              WebkitTextStrokeWidth: 0,
              textDecoration: "none",
            }}
          >
            <span style={{ lineHeight: "14.4px" }}>
              (b)<span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>(</span>
            <span
              className="s9"
              style={{ lineHeight: "14.4px", fontWeight: "bold" }}
            >
              gender
            </span>
            <span style={{ lineHeight: "14.4px" }}>
              ) words indicating a gender includes the corresponding words of
              any other gender;
            </span>
          </div>
          <div
            className="s16"
            style={{
              marginTop: 0,
              marginBottom: 0,
              textIndent: "-42px",
              marginLeft: 85,
              caretColor: "rgb(0, 0, 0)",
              color: "rgb(0, 0, 0)",
              fontFamily: "-webkit-standard",
              fontSize: 16,
              fontStyle: "normal",
              fontVariantCaps: "normal",
              fontWeight: 400,
              letterSpacing: "normal",
              orphans: "auto",
              textAlign: "start",
              textTransform: "none",
              whiteSpace: "normal",
              widows: "auto",
              wordSpacing: 0,
              WebkitTextStrokeWidth: 0,
              textDecoration: "none",
            }}
          >
            <span style={{ lineHeight: "14.4px" }}>
              (c)<span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>(</span>
            <span
              className="s9"
              style={{ lineHeight: "14.4px", fontWeight: "bold" }}
            >
              defined terms
            </span>
            <span style={{ lineHeight: "14.4px" }}>
              ) if a word or phrase is given a defined meaning, any other part
              of speech or grammatical form of that word or phrase has a
              corresponding meaning;
            </span>
          </div>
          <div
            className="s16"
            style={{
              marginTop: 0,
              marginBottom: 0,
              textIndent: "-42px",
              marginLeft: 85,
              caretColor: "rgb(0, 0, 0)",
              color: "rgb(0, 0, 0)",
              fontFamily: "-webkit-standard",
              fontSize: 16,
              fontStyle: "normal",
              fontVariantCaps: "normal",
              fontWeight: 400,
              letterSpacing: "normal",
              orphans: "auto",
              textAlign: "start",
              textTransform: "none",
              whiteSpace: "normal",
              widows: "auto",
              wordSpacing: 0,
              WebkitTextStrokeWidth: 0,
              textDecoration: "none",
            }}
          >
            <span style={{ lineHeight: "14.4px" }}>
              (d)<span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>(</span>
            <span
              className="s9"
              style={{ lineHeight: "14.4px", fontWeight: "bold" }}
            >
              person
            </span>
            <span style={{ lineHeight: "14.4px" }}>
              ) a reference to “person” or “you” includes an individual, the
              estate of an individual, a corporation, an authority, an
              association, consortium or joint venture (whether incorporated or
              unincorporated), a partnership, a trust and any other entity;
            </span>
          </div>
          <div
            className="s16"
            style={{
              marginTop: 0,
              marginBottom: 0,
              textIndent: "-42px",
              marginLeft: 85,
              caretColor: "rgb(0, 0, 0)",
              color: "rgb(0, 0, 0)",
              fontFamily: "-webkit-standard",
              fontSize: 16,
              fontStyle: "normal",
              fontVariantCaps: "normal",
              fontWeight: 400,
              letterSpacing: "normal",
              orphans: "auto",
              textAlign: "start",
              textTransform: "none",
              whiteSpace: "normal",
              widows: "auto",
              wordSpacing: 0,
              WebkitTextStrokeWidth: 0,
              textDecoration: "none",
            }}
          >
            <span style={{ lineHeight: "14.4px" }}>
              (e)<span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>(</span>
            <span
              className="s9"
              style={{ lineHeight: "14.4px", fontWeight: "bold" }}
            >
              party
            </span>
            <span style={{ lineHeight: "14.4px" }}>
              ) a reference to a party includes that party’s executors,
              administrators, successors and permitted assigns, including
              persons taking by way of novation and, in the case of a trustee,
              includes any substituted or additional trustee;
            </span>
          </div>
          <div
            className="s16"
            style={{
              marginTop: 0,
              marginBottom: 0,
              textIndent: "-42px",
              marginLeft: 85,
              caretColor: "rgb(0, 0, 0)",
              color: "rgb(0, 0, 0)",
              fontFamily: "-webkit-standard",
              fontSize: 16,
              fontStyle: "normal",
              fontVariantCaps: "normal",
              fontWeight: 400,
              letterSpacing: "normal",
              orphans: "auto",
              textAlign: "start",
              textTransform: "none",
              whiteSpace: "normal",
              widows: "auto",
              wordSpacing: 0,
              WebkitTextStrokeWidth: 0,
              textDecoration: "none",
            }}
          >
            <span style={{ lineHeight: "14.4px" }}>
              (f)<span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>(</span>
            <span
              className="s9"
              style={{ lineHeight: "14.4px", fontWeight: "bold" }}
            >
              this agreement
            </span>
            <span style={{ lineHeight: "14.4px" }}>
              ) a reference to a party, clause, paragraph, schedule, exhibit,
              attachment or annexure is a reference to a party, clause,
              paragraph, schedule, exhibit, attachment or annexure to or of this
              agreement, and a reference to this agreement includes all
              schedules, exhibits, attachments and annexures to it;
            </span>
          </div>
          <div
            className="s16"
            style={{
              marginTop: 0,
              marginBottom: 0,
              textIndent: "-42px",
              marginLeft: 85,
              caretColor: "rgb(0, 0, 0)",
              color: "rgb(0, 0, 0)",
              fontFamily: "-webkit-standard",
              fontSize: 16,
              fontStyle: "normal",
              fontVariantCaps: "normal",
              fontWeight: 400,
              letterSpacing: "normal",
              orphans: "auto",
              textAlign: "start",
              textTransform: "none",
              whiteSpace: "normal",
              widows: "auto",
              wordSpacing: 0,
              WebkitTextStrokeWidth: 0,
              textDecoration: "none",
            }}
          >
            <span style={{ lineHeight: "14.4px" }}>
              (g)<span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>(</span>
            <span
              className="s9"
              style={{ lineHeight: "14.4px", fontWeight: "bold" }}
            >
              document
            </span>
            <span style={{ lineHeight: "14.4px" }}>
              ) a reference to a document (including this agreement) is to that
              document as varied, novated, ratified or replaced from time to
              time;
            </span>
          </div>
          <div
            className="s16"
            style={{
              marginTop: 0,
              marginBottom: 0,
              textIndent: "-42px",
              marginLeft: 85,
              caretColor: "rgb(0, 0, 0)",
              color: "rgb(0, 0, 0)",
              fontFamily: "-webkit-standard",
              fontSize: 16,
              fontStyle: "normal",
              fontVariantCaps: "normal",
              fontWeight: 400,
              letterSpacing: "normal",
              orphans: "auto",
              textAlign: "start",
              textTransform: "none",
              whiteSpace: "normal",
              widows: "auto",
              wordSpacing: 0,
              WebkitTextStrokeWidth: 0,
              textDecoration: "none",
            }}
          >
            <span style={{ lineHeight: "14.4px" }}>
              (h)<span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>(</span>
            <span
              className="s9"
              style={{ lineHeight: "14.4px", fontWeight: "bold" }}
            >
              headings
            </span>
            <span style={{ lineHeight: "14.4px" }}>
              ) headings and words in bold type are for convenience only and do
              not affect interpretation;
            </span>
          </div>
          <div
            className="s16"
            style={{
              marginTop: 0,
              marginBottom: 0,
              textIndent: "-42px",
              marginLeft: 85,
              caretColor: "rgb(0, 0, 0)",
              color: "rgb(0, 0, 0)",
              fontFamily: "-webkit-standard",
              fontSize: 16,
              fontStyle: "normal",
              fontVariantCaps: "normal",
              fontWeight: 400,
              letterSpacing: "normal",
              orphans: "auto",
              textAlign: "start",
              textTransform: "none",
              whiteSpace: "normal",
              widows: "auto",
              wordSpacing: 0,
              WebkitTextStrokeWidth: 0,
              textDecoration: "none",
            }}
          >
            <span style={{ lineHeight: "14.4px" }}>
              (i)<span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>(</span>
            <span
              className="s9"
              style={{ lineHeight: "14.4px", fontWeight: "bold" }}
            >
              includes
            </span>
            <span style={{ lineHeight: "14.4px" }}>
              ) the word “includes” and similar words in any form is not a word
              of limitation; and
            </span>
          </div>
          <div
            className="s16"
            style={{
              marginTop: 0,
              marginBottom: 0,
              textIndent: "-42px",
              marginLeft: 85,
              caretColor: "rgb(0, 0, 0)",
              color: "rgb(0, 0, 0)",
              fontFamily: "-webkit-standard",
              fontSize: 16,
              fontStyle: "normal",
              fontVariantCaps: "normal",
              fontWeight: 400,
              letterSpacing: "normal",
              orphans: "auto",
              textAlign: "start",
              textTransform: "none",
              whiteSpace: "normal",
              widows: "auto",
              wordSpacing: 0,
              WebkitTextStrokeWidth: 0,
              textDecoration: "none",
            }}
          >
            <span style={{ lineHeight: "14.4px" }}>
              (j)<span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>(</span>
            <span
              className="s9"
              style={{ lineHeight: "14.4px", fontWeight: "bold" }}
            >
              adverse interpretation
            </span>
            <span style={{ lineHeight: "14.4px" }}>
              ) no provision of this agreement will be interpreted adversely to
              a party because that party was responsible for the preparation of
              this agreement or that provision.
            </span>
          </div>
          <p
            style={{
              marginTop: 0,
              marginBottom: 0,
              lineHeight: "14.4px",
              caretColor: "rgb(0, 0, 0)",
              color: "rgb(0, 0, 0)",
              fontFamily: "-webkit-standard",
              fontSize: 16,
              fontStyle: "normal",
              fontVariantCaps: "normal",
              fontWeight: 400,
              letterSpacing: "normal",
              orphans: "auto",
              textAlign: "start",
              textIndent: 0,
              textTransform: "none",
              whiteSpace: "normal",
              widows: "auto",
              wordSpacing: 0,
              WebkitTextStrokeWidth: 0,
              textDecoration: "none",
            }}
          >
            <span style={{ lineHeight: "14.4px" }}></span>
          </p>
          <div
            className="s13"
            style={{
              marginTop: 18,
              marginBottom: 0,
              textIndent: "-56px",
              marginLeft: 56,
              caretColor: "rgb(0, 0, 0)",
              color: "rgb(0, 0, 0)",
              fontFamily: "-webkit-standard",
              fontSize: 16,
              fontStyle: "normal",
              fontVariantCaps: "normal",
              fontWeight: 400,
              letterSpacing: "normal",
              orphans: "auto",
              textAlign: "start",
              textTransform: "none",
              whiteSpace: "normal",
              widows: "auto",
              wordSpacing: 0,
              WebkitTextStrokeWidth: 0,
              textDecoration: "none",
            }}
          >
            <span
              className="s9"
              style={{ lineHeight: "14.4px", fontWeight: "bold" }}
            >
              Part B<span className="Apple-converted-space">&nbsp;</span>
            </span>
            <a name="_heading=h.1ci93xb" />
            <a name="_Ref124842550" />
            <span
              className="s9"
              style={{ lineHeight: "14.4px", fontWeight: "bold" }}
            >
              Seller
            </span>
            <span
              className="s9"
              style={{ lineHeight: "14.4px", fontWeight: "bold" }}
            >
              s
            </span>
          </div>
          <div
            className="s15"
            style={{
              marginTop: 18,
              marginBottom: 0,
              textIndent: "-42px",
              marginLeft: 42,
              caretColor: "rgb(0, 0, 0)",
              color: "rgb(0, 0, 0)",
              fontFamily: "-webkit-standard",
              fontSize: 16,
              fontStyle: "normal",
              fontVariantCaps: "normal",
              fontWeight: 400,
              letterSpacing: "normal",
              orphans: "auto",
              textAlign: "start",
              textTransform: "none",
              whiteSpace: "normal",
              widows: "auto",
              wordSpacing: 0,
              WebkitTextStrokeWidth: 0,
              textDecoration: "none",
            }}
          >
            <span
              className="s14"
              style={{
                lineHeight: "14.4px",
                textTransform: "uppercase",
                fontWeight: "bold",
              }}
            >
              24<span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span
              className="s14"
              style={{
                lineHeight: "14.4px",
                textTransform: "uppercase",
                fontWeight: "bold",
              }}
            >
              ELIGIBILITY
            </span>
          </div>
          <div
            className="s12"
            style={{
              marginTop: 0,
              marginBottom: 0,
              textIndent: "-42px",
              marginLeft: 42,
              caretColor: "rgb(0, 0, 0)",
              color: "rgb(0, 0, 0)",
              fontFamily: "-webkit-standard",
              fontSize: 16,
              fontStyle: "normal",
              fontVariantCaps: "normal",
              fontWeight: 400,
              letterSpacing: "normal",
              orphans: "auto",
              textAlign: "start",
              textTransform: "none",
              whiteSpace: "normal",
              widows: "auto",
              wordSpacing: 0,
              WebkitTextStrokeWidth: 0,
              textDecoration: "none",
            }}
          >
            <span style={{ lineHeight: "14.4px" }}>
              You must verify that any
              <span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>Buyer</span>
            <span style={{ lineHeight: "14.4px" }}>
              <span className="Apple-converted-space">&nbsp;</span>is over 18
              years old or has their parent or guardian’s consent to use the
              Platform.
            </span>
          </div>
          <div
            className="s15"
            style={{
              marginTop: 18,
              marginBottom: 0,
              textIndent: "-42px",
              marginLeft: 42,
              caretColor: "rgb(0, 0, 0)",
              color: "rgb(0, 0, 0)",
              fontFamily: "-webkit-standard",
              fontSize: 16,
              fontStyle: "normal",
              fontVariantCaps: "normal",
              fontWeight: 400,
              letterSpacing: "normal",
              orphans: "auto",
              textAlign: "start",
              textTransform: "none",
              whiteSpace: "normal",
              widows: "auto",
              wordSpacing: 0,
              WebkitTextStrokeWidth: 0,
              textDecoration: "none",
            }}
          >
            <span
              className="s14"
              style={{
                lineHeight: "14.4px",
                textTransform: "uppercase",
                fontWeight: "bold",
              }}
            >
              25<span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span
              className="s14"
              style={{
                lineHeight: "14.4px",
                textTransform: "uppercase",
                fontWeight: "bold",
              }}
            >
              VEHICLE LISTING
            </span>
            <span
              className="s14"
              style={{
                lineHeight: "14.4px",
                textTransform: "uppercase",
                fontWeight: "bold",
              }}
            >
              S
            </span>
          </div>
          <div
            className="s20"
            style={{
              marginTop: 12,
              marginBottom: 0,
              textIndent: "-42px",
              marginLeft: 42,
              caretColor: "rgb(0, 0, 0)",
              color: "rgb(0, 0, 0)",
              fontFamily: "-webkit-standard",
              fontSize: 16,
              fontStyle: "normal",
              fontVariantCaps: "normal",
              fontWeight: 400,
              letterSpacing: "normal",
              orphans: "auto",
              textAlign: "start",
              textTransform: "none",
              whiteSpace: "normal",
              widows: "auto",
              wordSpacing: 0,
              WebkitTextStrokeWidth: 0,
              textDecoration: "none",
            }}
          >
            <span
              className="s19"
              style={{ lineHeight: "14.4px", textTransform: "uppercase" }}
            >
              25.1<span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span
              className="s19"
              style={{ lineHeight: "14.4px", textTransform: "uppercase" }}
            >
              LISTING CONTENT
            </span>
          </div>
          <p
            className="s18"
            style={{
              marginTop: 0,
              marginBottom: 0,
              lineHeight: "14.4px",
              marginLeft: 42,
              caretColor: "rgb(0, 0, 0)",
              color: "rgb(0, 0, 0)",
              fontFamily: "-webkit-standard",
              fontSize: 16,
              fontStyle: "normal",
              fontVariantCaps: "normal",
              fontWeight: 400,
              letterSpacing: "normal",
              orphans: "auto",
              textAlign: "start",
              textIndent: 0,
              textTransform: "none",
              whiteSpace: "normal",
              widows: "auto",
              wordSpacing: 0,
              WebkitTextStrokeWidth: 0,
              textDecoration: "none",
            }}
          >
            <span style={{ lineHeight: "14.4px" }}>
              You acknowledge and agree that:
            </span>
          </p>
          <div
            className="s16"
            style={{
              marginTop: 0,
              marginBottom: 0,
              textIndent: "-42px",
              marginLeft: 85,
              caretColor: "rgb(0, 0, 0)",
              color: "rgb(0, 0, 0)",
              fontFamily: "-webkit-standard",
              fontSize: 16,
              fontStyle: "normal",
              fontVariantCaps: "normal",
              fontWeight: 400,
              letterSpacing: "normal",
              orphans: "auto",
              textAlign: "start",
              textTransform: "none",
              whiteSpace: "normal",
              widows: "auto",
              wordSpacing: 0,
              WebkitTextStrokeWidth: 0,
              textDecoration: "none",
            }}
          >
            <span style={{ lineHeight: "14.4px" }}>
              (a)<span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>
              you must use your best endeavours to provide as much information
              as possible in any listing you submit to the Platform in which you
              offer to sell
              <span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>an Item</span>
            <span style={{ lineHeight: "14.4px" }}>
              <span className="Apple-converted-space">&nbsp;</span>(
            </span>
            <span
              className="s9"
              style={{ lineHeight: "14.4px", fontWeight: "bold" }}
            >
              Listing
            </span>
            <span style={{ lineHeight: "14.4px" }}>);</span>
          </div>
          <div
            className="s16"
            style={{
              marginTop: 0,
              marginBottom: 0,
              textIndent: "-42px",
              marginLeft: 85,
              caretColor: "rgb(0, 0, 0)",
              color: "rgb(0, 0, 0)",
              fontFamily: "-webkit-standard",
              fontSize: 16,
              fontStyle: "normal",
              fontVariantCaps: "normal",
              fontWeight: 400,
              letterSpacing: "normal",
              orphans: "auto",
              textAlign: "start",
              textTransform: "none",
              whiteSpace: "normal",
              widows: "auto",
              wordSpacing: 0,
              WebkitTextStrokeWidth: 0,
              textDecoration: "none",
            }}
          >
            <span style={{ lineHeight: "14.4px" }}>
              (b)<span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>e</span>
            <span style={{ lineHeight: "14.4px" }}>
              ach<span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>Listing</span>
            <span style={{ lineHeight: "14.4px" }}>
              <span className="Apple-converted-space">&nbsp;</span>must feature
              a fixed “buy it now” price (
            </span>
            <span
              className="s9"
              style={{ lineHeight: "14.4px", fontWeight: "bold" }}
            >
              Fixed
            </span>
            <span style={{ lineHeight: "14.4px" }}>
              <span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span
              className="s9"
              style={{ lineHeight: "14.4px", fontWeight: "bold" }}
            >
              Price
            </span>
            <span style={{ lineHeight: "14.4px" }}>
              ) and/or a reserve price to facilitate the auctioning process (
            </span>
            <span
              className="s9"
              style={{ lineHeight: "14.4px", fontWeight: "bold" }}
            >
              Reserve
            </span>
            <span style={{ lineHeight: "14.4px" }}>
              <span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span
              className="s9"
              style={{ lineHeight: "14.4px", fontWeight: "bold" }}
            >
              Price
            </span>
            <span style={{ lineHeight: "14.4px" }}>)</span>
            <span style={{ lineHeight: "14.4px" }}>;</span>
          </div>
          <div
            className="s16"
            style={{
              marginTop: 0,
              marginBottom: 0,
              textIndent: "-42px",
              marginLeft: 85,
              caretColor: "rgb(0, 0, 0)",
              color: "rgb(0, 0, 0)",
              fontFamily: "-webkit-standard",
              fontSize: 16,
              fontStyle: "normal",
              fontVariantCaps: "normal",
              fontWeight: 400,
              letterSpacing: "normal",
              orphans: "auto",
              textAlign: "start",
              textTransform: "none",
              whiteSpace: "normal",
              widows: "auto",
              wordSpacing: 0,
              WebkitTextStrokeWidth: 0,
              textDecoration: "none",
            }}
          >
            <span style={{ lineHeight: "14.4px" }}>
              (c)<span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>As Is Auctions</span>
            <span style={{ lineHeight: "14.4px" }}>
              <span className="Apple-converted-space">&nbsp;</span>may choose
              not to accept any
              <span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>Listing</span>
            <span style={{ lineHeight: "14.4px" }}>
              <span className="Apple-converted-space">&nbsp;</span>you submit to
              the Platform, and
              <span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>As Is Auctions</span>
            <span style={{ lineHeight: "14.4px" }}>
              <span className="Apple-converted-space">&nbsp;</span>may limit the
              number of
              <span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>Listing</span>
            <span style={{ lineHeight: "14.4px" }}>
              s you can submit to the Platform;
            </span>
          </div>
          <div
            className="s16"
            style={{
              marginTop: 0,
              marginBottom: 0,
              textIndent: "-42px",
              marginLeft: 85,
              caretColor: "rgb(0, 0, 0)",
              color: "rgb(0, 0, 0)",
              fontFamily: "-webkit-standard",
              fontSize: 16,
              fontStyle: "normal",
              fontVariantCaps: "normal",
              fontWeight: 400,
              letterSpacing: "normal",
              orphans: "auto",
              textAlign: "start",
              textTransform: "none",
              whiteSpace: "normal",
              widows: "auto",
              wordSpacing: 0,
              WebkitTextStrokeWidth: 0,
              textDecoration: "none",
            }}
          >
            <span style={{ lineHeight: "14.4px" }}>
              (d)<span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>
              any information you supply in a
              <span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>Listing</span>
            <span style={{ lineHeight: "14.4px" }}>
              s must be true, timely and accurate;
              <span className="Apple-converted-space">&nbsp;</span>
            </span>
          </div>
          <div
            className="s16"
            style={{
              marginTop: 0,
              marginBottom: 0,
              textIndent: "-42px",
              marginLeft: 85,
              caretColor: "rgb(0, 0, 0)",
              color: "rgb(0, 0, 0)",
              fontFamily: "-webkit-standard",
              fontSize: 16,
              fontStyle: "normal",
              fontVariantCaps: "normal",
              fontWeight: 400,
              letterSpacing: "normal",
              orphans: "auto",
              textAlign: "start",
              textTransform: "none",
              whiteSpace: "normal",
              widows: "auto",
              wordSpacing: 0,
              WebkitTextStrokeWidth: 0,
              textDecoration: "none",
            }}
          >
            <span style={{ lineHeight: "14.4px" }}>
              (e)<span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>
              by offering an
              <span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>Item</span>
            <span style={{ lineHeight: "14.4px" }}>
              <span className="Apple-converted-space">&nbsp;</span>for sale on
              the Platform, you are offering to enter into a legally binding
              contract with the
              <span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>Buyer</span>
            <span style={{ lineHeight: "14.4px" }}>;</span>
          </div>
          <div
            className="s16"
            style={{
              marginTop: 0,
              marginBottom: 0,
              textIndent: "-42px",
              marginLeft: 85,
              caretColor: "rgb(0, 0, 0)",
              color: "rgb(0, 0, 0)",
              fontFamily: "-webkit-standard",
              fontSize: 16,
              fontStyle: "normal",
              fontVariantCaps: "normal",
              fontWeight: 400,
              letterSpacing: "normal",
              orphans: "auto",
              textAlign: "start",
              textTransform: "none",
              whiteSpace: "normal",
              widows: "auto",
              wordSpacing: 0,
              WebkitTextStrokeWidth: 0,
              textDecoration: "none",
            }}
          >
            <span style={{ lineHeight: "14.4px" }}>
              (f)<span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>
              any additional terms and conditions relating to a
              <span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>Listing</span>
            <span style={{ lineHeight: "14.4px" }}>
              , or a quote for goods or services relating to the
              <span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>Item</span>
            <span style={{ lineHeight: "14.4px" }}>
              <span className="Apple-converted-space">&nbsp;</span>in the
              <span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>Listing</span>
            <span style={{ lineHeight: "14.4px" }}>
              , are solely between you and the relevant
              <span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>Buyer</span>
            <span style={{ lineHeight: "14.4px" }}>
              <span className="Apple-converted-space">&nbsp;</span>and do not
              involve As Is Auctions in any way, except that they must not be
              inconsistent with your or the
              <span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>Buyer</span>
            <span style={{ lineHeight: "14.4px" }}>
              ’s obligations under this agreement;
            </span>
          </div>
          <div
            className="s16"
            style={{
              marginTop: 0,
              marginBottom: 0,
              textIndent: "-42px",
              marginLeft: 85,
              caretColor: "rgb(0, 0, 0)",
              color: "rgb(0, 0, 0)",
              fontFamily: "-webkit-standard",
              fontSize: 16,
              fontStyle: "normal",
              fontVariantCaps: "normal",
              fontWeight: 400,
              letterSpacing: "normal",
              orphans: "auto",
              textAlign: "start",
              textTransform: "none",
              whiteSpace: "normal",
              widows: "auto",
              wordSpacing: 0,
              WebkitTextStrokeWidth: 0,
              textDecoration: "none",
            }}
          >
            <span style={{ lineHeight: "14.4px" }}>
              (g)<span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>
              you are solely responsible for any licences required in order for
              you to sell
              <span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>an Item</span>
            <span style={{ lineHeight: "14.4px" }}>
              , and solely responsible for complying with any Applicable Laws
              which apply to the sale of
              <span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>an Item</span>
            <span style={{ lineHeight: "14.4px" }}>
              <span className="Apple-converted-space">&nbsp;</span>and you
              represent and warrant that you have complied with these
              requirements when creating a
              <span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>Listing</span>
            <span style={{ lineHeight: "14.4px" }}>;</span>
            <span style={{ lineHeight: "14.4px" }}>
              <span className="Apple-converted-space">&nbsp;</span>and
            </span>
          </div>
          <div
            className="s16"
            style={{
              marginTop: 0,
              marginBottom: 0,
              textIndent: "-42px",
              marginLeft: 85,
              caretColor: "rgb(0, 0, 0)",
              color: "rgb(0, 0, 0)",
              fontFamily: "-webkit-standard",
              fontSize: 16,
              fontStyle: "normal",
              fontVariantCaps: "normal",
              fontWeight: 400,
              letterSpacing: "normal",
              orphans: "auto",
              textAlign: "start",
              textTransform: "none",
              whiteSpace: "normal",
              widows: "auto",
              wordSpacing: 0,
              WebkitTextStrokeWidth: 0,
              textDecoration: "none",
            }}
          >
            <span style={{ lineHeight: "14.4px" }}>
              (h)<span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>
              As Is Auctions reserve the continuing right to reject, revise, or
              discontinue any
              <span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>Listing</span>
            <span style={{ lineHeight: "14.4px" }}>
              s, at any time and for any reason in our sole discretion, and to
              terminate the
              <span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>Listing</span>
            <span style={{ lineHeight: "14.4px" }}>
              <span className="Apple-converted-space">&nbsp;</span>and to remove
              all references to the
              <span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>Listing</span>
            <span style={{ lineHeight: "14.4px" }}>
              <span className="Apple-converted-space">&nbsp;</span>from the
              Platform, and redirect or delete any URL used in connection with
              the
              <span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>Item</span>
            <span style={{ lineHeight: "14.4px" }}>
              .<span className="Apple-converted-space">&nbsp;</span>
            </span>
          </div>
          <div
            className="s20"
            style={{
              marginTop: 12,
              marginBottom: 0,
              textIndent: "-42px",
              marginLeft: 42,
              caretColor: "rgb(0, 0, 0)",
              color: "rgb(0, 0, 0)",
              fontFamily: "-webkit-standard",
              fontSize: 16,
              fontStyle: "normal",
              fontVariantCaps: "normal",
              fontWeight: 400,
              letterSpacing: "normal",
              orphans: "auto",
              textAlign: "start",
              textTransform: "none",
              whiteSpace: "normal",
              widows: "auto",
              wordSpacing: 0,
              WebkitTextStrokeWidth: 0,
              textDecoration: "none",
            }}
          >
            <span
              className="s19"
              style={{ lineHeight: "14.4px", textTransform: "uppercase" }}
            >
              25.2<span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span
              className="s19"
              style={{ lineHeight: "14.4px", textTransform: "uppercase" }}
            >
              SALE PROCESS
            </span>
          </div>
          <div
            className="s16"
            style={{
              marginTop: 0,
              marginBottom: 0,
              textIndent: "-42px",
              marginLeft: 85,
              caretColor: "rgb(0, 0, 0)",
              color: "rgb(0, 0, 0)",
              fontFamily: "-webkit-standard",
              fontSize: 16,
              fontStyle: "normal",
              fontVariantCaps: "normal",
              fontWeight: 400,
              letterSpacing: "normal",
              orphans: "auto",
              textAlign: "start",
              textTransform: "none",
              whiteSpace: "normal",
              widows: "auto",
              wordSpacing: 0,
              WebkitTextStrokeWidth: 0,
              textDecoration: "none",
            }}
          >
            <span style={{ lineHeight: "14.4px" }}>
              (a)<span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>
              You acknowledge and agree that:
            </span>
          </div>
          <div
            className="s17"
            style={{
              marginTop: 0,
              marginBottom: 0,
              textIndent: "-42px",
              marginLeft: 127,
              caretColor: "rgb(0, 0, 0)",
              color: "rgb(0, 0, 0)",
              fontFamily: "-webkit-standard",
              fontSize: 16,
              fontStyle: "normal",
              fontVariantCaps: "normal",
              fontWeight: 400,
              letterSpacing: "normal",
              orphans: "auto",
              textAlign: "start",
              textTransform: "none",
              whiteSpace: "normal",
              widows: "auto",
              wordSpacing: 0,
              WebkitTextStrokeWidth: 0,
              textDecoration: "none",
            }}
          >
            <span style={{ lineHeight: "14.4px" }}>
              (i)<span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>f</span>
            <span style={{ lineHeight: "14.4px" }}>
              or Fixed Price
              <span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>Listing</span>
            <span style={{ lineHeight: "14.4px" }}>
              s, once a<span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>Buyer</span>
            <span style={{ lineHeight: "14.4px" }}>
              <span className="Apple-converted-space">&nbsp;</span>places an
              order for
              <span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>an Item</span>
            <span style={{ lineHeight: "14.4px" }}>
              , you acknowledge and agree that you enter into a legally binding
              contract to sell the
              <span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>Item</span>
            <span style={{ lineHeight: "14.4px" }}>
              <span className="Apple-converted-space">&nbsp;</span>to the
              <span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>Buyer</span>
            <span style={{ lineHeight: "14.4px" }}>
              <span className="Apple-converted-space">&nbsp;</span>for the Fixed
              Price
            </span>
            <span style={{ lineHeight: "14.4px" }}>;</span>
            <span style={{ lineHeight: "14.4px" }}>
              <span className="Apple-converted-space">&nbsp;</span>or
            </span>
          </div>
          <div
            className="s17"
            style={{
              marginTop: 0,
              marginBottom: 0,
              textIndent: "-42px",
              marginLeft: 127,
              caretColor: "rgb(0, 0, 0)",
              color: "rgb(0, 0, 0)",
              fontFamily: "-webkit-standard",
              fontSize: 16,
              fontStyle: "normal",
              fontVariantCaps: "normal",
              fontWeight: 400,
              letterSpacing: "normal",
              orphans: "auto",
              textAlign: "start",
              textTransform: "none",
              whiteSpace: "normal",
              widows: "auto",
              wordSpacing: 0,
              WebkitTextStrokeWidth: 0,
              textDecoration: "none",
            }}
          >
            <span style={{ lineHeight: "14.4px" }}>
              (ii)<span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>f</span>
            <span style={{ lineHeight: "14.4px" }}>
              or Reserve Price
              <span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>Listing</span>
            <span style={{ lineHeight: "14.4px" }}>
              s, once an auction ends, and a
              <span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>Buyer</span>
            <span style={{ lineHeight: "14.4px" }}>
              <span className="Apple-converted-space">&nbsp;</span>has placed
              the winning<span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>bid</span>
            <span style={{ lineHeight: "14.4px" }}>
              <span className="Apple-converted-space">&nbsp;</span>to buy the
              <span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>Item</span>
            <span style={{ lineHeight: "14.4px" }}>
              <span className="Apple-converted-space">&nbsp;</span>for a price
              that exceeds the reserve price (or where no reserve price is
              specified, then the highest placed
              <span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>bid</span>
            <span style={{ lineHeight: "14.4px" }}>
              ), you acknowledge and agree that you enter into a legally binding
              contract to sell the
              <span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>Item</span>
            <span style={{ lineHeight: "14.4px" }}>
              <span className="Apple-converted-space">&nbsp;</span>to the
              <span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>Buyer</span>
            <span style={{ lineHeight: "14.4px" }}>
              <span className="Apple-converted-space">&nbsp;</span>at the
              winning
              <span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>bid</span>
            <span style={{ lineHeight: "14.4px" }}>
              <span className="Apple-converted-space">&nbsp;</span>Price
            </span>
            <span style={{ lineHeight: "14.4px" }}>
              <span className="Apple-converted-space">&nbsp;</span>(
            </span>
            <span
              className="s9"
              style={{ lineHeight: "14.4px", fontWeight: "bold" }}
            >
              Winning Bid Price
            </span>
            <span style={{ lineHeight: "14.4px" }}>)</span>
            <span style={{ lineHeight: "14.4px" }}>;</span>
          </div>
          <div
            className="s17"
            style={{
              marginTop: 0,
              marginBottom: 0,
              textIndent: "-42px",
              marginLeft: 127,
              caretColor: "rgb(0, 0, 0)",
              color: "rgb(0, 0, 0)",
              fontFamily: "-webkit-standard",
              fontSize: 16,
              fontStyle: "normal",
              fontVariantCaps: "normal",
              fontWeight: 400,
              letterSpacing: "normal",
              orphans: "auto",
              textAlign: "start",
              textTransform: "none",
              whiteSpace: "normal",
              widows: "auto",
              wordSpacing: 0,
              WebkitTextStrokeWidth: 0,
              textDecoration: "none",
            }}
          >
            <span style={{ lineHeight: "14.4px" }}>
              both<span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>deemed an order (</span>
            <span
              className="s9"
              style={{ lineHeight: "14.4px", fontWeight: "bold" }}
            >
              Agreement to Purchase
            </span>
            <span style={{ lineHeight: "14.4px" }}>).</span>
          </div>
          <div
            className="s16"
            style={{
              marginTop: 0,
              marginBottom: 0,
              textIndent: "-42px",
              marginLeft: 85,
              caretColor: "rgb(0, 0, 0)",
              color: "rgb(0, 0, 0)",
              fontFamily: "-webkit-standard",
              fontSize: 16,
              fontStyle: "normal",
              fontVariantCaps: "normal",
              fontWeight: 400,
              letterSpacing: "normal",
              orphans: "auto",
              textAlign: "start",
              textTransform: "none",
              whiteSpace: "normal",
              widows: "auto",
              wordSpacing: 0,
              WebkitTextStrokeWidth: 0,
              textDecoration: "none",
            }}
          >
            <span style={{ lineHeight: "14.4px" }}>
              (b)<span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>
              If any reserve price is not met at the end of an auction, and the
              <span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>Item</span>
            <span style={{ lineHeight: "14.4px" }}>
              <span className="Apple-converted-space">&nbsp;</span>remains
              unsold, you will have the opportunity to accept or reject the
              final highest
              <span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>bid</span>
            <span style={{ lineHeight: "14.4px" }}>
              . If you decide not to take the highest final
              <span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>bid</span>
            <span style={{ lineHeight: "14.4px" }}>
              , the<span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>Listing</span>
            <span style={{ lineHeight: "14.4px" }}>
              <span className="Apple-converted-space">&nbsp;</span>may stay for
              a further
              <span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>7 days</span>
            <span style={{ lineHeight: "14.4px" }}>
              <span className="Apple-converted-space">&nbsp;</span>at a Fixed
              Price.
            </span>
          </div>
          <div
            className="s16"
            style={{
              marginTop: 0,
              marginBottom: 0,
              textIndent: "-42px",
              marginLeft: 85,
              caretColor: "rgb(0, 0, 0)",
              color: "rgb(0, 0, 0)",
              fontFamily: "-webkit-standard",
              fontSize: 16,
              fontStyle: "normal",
              fontVariantCaps: "normal",
              fontWeight: 400,
              letterSpacing: "normal",
              orphans: "auto",
              textAlign: "start",
              textTransform: "none",
              whiteSpace: "normal",
              widows: "auto",
              wordSpacing: 0,
              WebkitTextStrokeWidth: 0,
              textDecoration: "none",
            }}
          >
            <span style={{ lineHeight: "14.4px" }}>
              (c)<span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>
              Sellers are strictly prohibited from
              <span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>bid</span>
            <span style={{ lineHeight: "14.4px" }}>
              ding on their own
              <span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>Listing</span>
            <span style={{ lineHeight: "14.4px" }}>
              <span className="Apple-converted-space">&nbsp;</span>or getting
              others to<span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>bid</span>
            <span style={{ lineHeight: "14.4px" }}>
              for them. We monitor all our auction
              <span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>Listing</span>
            <span style={{ lineHeight: "14.4px" }}>
              s and users to ensure this doesn’t happen.
            </span>
          </div>
          <div
            className="s16"
            style={{
              marginTop: 0,
              marginBottom: 0,
              textIndent: "-42px",
              marginLeft: 85,
              caretColor: "rgb(0, 0, 0)",
              color: "rgb(0, 0, 0)",
              fontFamily: "-webkit-standard",
              fontSize: 16,
              fontStyle: "normal",
              fontVariantCaps: "normal",
              fontWeight: 400,
              letterSpacing: "normal",
              orphans: "auto",
              textAlign: "start",
              textTransform: "none",
              whiteSpace: "normal",
              widows: "auto",
              wordSpacing: 0,
              WebkitTextStrokeWidth: 0,
              textDecoration: "none",
            }}
          >
            <span style={{ lineHeight: "14.4px" }}>
              (d)<span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>
              you must ensure that you sell the
              <span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>Item</span>
            <span style={{ lineHeight: "14.4px" }}>
              <span className="Apple-converted-space">&nbsp;</span>to the
              <span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>Buyer</span>
            <span style={{ lineHeight: "14.4px" }}>
              <span className="Apple-converted-space">&nbsp;</span>free from all
              Security Interests.
              <span className="Apple-converted-space">&nbsp;</span>
            </span>
          </div>
          <div
            className="s16"
            style={{
              marginTop: 0,
              marginBottom: 0,
              textIndent: "-42px",
              marginLeft: 85,
              caretColor: "rgb(0, 0, 0)",
              color: "rgb(0, 0, 0)",
              fontFamily: "-webkit-standard",
              fontSize: 16,
              fontStyle: "normal",
              fontVariantCaps: "normal",
              fontWeight: 400,
              letterSpacing: "normal",
              orphans: "auto",
              textAlign: "start",
              textTransform: "none",
              whiteSpace: "normal",
              widows: "auto",
              wordSpacing: 0,
              WebkitTextStrokeWidth: 0,
              textDecoration: "none",
            }}
          >
            <span style={{ lineHeight: "14.4px" }}>
              (e)<span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>
              you must take all reasonable steps to complete the sale of the
              <span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>Item</span>
            <span style={{ lineHeight: "14.4px" }}>
              <span className="Apple-converted-space">&nbsp;</span>described in
              a<span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>Listing</span>
            <span style={{ lineHeight: "14.4px" }}>
              <span className="Apple-converted-space">&nbsp;</span>that has
              concluded and been won by a
              <span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>Buyer</span>
            <span style={{ lineHeight: "14.4px" }}>;</span>
          </div>
          <div
            className="s16"
            style={{
              marginTop: 0,
              marginBottom: 0,
              textIndent: "-42px",
              marginLeft: 85,
              caretColor: "rgb(0, 0, 0)",
              color: "rgb(0, 0, 0)",
              fontFamily: "-webkit-standard",
              fontSize: 16,
              fontStyle: "normal",
              fontVariantCaps: "normal",
              fontWeight: 400,
              letterSpacing: "normal",
              orphans: "auto",
              textAlign: "start",
              textTransform: "none",
              whiteSpace: "normal",
              widows: "auto",
              wordSpacing: 0,
              WebkitTextStrokeWidth: 0,
              textDecoration: "none",
            }}
          >
            <span style={{ lineHeight: "14.4px" }}>
              (f)<span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>
              As Is Auctions will have no responsibility for the accuracy,
              reliability or timeliness of any
              <span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>Buyer</span>
            <span style={{ lineHeight: "14.4px" }}>
              ’s response to a
              <span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>Listing;</span>
          </div>
          <div
            className="s16"
            style={{
              marginTop: 0,
              marginBottom: 0,
              textIndent: "-42px",
              marginLeft: 85,
              caretColor: "rgb(0, 0, 0)",
              color: "rgb(0, 0, 0)",
              fontFamily: "-webkit-standard",
              fontSize: 16,
              fontStyle: "normal",
              fontVariantCaps: "normal",
              fontWeight: 400,
              letterSpacing: "normal",
              orphans: "auto",
              textAlign: "start",
              textTransform: "none",
              whiteSpace: "normal",
              widows: "auto",
              wordSpacing: 0,
              WebkitTextStrokeWidth: 0,
              textDecoration: "none",
            }}
          >
            <span style={{ lineHeight: "14.4px" }}>
              (g)<span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>
              you must deal with any dispute with a
              <span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>Buyer</span>
            <span style={{ lineHeight: "14.4px" }}>
              <span className="Apple-converted-space">&nbsp;</span>in accordance
              with clause of Part A;
            </span>
          </div>
          <div
            className="s15"
            style={{
              marginTop: 18,
              marginBottom: 0,
              textIndent: "-42px",
              marginLeft: 42,
              caretColor: "rgb(0, 0, 0)",
              color: "rgb(0, 0, 0)",
              fontFamily: "-webkit-standard",
              fontSize: 16,
              fontStyle: "normal",
              fontVariantCaps: "normal",
              fontWeight: 400,
              letterSpacing: "normal",
              orphans: "auto",
              textAlign: "start",
              textTransform: "none",
              whiteSpace: "normal",
              widows: "auto",
              wordSpacing: 0,
              WebkitTextStrokeWidth: 0,
              textDecoration: "none",
            }}
          >
            <span
              className="s14"
              style={{
                lineHeight: "14.4px",
                textTransform: "uppercase",
                fontWeight: "bold",
              }}
            >
              26<span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span
              className="s14"
              style={{
                lineHeight: "14.4px",
                textTransform: "uppercase",
                fontWeight: "bold",
              }}
            >
              FEES
            </span>
          </div>
          <div
            className="s16"
            style={{
              marginTop: 0,
              marginBottom: 0,
              textIndent: "-42px",
              marginLeft: 85,
              caretColor: "rgb(0, 0, 0)",
              color: "rgb(0, 0, 0)",
              fontFamily: "-webkit-standard",
              fontSize: 16,
              fontStyle: "normal",
              fontVariantCaps: "normal",
              fontWeight: 400,
              letterSpacing: "normal",
              orphans: "auto",
              textAlign: "start",
              textTransform: "none",
              whiteSpace: "normal",
              widows: "auto",
              wordSpacing: 0,
              WebkitTextStrokeWidth: 0,
              textDecoration: "none",
            }}
          >
            <span style={{ lineHeight: "14.4px" }}>
              (a)<span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>
              Viewing the Platform and posting a
              <span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>Listing</span>
            <span style={{ lineHeight: "14.4px" }}>
              <span className="Apple-converted-space">&nbsp;</span>is free.
            </span>
          </div>
          <div
            className="s16"
            style={{
              marginTop: 0,
              marginBottom: 0,
              textIndent: "-42px",
              marginLeft: 85,
              caretColor: "rgb(0, 0, 0)",
              color: "rgb(0, 0, 0)",
              fontFamily: "-webkit-standard",
              fontSize: 16,
              fontStyle: "normal",
              fontVariantCaps: "normal",
              fontWeight: 400,
              letterSpacing: "normal",
              orphans: "auto",
              textAlign: "start",
              textTransform: "none",
              whiteSpace: "normal",
              widows: "auto",
              wordSpacing: 0,
              WebkitTextStrokeWidth: 0,
              textDecoration: "none",
            }}
          >
            <span style={{ lineHeight: "14.4px" }}>
              (b)<span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>
              Our Platform does not provide payment
              <span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>
              facilities. A<span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>Buyer</span>
            <span style={{ lineHeight: "14.4px" }}>
              <span className="Apple-converted-space">&nbsp;</span>must pay a
              Seller directly for
              <span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>an Item</span>
            <span style={{ lineHeight: "14.4px" }}>.</span>
          </div>
          <div
            className="s16"
            style={{
              marginTop: 0,
              marginBottom: 0,
              textIndent: "-42px",
              marginLeft: 85,
              caretColor: "rgb(0, 0, 0)",
              color: "rgb(0, 0, 0)",
              fontFamily: "-webkit-standard",
              fontSize: 16,
              fontStyle: "normal",
              fontVariantCaps: "normal",
              fontWeight: 400,
              letterSpacing: "normal",
              orphans: "auto",
              textAlign: "start",
              textTransform: "none",
              whiteSpace: "normal",
              widows: "auto",
              wordSpacing: 0,
              WebkitTextStrokeWidth: 0,
              textDecoration: "none",
            }}
          >
            <span style={{ lineHeight: "14.4px" }}>
              (c)<span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>
              In consideration for us providing the Services to you and enabling
              you to create
              <span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>Listing</span>
            <span style={{ lineHeight: "14.4px" }}>
              s, you agree to pay to us a Service Fee equal to 10% of the Fixed
              Price<span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>
              or<span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>
              Winning Bid Price, depending on the nature of the
              <span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>Buyer</span>
            <span style={{ lineHeight: "14.4px" }}>’s purchase from you (</span>
            <span
              className="s9"
              style={{ lineHeight: "14.4px", fontWeight: "bold" }}
            >
              Service Fee
            </span>
            <span style={{ lineHeight: "14.4px" }}>).</span>
          </div>
          <div
            className="s16"
            style={{
              marginTop: 0,
              marginBottom: 0,
              textIndent: "-42px",
              marginLeft: 85,
              caretColor: "rgb(0, 0, 0)",
              color: "rgb(0, 0, 0)",
              fontFamily: "-webkit-standard",
              fontSize: 16,
              fontStyle: "normal",
              fontVariantCaps: "normal",
              fontWeight: 400,
              letterSpacing: "normal",
              orphans: "auto",
              textAlign: "start",
              textTransform: "none",
              whiteSpace: "normal",
              widows: "auto",
              wordSpacing: 0,
              WebkitTextStrokeWidth: 0,
              textDecoration: "none",
            }}
          >
            <span style={{ lineHeight: "14.4px" }}>
              (d)<span className="Apple-converted-space">&nbsp;</span>
            </span>
            <a name="_heading=h.3whwml4" />
            <span style={{ lineHeight: "14.4px" }}>As Is Auctions</span>
            <span style={{ lineHeight: "14.4px" }}>
              <span className="Apple-converted-space">&nbsp;</span>reserves the
              right to change or waive the Service Fee at any time by updating
              this agreement, on written notice to you. We will provide you with
              at least 14 days’ written notice if this occurs, and upon receipt
              of such notice you will have the right to terminate this agreement
              immediately, on written notice to us. Your continued use of the
              Services after you receive such written notice will constitute
              your consent to the change and/or waiver set out in that notice.
              <span className="Apple-converted-space">&nbsp;</span>
            </span>
          </div>
          <div
            className="s16"
            style={{
              marginTop: 0,
              marginBottom: 0,
              textIndent: "-42px",
              marginLeft: 85,
              caretColor: "rgb(0, 0, 0)",
              color: "rgb(0, 0, 0)",
              fontFamily: "-webkit-standard",
              fontSize: 16,
              fontStyle: "normal",
              fontVariantCaps: "normal",
              fontWeight: 400,
              letterSpacing: "normal",
              orphans: "auto",
              textAlign: "start",
              textTransform: "none",
              whiteSpace: "normal",
              widows: "auto",
              wordSpacing: 0,
              WebkitTextStrokeWidth: 0,
              textDecoration: "none",
            }}
          >
            <span style={{ lineHeight: "14.4px" }}>
              (e)<span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>
              You acknowledge and agree that, unless applicable laws or
              regulations require otherwise, taxes (including GST) will be
              calculated and charged on the
              <span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>
              Fixed Price/Winning Bid Price
            </span>
            <span style={{ lineHeight: "14.4px" }}>
              <span className="Apple-converted-space">&nbsp;</span>and we will
              calculate the Service Fee on an amount equal to
              <span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>the Fixed</span>
            <span style={{ lineHeight: "14.4px" }}>
              <span className="Apple-converted-space">&nbsp;</span>
              Price/Winning Bid Price
              <span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>
              plus any taxes applicable to the
              <span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>
              Fixed Price/Winning Bid Price
            </span>
            <span style={{ lineHeight: "14.4px" }}>
              .<span className="Apple-converted-space">&nbsp;</span>
            </span>
          </div>
          <div
            className="s15"
            style={{
              marginTop: 18,
              marginBottom: 0,
              textIndent: "-42px",
              marginLeft: 42,
              caretColor: "rgb(0, 0, 0)",
              color: "rgb(0, 0, 0)",
              fontFamily: "-webkit-standard",
              fontSize: 16,
              fontStyle: "normal",
              fontVariantCaps: "normal",
              fontWeight: 400,
              letterSpacing: "normal",
              orphans: "auto",
              textAlign: "start",
              textTransform: "none",
              whiteSpace: "normal",
              widows: "auto",
              wordSpacing: 0,
              WebkitTextStrokeWidth: 0,
              textDecoration: "none",
            }}
          >
            <span
              className="s14"
              style={{
                lineHeight: "14.4px",
                textTransform: "uppercase",
                fontWeight: "bold",
              }}
            >
              27<span className="Apple-converted-space">&nbsp;</span>
            </span>
            <a name="_heading=h.3as4poj" />
            <span
              className="s14"
              style={{
                lineHeight: "14.4px",
                textTransform: "uppercase",
                fontWeight: "bold",
              }}
            >
              REFUNDS &amp; CANCELLATIONS
            </span>
          </div>
          <div
            className="s16"
            style={{
              marginTop: 0,
              marginBottom: 0,
              textIndent: "-42px",
              marginLeft: 85,
              caretColor: "rgb(0, 0, 0)",
              color: "rgb(0, 0, 0)",
              fontFamily: "-webkit-standard",
              fontSize: 16,
              fontStyle: "normal",
              fontVariantCaps: "normal",
              fontWeight: 400,
              letterSpacing: "normal",
              orphans: "auto",
              textAlign: "start",
              textTransform: "none",
              whiteSpace: "normal",
              widows: "auto",
              wordSpacing: 0,
              WebkitTextStrokeWidth: 0,
              textDecoration: "none",
            }}
          >
            <span style={{ lineHeight: "14.4px" }}>
              (a)<span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>
              We have no liability or obligation to you if a Buyer cancels an
              order for<span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>an Item</span>
            <span style={{ lineHeight: "14.4px" }}>
              <span className="Apple-converted-space">&nbsp;</span>or if they
              fail to pay for
              <span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>an Item</span>
            <span style={{ lineHeight: "14.4px" }}>
              .<span className="Apple-converted-space">&nbsp;</span>
            </span>
          </div>
          <div
            className="s16"
            style={{
              marginTop: 0,
              marginBottom: 0,
              textIndent: "-42px",
              marginLeft: 85,
              caretColor: "rgb(0, 0, 0)",
              color: "rgb(0, 0, 0)",
              fontFamily: "-webkit-standard",
              fontSize: 16,
              fontStyle: "normal",
              fontVariantCaps: "normal",
              fontWeight: 400,
              letterSpacing: "normal",
              orphans: "auto",
              textAlign: "start",
              textTransform: "none",
              whiteSpace: "normal",
              widows: "auto",
              wordSpacing: 0,
              WebkitTextStrokeWidth: 0,
              textDecoration: "none",
            }}
          >
            <span style={{ lineHeight: "14.4px" }}>
              (b)<span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>
              In the event a transaction does not proceed due to your default
              and we are required by law to refund
              <span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>
              the Buyer’s Fee or
              <span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>
              any<span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>
              other<span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>
              amount to the Buyer (or we otherwise agree to), then you
              <span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>
              will be responsible for covering the such amount.
              <span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>
              You acknowledge and agree that such amount is not a penalty, but a
              genuine estimate of
              <span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>
              Loss we will have suffered
            </span>
            <span style={{ lineHeight: "14.4px" }}>
              <span className="Apple-converted-space">&nbsp;</span>as a result
              of your breach.
            </span>
          </div>
          <div
            className="s15"
            style={{
              marginTop: 18,
              marginBottom: 0,
              textIndent: "-42px",
              marginLeft: 42,
              caretColor: "rgb(0, 0, 0)",
              color: "rgb(0, 0, 0)",
              fontFamily: "-webkit-standard",
              fontSize: 16,
              fontStyle: "normal",
              fontVariantCaps: "normal",
              fontWeight: 400,
              letterSpacing: "normal",
              orphans: "auto",
              textAlign: "start",
              textTransform: "none",
              whiteSpace: "normal",
              widows: "auto",
              wordSpacing: 0,
              WebkitTextStrokeWidth: 0,
              textDecoration: "none",
            }}
          >
            <span
              className="s14"
              style={{
                lineHeight: "14.4px",
                textTransform: "uppercase",
                fontWeight: "bold",
              }}
            >
              28<span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span
              className="s14"
              style={{
                lineHeight: "14.4px",
                textTransform: "uppercase",
                fontWeight: "bold",
              }}
            >
              ARRANGEMENTS AND COMMUNICATIONS WITH OTHER USERS
            </span>
          </div>
          <div
            className="s16"
            style={{
              marginTop: 0,
              marginBottom: 0,
              textIndent: "-42px",
              marginLeft: 85,
              caretColor: "rgb(0, 0, 0)",
              color: "rgb(0, 0, 0)",
              fontFamily: "-webkit-standard",
              fontSize: 16,
              fontStyle: "normal",
              fontVariantCaps: "normal",
              fontWeight: 400,
              letterSpacing: "normal",
              orphans: "auto",
              textAlign: "start",
              textTransform: "none",
              whiteSpace: "normal",
              widows: "auto",
              wordSpacing: 0,
              WebkitTextStrokeWidth: 0,
              textDecoration: "none",
            }}
          >
            <span style={{ lineHeight: "14.4px" }}>
              (a)<span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>
              Once an Agreement to Purchase is made and As Is Auctions has
              received the Service Fee from the Seller,
              <span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>As Is Auctions</span>
            <span style={{ lineHeight: "14.4px" }}>
              <span className="Apple-converted-space">&nbsp;</span>will provide
              you with the Buyer’s contact information.
              <span className="Apple-converted-space">&nbsp;</span>
            </span>
          </div>
          <div
            className="s16"
            style={{
              marginTop: 0,
              marginBottom: 0,
              textIndent: "-42px",
              marginLeft: 85,
              caretColor: "rgb(0, 0, 0)",
              color: "rgb(0, 0, 0)",
              fontFamily: "-webkit-standard",
              fontSize: 16,
              fontStyle: "normal",
              fontVariantCaps: "normal",
              fontWeight: 400,
              letterSpacing: "normal",
              orphans: "auto",
              textAlign: "start",
              textTransform: "none",
              whiteSpace: "normal",
              widows: "auto",
              wordSpacing: 0,
              WebkitTextStrokeWidth: 0,
              textDecoration: "none",
            }}
          >
            <span style={{ lineHeight: "14.4px" }}>
              (b)<span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>
              You must work together with the Buyer to complete the transaction
              within
              <span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>
              a reasonable period of time as agreed between you and the Buyer
            </span>
            <span style={{ lineHeight: "14.4px" }}>
              , arranging any shipping (at the Buyer’s expense) if necessary.
              Sellers are strictly prohibited from increasing the
              <span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>
              purchase price for the Item
            </span>
            <span style={{ lineHeight: "14.4px" }}>
              <span className="Apple-converted-space">&nbsp;</span>post
              <span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>Auction</span>
            <span style={{ lineHeight: "14.4px" }}>.</span>
          </div>
          <div
            className="s16"
            style={{
              marginTop: 0,
              marginBottom: 0,
              textIndent: "-42px",
              marginLeft: 85,
              caretColor: "rgb(0, 0, 0)",
              color: "rgb(0, 0, 0)",
              fontFamily: "-webkit-standard",
              fontSize: 16,
              fontStyle: "normal",
              fontVariantCaps: "normal",
              fontWeight: 400,
              letterSpacing: "normal",
              orphans: "auto",
              textAlign: "start",
              textTransform: "none",
              whiteSpace: "normal",
              widows: "auto",
              wordSpacing: 0,
              WebkitTextStrokeWidth: 0,
              textDecoration: "none",
            }}
          >
            <span style={{ lineHeight: "14.4px" }}>
              (c)<span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>
              You acknowledge that any arrangement or communication entered into
              with another User is solely at your own risk and you are solely
              responsible for the supply of any
              <span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>Item</span>
            <span style={{ lineHeight: "14.4px" }}>
              . We are not a party to any arrangement entered into. We have no
              control, influence or involvement in such arrangements. Our
              responsibilities are limited to facilitating the availability of
              the Services and acting as your agent
            </span>
            <span style={{ lineHeight: "14.4px" }}>
              <span className="Apple-converted-space">&nbsp;</span>in posting a
              Listing.<span className="Apple-converted-space">&nbsp;</span>
            </span>
          </div>
          <div
            className="s16"
            style={{
              marginTop: 0,
              marginBottom: 0,
              textIndent: "-42px",
              marginLeft: 85,
              caretColor: "rgb(0, 0, 0)",
              color: "rgb(0, 0, 0)",
              fontFamily: "-webkit-standard",
              fontSize: 16,
              fontStyle: "normal",
              fontVariantCaps: "normal",
              fontWeight: 400,
              letterSpacing: "normal",
              orphans: "auto",
              textAlign: "start",
              textTransform: "none",
              whiteSpace: "normal",
              widows: "auto",
              wordSpacing: 0,
              WebkitTextStrokeWidth: 0,
              textDecoration: "none",
            }}
          >
            <span style={{ lineHeight: "14.4px" }}>
              (d)<span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>
              We do not guarantee the completeness, accuracy, currency or
              reliability of any information provided by Buyers. You must make
              your own investigation in the accuracy of the information.
            </span>
          </div>
          <div
            className="s15"
            style={{
              marginTop: 18,
              marginBottom: 0,
              textIndent: "-42px",
              marginLeft: 42,
              caretColor: "rgb(0, 0, 0)",
              color: "rgb(0, 0, 0)",
              fontFamily: "-webkit-standard",
              fontSize: 16,
              fontStyle: "normal",
              fontVariantCaps: "normal",
              fontWeight: 400,
              letterSpacing: "normal",
              orphans: "auto",
              textAlign: "start",
              textTransform: "none",
              whiteSpace: "normal",
              widows: "auto",
              wordSpacing: 0,
              WebkitTextStrokeWidth: 0,
              textDecoration: "none",
            }}
          >
            <span
              className="s14"
              style={{
                lineHeight: "14.4px",
                textTransform: "uppercase",
                fontWeight: "bold",
              }}
            >
              29<span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span
              className="s14"
              style={{
                lineHeight: "14.4px",
                textTransform: "uppercase",
                fontWeight: "bold",
              }}
            >
              LEGAL RIGHTS OF SELLERS
            </span>
          </div>
          <div
            className="s16"
            style={{
              marginTop: 0,
              marginBottom: 0,
              textIndent: "-42px",
              marginLeft: 85,
              caretColor: "rgb(0, 0, 0)",
              color: "rgb(0, 0, 0)",
              fontFamily: "-webkit-standard",
              fontSize: 16,
              fontStyle: "normal",
              fontVariantCaps: "normal",
              fontWeight: 400,
              letterSpacing: "normal",
              orphans: "auto",
              textAlign: "start",
              textTransform: "none",
              whiteSpace: "normal",
              widows: "auto",
              wordSpacing: 0,
              WebkitTextStrokeWidth: 0,
              textDecoration: "none",
            }}
          >
            <span style={{ lineHeight: "14.4px" }}>
              Your legal rights in connection with the supply of the Items are
              against the Buyer and not us.
              <span className="Apple-converted-space">&nbsp;</span>
            </span>
          </div>
          <div
            className="s15"
            style={{
              marginTop: 18,
              marginBottom: 0,
              textIndent: "-42px",
              marginLeft: 42,
              caretColor: "rgb(0, 0, 0)",
              color: "rgb(0, 0, 0)",
              fontFamily: "-webkit-standard",
              fontSize: 16,
              fontStyle: "normal",
              fontVariantCaps: "normal",
              fontWeight: 400,
              letterSpacing: "normal",
              orphans: "auto",
              textAlign: "start",
              textTransform: "none",
              whiteSpace: "normal",
              widows: "auto",
              wordSpacing: 0,
              WebkitTextStrokeWidth: 0,
              textDecoration: "none",
            }}
          >
            <span
              className="s14"
              style={{
                lineHeight: "14.4px",
                textTransform: "uppercase",
                fontWeight: "bold",
              }}
            >
              30<span className="Apple-converted-space">&nbsp;</span>
            </span>
            <a name="_heading=h.1pxezwc" />
            <a name="_heading=h.49x2ik5" />
            <a name="_Ref124842452" />
            <span
              className="s14"
              style={{
                lineHeight: "14.4px",
                textTransform: "uppercase",
                fontWeight: "bold",
              }}
            >
              BYPASSING
            </span>
          </div>
          <div
            className="s16"
            style={{
              marginTop: 0,
              marginBottom: 0,
              textIndent: "-42px",
              marginLeft: 85,
              caretColor: "rgb(0, 0, 0)",
              color: "rgb(0, 0, 0)",
              fontFamily: "-webkit-standard",
              fontSize: 16,
              fontStyle: "normal",
              fontVariantCaps: "normal",
              fontWeight: 400,
              letterSpacing: "normal",
              orphans: "auto",
              textAlign: "start",
              textTransform: "none",
              whiteSpace: "normal",
              widows: "auto",
              wordSpacing: 0,
              WebkitTextStrokeWidth: 0,
              textDecoration: "none",
            }}
          >
            <span style={{ lineHeight: "14.4px" }}>
              (a)<span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>
              You agree that while you are a
              <span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>Seller</span>
            <span style={{ lineHeight: "14.4px" }}>
              <span className="Apple-converted-space">&nbsp;</span>on the
              Platform, regardless of the reason that your Account was suspended
              or cancelled, you will not, either directly or indirectly, solicit
              or attempt to solicit any business, work,
              <span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>
              income or other benefit, from any
              <span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>Buyer</span>
            <span style={{ lineHeight: "14.4px" }}>
              <span className="Apple-converted-space">&nbsp;</span>whom you came
              to know about, or with whom you provided goods or services to
              directly or indirectly, by using the Platform. This provision will
              apply whether or not the
              <span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>Buyer</span>
            <span style={{ lineHeight: "14.4px" }}>
              <span className="Apple-converted-space">&nbsp;</span>or their
              representative is still active on the Platform.
            </span>
          </div>
          <div
            className="s16"
            style={{
              marginTop: 0,
              marginBottom: 0,
              textIndent: "-42px",
              marginLeft: 85,
              caretColor: "rgb(0, 0, 0)",
              color: "rgb(0, 0, 0)",
              fontFamily: "-webkit-standard",
              fontSize: 16,
              fontStyle: "normal",
              fontVariantCaps: "normal",
              fontWeight: 400,
              letterSpacing: "normal",
              orphans: "auto",
              textAlign: "start",
              textTransform: "none",
              whiteSpace: "normal",
              widows: "auto",
              wordSpacing: 0,
              WebkitTextStrokeWidth: 0,
              textDecoration: "none",
            }}
          >
            <span style={{ lineHeight: "14.4px" }}>
              (b)<span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>As Is Auctions</span>
            <span style={{ lineHeight: "14.4px" }}>
              <span className="Apple-converted-space">&nbsp;</span>may, in its
              absolute discretion, cancel your Account and suspend you from
              using the Platform if it finds o
            </span>
            <span style={{ lineHeight: "14.4px" }}>
              r suspects that you have breached or are in breach of this clause
              <span className="Apple-converted-space">&nbsp;</span>
            </span>
            <a>
              <span style={{ lineHeight: "14.4px" }}>7</span>
            </a>
            <span style={{ lineHeight: "14.4px" }}>.</span>
          </div>
          <p
            className="s26"
            style={{
              marginTop: 0,
              marginBottom: 0,
              lineHeight: "14.4px",
              borderBottomStyle: "solid",
              borderBottomWidth: "thin",
              borderColor: "rgb(0, 0, 0)",
              caretColor: "rgb(0, 0, 0)",
              color: "rgb(0, 0, 0)",
              fontFamily: "-webkit-standard",
              fontSize: 16,
              fontStyle: "normal",
              fontVariantCaps: "normal",
              fontWeight: 400,
              letterSpacing: "normal",
              orphans: "auto",
              textAlign: "start",
              textIndent: 0,
              textTransform: "none",
              whiteSpace: "normal",
              widows: "auto",
              wordSpacing: 0,
              WebkitTextStrokeWidth: 0,
              textDecoration: "none",
            }}
          >
            <a name="_heading=h.2p2csry" />
            <a name="_heading=h.147n2zr" />
            <span style={{ lineHeight: "14.4px" }}></span>
          </p>
          <div
            className="s13"
            style={{
              marginTop: 18,
              marginBottom: 0,
              textIndent: "-56px",
              marginLeft: 56,
              caretColor: "rgb(0, 0, 0)",
              color: "rgb(0, 0, 0)",
              fontFamily: "-webkit-standard",
              fontSize: 16,
              fontStyle: "normal",
              fontVariantCaps: "normal",
              fontWeight: 400,
              letterSpacing: "normal",
              orphans: "auto",
              textAlign: "start",
              textTransform: "none",
              whiteSpace: "normal",
              widows: "auto",
              wordSpacing: 0,
              WebkitTextStrokeWidth: 0,
              textDecoration: "none",
            }}
          >
            <span
              className="s9"
              style={{ lineHeight: "14.4px", fontWeight: "bold" }}
            >
              Part C<span className="Apple-converted-space">&nbsp;</span>
            </span>
            <a name="_heading=h.2bn6wsx" />
            <span
              className="s9"
              style={{ lineHeight: "14.4px", fontWeight: "bold" }}
            >
              Buyers
            </span>
          </div>
          <div
            className="s15"
            style={{
              marginTop: 18,
              marginBottom: 0,
              textIndent: "-42px",
              marginLeft: 42,
              caretColor: "rgb(0, 0, 0)",
              color: "rgb(0, 0, 0)",
              fontFamily: "-webkit-standard",
              fontSize: 16,
              fontStyle: "normal",
              fontVariantCaps: "normal",
              fontWeight: 400,
              letterSpacing: "normal",
              orphans: "auto",
              textAlign: "start",
              textTransform: "none",
              whiteSpace: "normal",
              widows: "auto",
              wordSpacing: 0,
              WebkitTextStrokeWidth: 0,
              textDecoration: "none",
            }}
          >
            <span
              className="s14"
              style={{
                lineHeight: "14.4px",
                textTransform: "uppercase",
                fontWeight: "bold",
              }}
            >
              31<span className="Apple-converted-space">&nbsp;</span>
            </span>
            <a name="_heading=h.qsh70q" />
            <a name="_heading=h.ihv636" />
            <span
              className="s14"
              style={{
                lineHeight: "14.4px",
                textTransform: "uppercase",
                fontWeight: "bold",
              }}
            >
              BIDDING OR PLACING AN ORDER
            </span>
          </div>
          <div
            className="s16"
            style={{
              marginTop: 0,
              marginBottom: 0,
              textIndent: "-42px",
              marginLeft: 85,
              caretColor: "rgb(0, 0, 0)",
              color: "rgb(0, 0, 0)",
              fontFamily: "-webkit-standard",
              fontSize: 16,
              fontStyle: "normal",
              fontVariantCaps: "normal",
              fontWeight: 400,
              letterSpacing: "normal",
              orphans: "auto",
              textAlign: "start",
              textTransform: "none",
              whiteSpace: "normal",
              widows: "auto",
              wordSpacing: 0,
              WebkitTextStrokeWidth: 0,
              textDecoration: "none",
            }}
          >
            <span style={{ lineHeight: "14.4px" }}>
              (a)<span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>
              Buyers can access the Platform to view Listings and bid and place
              orders to purchase Items from Sellers.
              <span className="Apple-converted-space">&nbsp;</span>
            </span>
          </div>
          <div
            className="s16"
            style={{
              marginTop: 0,
              marginBottom: 0,
              textIndent: "-42px",
              marginLeft: 85,
              caretColor: "rgb(0, 0, 0)",
              color: "rgb(0, 0, 0)",
              fontFamily: "-webkit-standard",
              fontSize: 16,
              fontStyle: "normal",
              fontVariantCaps: "normal",
              fontWeight: 400,
              letterSpacing: "normal",
              orphans: "auto",
              textAlign: "start",
              textTransform: "none",
              whiteSpace: "normal",
              widows: "auto",
              wordSpacing: 0,
              WebkitTextStrokeWidth: 0,
              textDecoration: "none",
            }}
          >
            <span style={{ lineHeight: "14.4px" }}>
              (b)<span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>
              In order to place a bid for an Item or purchase an Item you must
              have an Account.
            </span>
          </div>
          <div
            className="s16"
            style={{
              marginTop: 0,
              marginBottom: 0,
              textIndent: "-42px",
              marginLeft: 85,
              caretColor: "rgb(0, 0, 0)",
              color: "rgb(0, 0, 0)",
              fontFamily: "-webkit-standard",
              fontSize: 16,
              fontStyle: "normal",
              fontVariantCaps: "normal",
              fontWeight: 400,
              letterSpacing: "normal",
              orphans: "auto",
              textAlign: "start",
              textTransform: "none",
              whiteSpace: "normal",
              widows: "auto",
              wordSpacing: 0,
              WebkitTextStrokeWidth: 0,
              textDecoration: "none",
            }}
          >
            <span style={{ lineHeight: "14.4px" }}>
              (c)<span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>
              Buyers should make their own enquiries as to the state of repair
              and registration status of any Items advertised by a Seller on the
              Platform.
            </span>
          </div>
          <div
            className="s16"
            style={{
              marginTop: 0,
              marginBottom: 0,
              textIndent: "-42px",
              marginLeft: 85,
              caretColor: "rgb(0, 0, 0)",
              color: "rgb(0, 0, 0)",
              fontFamily: "-webkit-standard",
              fontSize: 16,
              fontStyle: "normal",
              fontVariantCaps: "normal",
              fontWeight: 400,
              letterSpacing: "normal",
              orphans: "auto",
              textAlign: "start",
              textTransform: "none",
              whiteSpace: "normal",
              widows: "auto",
              wordSpacing: 0,
              WebkitTextStrokeWidth: 0,
              textDecoration: "none",
            }}
          >
            <span style={{ lineHeight: "14.4px" }}>
              (d)<span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>
              Once a bid or order for an Item on has been placed by you on the
              Platform it is not able to be cancelled, so please ensure that you
              complete all due diligence on an Item before placing a bid.
              <span className="Apple-converted-space">&nbsp;</span>
            </span>
          </div>
          <div
            className="s16"
            style={{
              marginTop: 0,
              marginBottom: 0,
              textIndent: "-42px",
              marginLeft: 85,
              caretColor: "rgb(0, 0, 0)",
              color: "rgb(0, 0, 0)",
              fontFamily: "-webkit-standard",
              fontSize: 16,
              fontStyle: "normal",
              fontVariantCaps: "normal",
              fontWeight: 400,
              letterSpacing: "normal",
              orphans: "auto",
              textAlign: "start",
              textTransform: "none",
              whiteSpace: "normal",
              widows: "auto",
              wordSpacing: 0,
              WebkitTextStrokeWidth: 0,
              textDecoration: "none",
            }}
          >
            <span style={{ lineHeight: "14.4px" }}>
              (e)<span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>
              When you place a bid or an offer on a Listing, that bid or offer
              placed is binding, and if accepted by a Seller,
              <span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>
              that will constitute your offer and intention to enter into a
              contract
              <span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>
              to purchase the relevant Item from the Seller.
              <span className="Apple-converted-space">&nbsp;</span>
            </span>
          </div>
          <div
            className="s15"
            style={{
              marginTop: 18,
              marginBottom: 0,
              textIndent: "-42px",
              marginLeft: 42,
              caretColor: "rgb(0, 0, 0)",
              color: "rgb(0, 0, 0)",
              fontFamily: "-webkit-standard",
              fontSize: 16,
              fontStyle: "normal",
              fontVariantCaps: "normal",
              fontWeight: 400,
              letterSpacing: "normal",
              orphans: "auto",
              textAlign: "start",
              textTransform: "none",
              whiteSpace: "normal",
              widows: "auto",
              wordSpacing: 0,
              WebkitTextStrokeWidth: 0,
              textDecoration: "none",
            }}
          >
            <span
              className="s14"
              style={{
                lineHeight: "14.4px",
                textTransform: "uppercase",
                fontWeight: "bold",
              }}
            >
              32<span className="Apple-converted-space">&nbsp;</span>
            </span>
            <a name="_Ref54183198" />
            <span
              className="s14"
              style={{
                lineHeight: "14.4px",
                textTransform: "uppercase",
                fontWeight: "bold",
              }}
            >
              BUYER FEES
            </span>
          </div>
          <div
            className="s16"
            style={{
              marginTop: 0,
              marginBottom: 0,
              textIndent: "-42px",
              marginLeft: 85,
              caretColor: "rgb(0, 0, 0)",
              color: "rgb(0, 0, 0)",
              fontFamily: "-webkit-standard",
              fontSize: 16,
              fontStyle: "normal",
              fontVariantCaps: "normal",
              fontWeight: 400,
              letterSpacing: "normal",
              orphans: "auto",
              textAlign: "start",
              textTransform: "none",
              whiteSpace: "normal",
              widows: "auto",
              wordSpacing: 0,
              WebkitTextStrokeWidth: 0,
              textDecoration: "none",
            }}
          >
            <span style={{ lineHeight: "14.4px" }}>
              (a)<span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>
              If a bid or offer you place in response to a Listing on the
              Platform is accepted by the relevant Seller, being that an
              Agreement to Purchase is
              <span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>made</span>
            <span style={{ lineHeight: "14.4px" }}>
              , you will be required to pay
              <span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>
              As Is Auctions a Buyer’s Fee
              <span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>equal to</span>
            <span style={{ lineHeight: "14.4px" }}>
              <span className="Apple-converted-space">&nbsp;</span>10% (inc GST)
              of the Fixed Price or Winning Bid Price.
            </span>
          </div>
          <div
            className="s16"
            style={{
              marginTop: 0,
              marginBottom: 0,
              textIndent: "-42px",
              marginLeft: 85,
              caretColor: "rgb(0, 0, 0)",
              color: "rgb(0, 0, 0)",
              fontFamily: "-webkit-standard",
              fontSize: 16,
              fontStyle: "normal",
              fontVariantCaps: "normal",
              fontWeight: 400,
              letterSpacing: "normal",
              orphans: "auto",
              textAlign: "start",
              textTransform: "none",
              whiteSpace: "normal",
              widows: "auto",
              wordSpacing: 0,
              WebkitTextStrokeWidth: 0,
              textDecoration: "none",
            }}
          >
            <span style={{ lineHeight: "14.4px" }}>
              (b)<span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>
              Once you have paid As Is Auction’s the Buyer Fee, As Is Auctions
              will provide you with the Seller’s contact information.
            </span>
          </div>
          <div
            className="s15"
            style={{
              marginTop: 18,
              marginBottom: 0,
              textIndent: "-42px",
              marginLeft: 42,
              caretColor: "rgb(0, 0, 0)",
              color: "rgb(0, 0, 0)",
              fontFamily: "-webkit-standard",
              fontSize: 16,
              fontStyle: "normal",
              fontVariantCaps: "normal",
              fontWeight: 400,
              letterSpacing: "normal",
              orphans: "auto",
              textAlign: "start",
              textTransform: "none",
              whiteSpace: "normal",
              widows: "auto",
              wordSpacing: 0,
              WebkitTextStrokeWidth: 0,
              textDecoration: "none",
            }}
          >
            <span
              className="s14"
              style={{
                lineHeight: "14.4px",
                textTransform: "uppercase",
                fontWeight: "bold",
              }}
            >
              33<span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span
              className="s14"
              style={{
                lineHeight: "14.4px",
                textTransform: "uppercase",
                fontWeight: "bold",
              }}
            >
              PAYMENT PROCESS
            </span>
          </div>
          <div
            className="s16"
            style={{
              marginTop: 0,
              marginBottom: 0,
              textIndent: "-42px",
              marginLeft: 85,
              caretColor: "rgb(0, 0, 0)",
              color: "rgb(0, 0, 0)",
              fontFamily: "-webkit-standard",
              fontSize: 16,
              fontStyle: "normal",
              fontVariantCaps: "normal",
              fontWeight: 400,
              letterSpacing: "normal",
              orphans: "auto",
              textAlign: "start",
              textTransform: "none",
              whiteSpace: "normal",
              widows: "auto",
              wordSpacing: 0,
              WebkitTextStrokeWidth: 0,
              textDecoration: "none",
            }}
          >
            <span style={{ lineHeight: "14.4px" }}>
              (a)<span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>
              The purchase price for an Item is as displayed on the Platform
              either at the time of the end of the auction (if a reserve price
              has been met if applicable) or at the time you order from a buy it
              now Listing. Prices and other details are subject to change
              without notice.
            </span>
          </div>
          <div
            className="s16"
            style={{
              marginTop: 0,
              marginBottom: 0,
              textIndent: "-42px",
              marginLeft: 85,
              caretColor: "rgb(0, 0, 0)",
              color: "rgb(0, 0, 0)",
              fontFamily: "-webkit-standard",
              fontSize: 16,
              fontStyle: "normal",
              fontVariantCaps: "normal",
              fontWeight: 400,
              letterSpacing: "normal",
              orphans: "auto",
              textAlign: "start",
              textTransform: "none",
              whiteSpace: "normal",
              widows: "auto",
              wordSpacing: 0,
              WebkitTextStrokeWidth: 0,
              textDecoration: "none",
            }}
          >
            <span style={{ lineHeight: "14.4px" }}>
              (b)<span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>
              Our Platform does not provide payment facilities. A Buyer must pay
              a Seller directly for an Item.
            </span>
          </div>
          <div
            className="s15"
            style={{
              marginTop: 18,
              marginBottom: 0,
              textIndent: "-42px",
              marginLeft: 42,
              caretColor: "rgb(0, 0, 0)",
              color: "rgb(0, 0, 0)",
              fontFamily: "-webkit-standard",
              fontSize: 16,
              fontStyle: "normal",
              fontVariantCaps: "normal",
              fontWeight: 400,
              letterSpacing: "normal",
              orphans: "auto",
              textAlign: "start",
              textTransform: "none",
              whiteSpace: "normal",
              widows: "auto",
              wordSpacing: 0,
              WebkitTextStrokeWidth: 0,
              textDecoration: "none",
            }}
          >
            <span
              className="s14"
              style={{
                lineHeight: "14.4px",
                textTransform: "uppercase",
                fontWeight: "bold",
              }}
            >
              34<span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span
              className="s14"
              style={{
                lineHeight: "14.4px",
                textTransform: "uppercase",
                fontWeight: "bold",
              }}
            >
              REFUNDS
            </span>
          </div>
          <div
            className="s16"
            style={{
              marginTop: 0,
              marginBottom: 0,
              textIndent: "-42px",
              marginLeft: 85,
              caretColor: "rgb(0, 0, 0)",
              color: "rgb(0, 0, 0)",
              fontFamily: "-webkit-standard",
              fontSize: 16,
              fontStyle: "normal",
              fontVariantCaps: "normal",
              fontWeight: 400,
              letterSpacing: "normal",
              orphans: "auto",
              textAlign: "start",
              textTransform: "none",
              whiteSpace: "normal",
              widows: "auto",
              wordSpacing: 0,
              WebkitTextStrokeWidth: 0,
              textDecoration: "none",
            }}
          >
            <span style={{ lineHeight: "14.4px" }}>
              (a)<span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>
              Subject to the other terms of this clause and to the extent
              permitted by law, all Fees paid by you to us
            </span>
            <span style={{ lineHeight: "14.4px" }}>
              , including the Buyer’s Fee
            </span>
            <span style={{ lineHeight: "14.4px" }}>,</span>
            <span style={{ lineHeight: "14.4px" }}>
              <span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>are</span>
            <span style={{ lineHeight: "14.4px" }}>
              <span className="Apple-converted-space">&nbsp;</span>
              non-refundable. We may at our discretion provide a refund in
              certain circumstances, however, this will be at our sole
              discretion (and we are not obliged to).
            </span>
          </div>
          <div
            className="s16"
            style={{
              marginTop: 0,
              marginBottom: 0,
              textIndent: "-42px",
              marginLeft: 85,
              caretColor: "rgb(0, 0, 0)",
              color: "rgb(0, 0, 0)",
              fontFamily: "-webkit-standard",
              fontSize: 16,
              fontStyle: "normal",
              fontVariantCaps: "normal",
              fontWeight: 400,
              letterSpacing: "normal",
              orphans: "auto",
              textAlign: "start",
              textTransform: "none",
              whiteSpace: "normal",
              widows: "auto",
              wordSpacing: 0,
              WebkitTextStrokeWidth: 0,
              textDecoration: "none",
            }}
          >
            <span style={{ lineHeight: "14.4px" }}>
              (b)<span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>
              We have no liability or obligation to you if a Seller cancels
              <span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>an Item</span>
            <span style={{ lineHeight: "14.4px" }}>
              <span className="Apple-converted-space">&nbsp;</span>any time
              after you have placed an order for it or if
              <span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>an</span>
            <span style={{ lineHeight: "14.4px" }}>
              <span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>Item you purchase</span>
            <span style={{ lineHeight: "14.4px" }}>
              <span className="Apple-converted-space">&nbsp;</span>is faulty or
              non-compliant with a Listing, these Terms and Conditions or any
              other terms (including the
              <span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>a</span>
            <span style={{ lineHeight: "14.4px" }}>
              dditional<span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>t</span>
            <span style={{ lineHeight: "14.4px" }}>
              erms) or any Applicable Laws. If you have rights to a refund or
              other remedy in respect of
              <span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>an Item</span>
            <span style={{ lineHeight: "14.4px" }}>
              <span className="Apple-converted-space">&nbsp;</span>under
              Applicable Laws, then your rights are against the Seller and not
              us.
            </span>
          </div>
          <div
            className="s15"
            style={{
              marginTop: 18,
              marginBottom: 0,
              textIndent: "-42px",
              marginLeft: 42,
              caretColor: "rgb(0, 0, 0)",
              color: "rgb(0, 0, 0)",
              fontFamily: "-webkit-standard",
              fontSize: 16,
              fontStyle: "normal",
              fontVariantCaps: "normal",
              fontWeight: 400,
              letterSpacing: "normal",
              orphans: "auto",
              textAlign: "start",
              textTransform: "none",
              whiteSpace: "normal",
              widows: "auto",
              wordSpacing: 0,
              WebkitTextStrokeWidth: 0,
              textDecoration: "none",
            }}
          >
            <span
              className="s14"
              style={{
                lineHeight: "14.4px",
                textTransform: "uppercase",
                fontWeight: "bold",
              }}
            >
              35<span className="Apple-converted-space">&nbsp;</span>
            </span>
            <a name="_heading=h.32hioqz" />
            <a name="_heading=h.1hmsyys" />
            <span
              className="s14"
              style={{
                lineHeight: "14.4px",
                textTransform: "uppercase",
                fontWeight: "bold",
              }}
            >
              LINKED BUSINESSES
            </span>
          </div>
          <p
            className="s18"
            style={{
              marginTop: 0,
              marginBottom: 0,
              lineHeight: "14.4px",
              marginLeft: 42,
              caretColor: "rgb(0, 0, 0)",
              color: "rgb(0, 0, 0)",
              fontFamily: "-webkit-standard",
              fontSize: 16,
              fontStyle: "normal",
              fontVariantCaps: "normal",
              fontWeight: 400,
              letterSpacing: "normal",
              orphans: "auto",
              textAlign: "start",
              textIndent: 0,
              textTransform: "none",
              whiteSpace: "normal",
              widows: "auto",
              wordSpacing: 0,
              WebkitTextStrokeWidth: 0,
              textDecoration: "none",
            }}
          >
            <span style={{ lineHeight: "14.4px" }}>
              You acknowledge and agree that:
            </span>
          </p>
          <div
            className="s16"
            style={{
              marginTop: 0,
              marginBottom: 0,
              textIndent: "-42px",
              marginLeft: 85,
              caretColor: "rgb(0, 0, 0)",
              color: "rgb(0, 0, 0)",
              fontFamily: "-webkit-standard",
              fontSize: 16,
              fontStyle: "normal",
              fontVariantCaps: "normal",
              fontWeight: 400,
              letterSpacing: "normal",
              orphans: "auto",
              textAlign: "start",
              textTransform: "none",
              whiteSpace: "normal",
              widows: "auto",
              wordSpacing: 0,
              WebkitTextStrokeWidth: 0,
              textDecoration: "none",
            }}
          >
            <span style={{ lineHeight: "14.4px" }}>
              (a)<span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>
              the Platform provides links and introductions to
              <span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>Seller</span>
            <span style={{ lineHeight: "14.4px" }}>
              s owned and operated by third parties that are not under the
              control of<span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>As Is Auctions</span>
            <span style={{ lineHeight: "14.4px" }}>;</span>
          </div>
          <div
            className="s16"
            style={{
              marginTop: 0,
              marginBottom: 0,
              textIndent: "-42px",
              marginLeft: 85,
              caretColor: "rgb(0, 0, 0)",
              color: "rgb(0, 0, 0)",
              fontFamily: "-webkit-standard",
              fontSize: 16,
              fontStyle: "normal",
              fontVariantCaps: "normal",
              fontWeight: 400,
              letterSpacing: "normal",
              orphans: "auto",
              textAlign: "start",
              textTransform: "none",
              whiteSpace: "normal",
              widows: "auto",
              wordSpacing: 0,
              WebkitTextStrokeWidth: 0,
              textDecoration: "none",
            }}
          >
            <span style={{ lineHeight: "14.4px" }}>
              (b)<span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>
              the provision by
              <span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>As Is Auctions</span>
            <span style={{ lineHeight: "14.4px" }}>
              <span className="Apple-converted-space">&nbsp;</span>of
              introductions to
              <span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>Seller</span>
            <span style={{ lineHeight: "14.4px" }}>
              s does not imply any endorsement or recommendation by
              <span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>As Is Auctions</span>
            <span style={{ lineHeight: "14.4px" }}>
              <span className="Apple-converted-space">&nbsp;</span>of any
              <span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>Seller</span>
            <span style={{ lineHeight: "14.4px" }}>
              ;<span className="Apple-converted-space">&nbsp;</span>
            </span>
          </div>
          <div
            className="s16"
            style={{
              marginTop: 0,
              marginBottom: 0,
              textIndent: "-42px",
              marginLeft: 85,
              caretColor: "rgb(0, 0, 0)",
              color: "rgb(0, 0, 0)",
              fontFamily: "-webkit-standard",
              fontSize: 16,
              fontStyle: "normal",
              fontVariantCaps: "normal",
              fontWeight: 400,
              letterSpacing: "normal",
              orphans: "auto",
              textAlign: "start",
              textTransform: "none",
              whiteSpace: "normal",
              widows: "auto",
              wordSpacing: 0,
              WebkitTextStrokeWidth: 0,
              textDecoration: "none",
            }}
          >
            <span style={{ lineHeight: "14.4px" }}>
              (c)<span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>As Is Auctions</span>
            <span style={{ lineHeight: "14.4px" }}>
              <span className="Apple-converted-space">&nbsp;</span>does not
              examine, determine or warrant the certification and/or licensing,
              competence, solvency or information of any
              <span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>Seller</span>
            <span style={{ lineHeight: "14.4px" }}>
              <span className="Apple-converted-space">&nbsp;</span>who uses or
              is listed on the Platform; and
            </span>
          </div>
          <div
            className="s16"
            style={{
              marginTop: 0,
              marginBottom: 0,
              textIndent: "-42px",
              marginLeft: 85,
              caretColor: "rgb(0, 0, 0)",
              color: "rgb(0, 0, 0)",
              fontFamily: "-webkit-standard",
              fontSize: 16,
              fontStyle: "normal",
              fontVariantCaps: "normal",
              fontWeight: 400,
              letterSpacing: "normal",
              orphans: "auto",
              textAlign: "start",
              textTransform: "none",
              whiteSpace: "normal",
              widows: "auto",
              wordSpacing: 0,
              WebkitTextStrokeWidth: 0,
              textDecoration: "none",
            }}
          >
            <span style={{ lineHeight: "14.4px" }}>
              (d)<span className="Apple-converted-space">&nbsp;</span>
            </span>
            <a name="_heading=h.41mghml" />
            <span style={{ lineHeight: "14.4px" }}>
              any terms and conditions relating to a
              <span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>Listing</span>
            <span style={{ lineHeight: "14.4px" }}>
              <span className="Apple-converted-space">&nbsp;</span>or quote
              provided via the Platform constitute a contract between you and
              the
              <span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>Seller</span>
            <span style={{ lineHeight: "14.4px" }}>
              <span className="Apple-converted-space">&nbsp;</span>once agreed
              in accordance with clause
              <span className="Apple-converted-space">&nbsp;</span>
            </span>
            <a>
              <span style={{ lineHeight: "14.4px" }}>1</span>
            </a>
            <span style={{ lineHeight: "14.4px" }}>
              <span className="Apple-converted-space">&nbsp;</span>and do not
              involve
              <span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>As Is Auctions</span>
            <span style={{ lineHeight: "14.4px" }}>
              <span className="Apple-converted-space">&nbsp;</span>in any way.
            </span>
          </div>
          <div
            className="s15"
            style={{
              marginTop: 18,
              marginBottom: 0,
              textIndent: "-42px",
              marginLeft: 42,
              caretColor: "rgb(0, 0, 0)",
              color: "rgb(0, 0, 0)",
              fontFamily: "-webkit-standard",
              fontSize: 16,
              fontStyle: "normal",
              fontVariantCaps: "normal",
              fontWeight: 400,
              letterSpacing: "normal",
              orphans: "auto",
              textAlign: "start",
              textTransform: "none",
              whiteSpace: "normal",
              widows: "auto",
              wordSpacing: 0,
              WebkitTextStrokeWidth: 0,
              textDecoration: "none",
            }}
          >
            <span
              className="s14"
              style={{
                lineHeight: "14.4px",
                textTransform: "uppercase",
                fontWeight: "bold",
              }}
            >
              36<span className="Apple-converted-space">&nbsp;</span>
            </span>
            <a name="_heading=h.2grqrue" />
            <a name="_Ref124843229" />
            <span
              className="s14"
              style={{
                lineHeight: "14.4px",
                textTransform: "uppercase",
                fontWeight: "bold",
              }}
            >
              COMMUNICATION OUTSIDE THE PLATFORM
            </span>
          </div>
          <div
            className="s16"
            style={{
              marginTop: 0,
              marginBottom: 0,
              textIndent: "-42px",
              marginLeft: 85,
              caretColor: "rgb(0, 0, 0)",
              color: "rgb(0, 0, 0)",
              fontFamily: "-webkit-standard",
              fontSize: 16,
              fontStyle: "normal",
              fontVariantCaps: "normal",
              fontWeight: 400,
              letterSpacing: "normal",
              orphans: "auto",
              textAlign: "start",
              textTransform: "none",
              whiteSpace: "normal",
              widows: "auto",
              wordSpacing: 0,
              WebkitTextStrokeWidth: 0,
              textDecoration: "none",
            }}
          >
            <span style={{ lineHeight: "14.4px" }}>
              (a)<span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>
              You must work together with the Seller to complete the transaction
              <span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>
              within<span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>
              a reasonable period of time as agreed between you and the Seller
            </span>
            <span style={{ lineHeight: "14.4px" }}>
              , arranging any shipping (which is at the Buyer’s expense) if
              necessary.<span className="Apple-converted-space">&nbsp;</span>
            </span>
          </div>
          <div
            className="s16"
            style={{
              marginTop: 0,
              marginBottom: 0,
              textIndent: "-42px",
              marginLeft: 85,
              caretColor: "rgb(0, 0, 0)",
              color: "rgb(0, 0, 0)",
              fontFamily: "-webkit-standard",
              fontSize: 16,
              fontStyle: "normal",
              fontVariantCaps: "normal",
              fontWeight: 400,
              letterSpacing: "normal",
              orphans: "auto",
              textAlign: "start",
              textTransform: "none",
              whiteSpace: "normal",
              widows: "auto",
              wordSpacing: 0,
              WebkitTextStrokeWidth: 0,
              textDecoration: "none",
            }}
          >
            <span style={{ lineHeight: "14.4px" }}>
              (b)<span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>
              A Seller is a third party that is unrelated to us. When you place
              an order for
              <span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>an Item</span>
            <span style={{ lineHeight: "14.4px" }}>
              , you are entering into a contract directly with the Seller. We
              are not a party to any contract between you and a Seller and are
              not responsible for ensuring that a Seller fulfills any order for
              <span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>an Item</span>
            <span style={{ lineHeight: "14.4px" }}>
              <span className="Apple-converted-space">&nbsp;</span>or complies
              with these Terms and Conditions or any other terms published by
              us,
              <span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>
              or<span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>
              any Applicable Laws.
              <span className="Apple-converted-space">&nbsp;</span>
            </span>
          </div>
          <div
            className="s16"
            style={{
              marginTop: 0,
              marginBottom: 0,
              textIndent: "-42px",
              marginLeft: 85,
              caretColor: "rgb(0, 0, 0)",
              color: "rgb(0, 0, 0)",
              fontFamily: "-webkit-standard",
              fontSize: 16,
              fontStyle: "normal",
              fontVariantCaps: "normal",
              fontWeight: 400,
              letterSpacing: "normal",
              orphans: "auto",
              textAlign: "start",
              textTransform: "none",
              whiteSpace: "normal",
              widows: "auto",
              wordSpacing: 0,
              WebkitTextStrokeWidth: 0,
              textDecoration: "none",
            }}
          >
            <span style={{ lineHeight: "14.4px" }}>
              (c)<span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>
              We do not supply, provide, manage or control the Sellers on the
              <span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>Platform</span>
            <span style={{ lineHeight: "14.4px" }}>
              <span className="Apple-converted-space">&nbsp;</span>or their
              <span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>Item</span>
            <span style={{ lineHeight: "14.4px" }}>
              s and are not responsible for their marketing, preparation,
              delivery, supply, packaging or otherwise. The Seller is solely
              responsible for supplying the
              <span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>Item</span>
            <span style={{ lineHeight: "14.4px" }}>
              s to you. We take no responsibility for the quality of
              <span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>Item</span>
            <span style={{ lineHeight: "14.4px" }}>
              s published on the
              <span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>Platform</span>
            <span style={{ lineHeight: "14.4px" }}>
              . You purchase
              <span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>an Item</span>
            <span style={{ lineHeight: "14.4px" }}>
              <span className="Apple-converted-space">&nbsp;</span>at your own
              risk, &nbsp;
            </span>
          </div>
          <div
            className="s16"
            style={{
              marginTop: 0,
              marginBottom: 0,
              textIndent: "-42px",
              marginLeft: 85,
              caretColor: "rgb(0, 0, 0)",
              color: "rgb(0, 0, 0)",
              fontFamily: "-webkit-standard",
              fontSize: 16,
              fontStyle: "normal",
              fontVariantCaps: "normal",
              fontWeight: 400,
              letterSpacing: "normal",
              orphans: "auto",
              textAlign: "start",
              textTransform: "none",
              whiteSpace: "normal",
              widows: "auto",
              wordSpacing: 0,
              WebkitTextStrokeWidth: 0,
              textDecoration: "none",
            }}
          >
            <span style={{ lineHeight: "14.4px" }}>
              (d)<span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>
              The information about Sellers and their
              <span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>Item</span>
            <span style={{ lineHeight: "14.4px" }}>
              s contained on the
              <span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>Platform</span>
            <span style={{ lineHeight: "14.4px" }}>
              <span className="Apple-converted-space">&nbsp;</span>is provided
              to us by Sellers and other third parties and we cannot, and do
              not, guarantee the completeness, accuracy, currency or reliability
              of such information. You must make your own investigation in the
              accuracy of the information.
              <span className="Apple-converted-space">&nbsp;</span>
            </span>
          </div>
          <div
            className="s16"
            style={{
              marginTop: 0,
              marginBottom: 0,
              textIndent: "-42px",
              marginLeft: 85,
              caretColor: "rgb(0, 0, 0)",
              color: "rgb(0, 0, 0)",
              fontFamily: "-webkit-standard",
              fontSize: 16,
              fontStyle: "normal",
              fontVariantCaps: "normal",
              fontWeight: 400,
              letterSpacing: "normal",
              orphans: "auto",
              textAlign: "start",
              textTransform: "none",
              whiteSpace: "normal",
              widows: "auto",
              wordSpacing: 0,
              WebkitTextStrokeWidth: 0,
              textDecoration: "none",
            }}
          >
            <span style={{ lineHeight: "14.4px" }}>
              (e)<span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>
              For the avoidance of doubt and without limitation, we are not
              responsible for ensuring and verifying any licenses
              <span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>
              which a Seller may be required to hold.
              <span className="Apple-converted-space">&nbsp;</span>
            </span>
          </div>
          <div
            className="s16"
            style={{
              marginTop: 0,
              marginBottom: 0,
              textIndent: "-42px",
              marginLeft: 85,
              caretColor: "rgb(0, 0, 0)",
              color: "rgb(0, 0, 0)",
              fontFamily: "-webkit-standard",
              fontSize: 16,
              fontStyle: "normal",
              fontVariantCaps: "normal",
              fontWeight: 400,
              letterSpacing: "normal",
              orphans: "auto",
              textAlign: "start",
              textTransform: "none",
              whiteSpace: "normal",
              widows: "auto",
              wordSpacing: 0,
              WebkitTextStrokeWidth: 0,
              textDecoration: "none",
            }}
          >
            <span style={{ lineHeight: "14.4px" }}>
              (f)<span className="Apple-converted-space">&nbsp;</span>
            </span>
            <span style={{ lineHeight: "14.4px" }}>
              Please ensure that you undertake a search of the Item you purchase
              on the Personal Property Securities Register and otherwise ensure
              that it is free from Security Interests.
              <span className="Apple-converted-space">&nbsp;</span>
            </span>
          </div>
          <p
            style={{
              marginTop: 0,
              marginBottom: 0,
              lineHeight: "14.4px",
              caretColor: "rgb(0, 0, 0)",
              color: "rgb(0, 0, 0)",
              fontFamily: "-webkit-standard",
              fontSize: 16,
              fontStyle: "normal",
              fontVariantCaps: "normal",
              fontWeight: 400,
              letterSpacing: "normal",
              orphans: "auto",
              textAlign: "start",
              textIndent: 0,
              textTransform: "none",
              whiteSpace: "normal",
              widows: "auto",
              wordSpacing: 0,
              WebkitTextStrokeWidth: 0,
              textDecoration: "none",
            }}
          >
            <span style={{ lineHeight: "14.4px" }}>&nbsp;</span>
          </p>
          <table
            style={{
              borderCollapse: "collapse",
              borderColor: "black",
              fontSize: 16,
              caretColor: "rgb(0, 0, 0)",
              fontFamily: "-webkit-standard",
              letterSpacing: "normal",
              orphans: "auto",
              textTransform: "none",
              widows: "auto",
              wordSpacing: 0,
              WebkitTextStrokeWidth: 0,
              textDecoration: "none",
              marginLeft: 0,
              borderStyle: "none",
            }}
          >
            <tbody>
              <tr style={{ verticalAlign: "top" }}>
                <td
                  className="s27"
                  style={{
                    overflowWrap: "break-word",
                    height: 14,
                    borderStyle: "none",
                  }}
                >
                  <div style={{ marginTop: 0, marginBottom: 0, maxWidth: 219 }}>
                    <p
                      className="s28"
                      style={{
                        marginTop: 4,
                        marginBottom: 0,
                        lineHeight: "14.4px",
                      }}
                    >
                      <span style={{ lineHeight: "14.4px" }}>&nbsp;</span>
                    </p>
                  </div>
                </td>
                <td
                  className="s27"
                  style={{
                    overflowWrap: "break-word",
                    height: 14,
                    borderStyle: "none",
                  }}
                >
                  <div style={{ marginTop: 0, marginBottom: 0, maxWidth: 42 }}>
                    <p
                      style={{
                        marginTop: 0,
                        marginBottom: 0,
                        lineHeight: "14.4px",
                      }}
                    >
                      
                    </p>
                  </div>
                </td>
                <td
                  className="s27"
                  style={{
                    overflowWrap: "break-word",
                    height: 14,
                    borderStyle: "none",
                  }}
                >
                  <div style={{ marginTop: 0, marginBottom: 0, maxWidth: 219 }}>
                    <p
                      className="s30"
                      style={{
                        marginTop: 4,
                        marginBottom: 0,
                        lineHeight: "14.4px",
                        textAlign: "right",
                      }}
                    >
                      <span style={{ lineHeight: "14.4px" }}>&nbsp;</span>
                    </p>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
          <p
            className="s31"
            style={{
              marginTop: 0,
              marginBottom: 0,
              lineHeight: "14.4px",
              caretColor: "rgb(0, 0, 0)",
              color: "rgb(0, 0, 0)",
              fontFamily: "-webkit-standard",
              fontSize: 16,
              fontStyle: "normal",
              fontVariantCaps: "normal",
              fontWeight: 400,
              letterSpacing: "normal",
              orphans: "auto",
              textAlign: "start",
              textIndent: 0,
              textTransform: "none",
              whiteSpace: "normal",
              widows: "auto",
              wordSpacing: 0,
              WebkitTextStrokeWidth: 0,
              textDecoration: "none",
            }}
          >
         
          </p>
        </Container>
      </section>
    </Layout>
  );
};

export default TermsConditions;
