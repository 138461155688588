import React from "react";

const DashboardHeader = () => {
  return (
    <div className="heading-section">
      <>
        <h3 className="text-start">Account Details</h3>
        <h6 className="text-start"></h6>
        <hr />
      </>
    </div>
  );
};

export default DashboardHeader;
