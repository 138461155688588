import React from "react";

const previewheader = () => {
  return (
    <>
      {/* <InfiniteLooper speed="1" direction="left">
        // the stuff you want to loop
      </InfiniteLooper> */}
    </>
  );
};

export default previewheader;
