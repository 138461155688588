import React from "react";
import { Container } from "react-bootstrap";
import Layout from "../components/Layout/Layout/Layout";
import PageHeading from "../components/Layout/PageHeading";

const PrivacyPolicy = () => {
  const pageTitle = "Privacy Policy";
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Layout>
      <PageHeading title={pageTitle} />
      <br />
      <Container style={{ overflowWrap: "break-word", wordWrap: "break-word" }}>
        <div
          className="s7"
          style={{
            marginTop: 18,
            marginBottom: 0,
            textIndent: "-42px",
            marginLeft: 42,
            caretColor: "rgb(0, 0, 0)",
            color: "rgb(0, 0, 0)",
            fontFamily: "-webkit-standard",
            fontSize: 16,
            fontStyle: "normal",
            fontVariantCaps: "normal",
            fontWeight: 400,
            letterSpacing: "normal",
            orphans: "auto",
            textAlign: "start",
            textTransform: "none",
            whiteSpace: "normal",
            widows: "auto",
            wordSpacing: 0,
            WebkitTextStrokeWidth: 0,
            textDecoration: "none",
          }}
        >
          <span
            className="s5"
            style={{
              lineHeight: "14.4px",
              textTransform: "uppercase",
              fontWeight: "bold",
            }}
          >
            1<span className="Apple-converted-space">&nbsp;</span>
          </span>
          <span
            className="s5"
            style={{
              lineHeight: "14.4px",
              textTransform: "uppercase",
              fontWeight: "bold",
            }}
          >
            INTRODUCTION
          </span>
        </div>
        <div
          className="s9"
          style={{
            marginTop: 0,
            marginBottom: 0,
            textIndent: 0,
            marginLeft: 42,
            caretColor: "rgb(0, 0, 0)",
            color: "rgb(0, 0, 0)",
            fontFamily: "-webkit-standard",
            fontSize: 16,
            fontStyle: "normal",
            fontVariantCaps: "normal",
            fontWeight: 400,
            letterSpacing: "normal",
            orphans: "auto",
            textAlign: "start",
            textTransform: "none",
            whiteSpace: "normal",
            widows: "auto",
            wordSpacing: 0,
            WebkitTextStrokeWidth: 0,
            textDecoration: "none",
          }}
        >
          <span style={{ lineHeight: "14.4px" }}>
            This document sets out the privacy policy of
            <span className="Apple-converted-space">&nbsp;</span>
          </span>
          <span
            className="s8"
            style={{ lineHeight: "14.4px", fontWeight: "bold" }}
          >
            AS IS AUCTIONS PTY LTD (ABN 92 672 843 775)
          </span>
          <span style={{ lineHeight: "14.4px" }}>
            <span className="Apple-converted-space">&nbsp;</span>
          </span>
          <span style={{ lineHeight: "14.4px" }}>
            (referred to in this privacy policy as
            <span className="Apple-converted-space">&nbsp;</span>
          </span>
          <span style={{ lineHeight: "14.4px" }}>‘</span>
          <span
            className="s8"
            style={{ lineHeight: "14.4px", fontWeight: "bold" }}
          >
            we
          </span>
          <span
            className="s8"
            style={{ lineHeight: "14.4px", fontWeight: "bold" }}
          >
            ’
          </span>
          <span style={{ lineHeight: "14.4px" }}>
            ,<span className="Apple-converted-space">&nbsp;</span>
          </span>
          <span style={{ lineHeight: "14.4px" }}>‘</span>
          <span
            className="s8"
            style={{ lineHeight: "14.4px", fontWeight: "bold" }}
          >
            us
          </span>
          <span
            className="s8"
            style={{ lineHeight: "14.4px", fontWeight: "bold" }}
          >
            ’
          </span>
          <span style={{ lineHeight: "14.4px" }}>
            , or<span className="Apple-converted-space">&nbsp;</span>
          </span>
          <span style={{ lineHeight: "14.4px" }}>‘</span>
          <span
            className="s8"
            style={{ lineHeight: "14.4px", fontWeight: "bold" }}
          >
            our
          </span>
          <span
            className="s8"
            style={{ lineHeight: "14.4px", fontWeight: "bold" }}
          >
            ’
          </span>
          <span style={{ lineHeight: "14.4px" }}>).</span>
        </div>
        <div
          className="s9"
          style={{
            marginTop: 0,
            marginBottom: 0,
            textIndent: 0,
            marginLeft: 42,
            caretColor: "rgb(0, 0, 0)",
            color: "rgb(0, 0, 0)",
            fontFamily: "-webkit-standard",
            fontSize: 16,
            fontStyle: "normal",
            fontVariantCaps: "normal",
            fontWeight: 400,
            letterSpacing: "normal",
            orphans: "auto",
            textAlign: "start",
            textTransform: "none",
            whiteSpace: "normal",
            widows: "auto",
            wordSpacing: 0,
            WebkitTextStrokeWidth: 0,
            textDecoration: "none",
          }}
        >
          <span style={{ lineHeight: "14.4px" }}>
            We take our privacy obligations seriously and we
          </span>
          <span style={{ lineHeight: "14.4px" }}>’</span>
          <span style={{ lineHeight: "14.4px" }}>
            ve created this privacy policy to explain how we store, maintain,
            <span className="Apple-converted-space">&nbsp;</span>
          </span>
          <span style={{ lineHeight: "14.4px" }}>use</span>
          <span style={{ lineHeight: "14.4px" }}>
            <span className="Apple-converted-space">&nbsp;</span>and disclose
            personal information.
          </span>
        </div>
        <div
          className="s9"
          style={{
            marginTop: 0,
            marginBottom: 0,
            textIndent: 0,
            marginLeft: 42,
            caretColor: "rgb(0, 0, 0)",
            color: "rgb(0, 0, 0)",
            fontFamily: "-webkit-standard",
            fontSize: 16,
            fontStyle: "normal",
            fontVariantCaps: "normal",
            fontWeight: 400,
            letterSpacing: "normal",
            orphans: "auto",
            textAlign: "start",
            textTransform: "none",
            whiteSpace: "normal",
            widows: "auto",
            wordSpacing: 0,
            WebkitTextStrokeWidth: 0,
            textDecoration: "none",
          }}
        >
          <span style={{ lineHeight: "14.4px" }}>
            By providing personal information to us, you consent to our storage,
            maintenance, use and disclosing of personal information in
            accordance with this privacy policy.
          </span>
        </div>
        <div
          className="s9"
          style={{
            marginTop: 0,
            marginBottom: 0,
            textIndent: 0,
            marginLeft: 42,
            caretColor: "rgb(0, 0, 0)",
            color: "rgb(0, 0, 0)",
            fontFamily: "-webkit-standard",
            fontSize: 16,
            fontStyle: "normal",
            fontVariantCaps: "normal",
            fontWeight: 400,
            letterSpacing: "normal",
            orphans: "auto",
            textAlign: "start",
            textTransform: "none",
            whiteSpace: "normal",
            widows: "auto",
            wordSpacing: 0,
            WebkitTextStrokeWidth: 0,
            textDecoration: "none",
          }}
        >
          <span style={{ lineHeight: "14.4px" }}>
            We may change this privacy policy from time to time by posting an
            updated copy on our website and we encourage you to check our
            website regularly to ensure that you are aware of our most current
            privacy policy.
          </span>
        </div>
        <div
          className="s7"
          style={{
            marginTop: 18,
            marginBottom: 0,
            textIndent: "-42px",
            marginLeft: 42,
            caretColor: "rgb(0, 0, 0)",
            color: "rgb(0, 0, 0)",
            fontFamily: "-webkit-standard",
            fontSize: 16,
            fontStyle: "normal",
            fontVariantCaps: "normal",
            fontWeight: 400,
            letterSpacing: "normal",
            orphans: "auto",
            textAlign: "start",
            textTransform: "none",
            whiteSpace: "normal",
            widows: "auto",
            wordSpacing: 0,
            WebkitTextStrokeWidth: 0,
            textDecoration: "none",
          }}
        >
          <span
            className="s5"
            style={{
              lineHeight: "14.4px",
              textTransform: "uppercase",
              fontWeight: "bold",
            }}
          >
            2<span className="Apple-converted-space">&nbsp;</span>
          </span>
          <a name="_Toc8723799" />
          <span
            className="s5"
            style={{
              lineHeight: "14.4px",
              textTransform: "uppercase",
              fontWeight: "bold",
            }}
          >
            TYPES OF PERSONAL INFORMATION WE COLLECT
          </span>
        </div>
        <div
          className="s9"
          style={{
            marginTop: 0,
            marginBottom: 0,
            textIndent: 0,
            marginLeft: 42,
            caretColor: "rgb(0, 0, 0)",
            color: "rgb(0, 0, 0)",
            fontFamily: "-webkit-standard",
            fontSize: 16,
            fontStyle: "normal",
            fontVariantCaps: "normal",
            fontWeight: 400,
            letterSpacing: "normal",
            orphans: "auto",
            textAlign: "start",
            textTransform: "none",
            whiteSpace: "normal",
            widows: "auto",
            wordSpacing: 0,
            WebkitTextStrokeWidth: 0,
            textDecoration: "none",
          }}
        >
          <span style={{ lineHeight: "14.4px" }}>
            The personal information we collect may include the following:
          </span>
        </div>
        <div
          className="s10"
          style={{
            marginTop: 0,
            marginBottom: 0,
            textIndent: "-42px",
            marginLeft: 85,
            caretColor: "rgb(0, 0, 0)",
            color: "rgb(0, 0, 0)",
            fontFamily: "-webkit-standard",
            fontSize: 16,
            fontStyle: "normal",
            fontVariantCaps: "normal",
            fontWeight: 400,
            letterSpacing: "normal",
            orphans: "auto",
            textAlign: "start",
            textTransform: "none",
            whiteSpace: "normal",
            widows: "auto",
            wordSpacing: 0,
            WebkitTextStrokeWidth: 0,
            textDecoration: "none",
          }}
        >
          <span style={{ lineHeight: "14.4px" }}>
            (a)<span className="Apple-converted-space">&nbsp;</span>
          </span>
          <span style={{ lineHeight: "14.4px" }}>name;</span>
        </div>
        <div
          className="s10"
          style={{
            marginTop: 0,
            marginBottom: 0,
            textIndent: "-42px",
            marginLeft: 85,
            caretColor: "rgb(0, 0, 0)",
            color: "rgb(0, 0, 0)",
            fontFamily: "-webkit-standard",
            fontSize: 16,
            fontStyle: "normal",
            fontVariantCaps: "normal",
            fontWeight: 400,
            letterSpacing: "normal",
            orphans: "auto",
            textAlign: "start",
            textTransform: "none",
            whiteSpace: "normal",
            widows: "auto",
            wordSpacing: 0,
            WebkitTextStrokeWidth: 0,
            textDecoration: "none",
          }}
        >
          <span style={{ lineHeight: "14.4px" }}>
            (b)<span className="Apple-converted-space">&nbsp;</span>
          </span>
          <span style={{ lineHeight: "14.4px" }}>
            mailing or street address;
          </span>
        </div>
        <div
          className="s10"
          style={{
            marginTop: 0,
            marginBottom: 0,
            textIndent: "-42px",
            marginLeft: 85,
            caretColor: "rgb(0, 0, 0)",
            color: "rgb(0, 0, 0)",
            fontFamily: "-webkit-standard",
            fontSize: 16,
            fontStyle: "normal",
            fontVariantCaps: "normal",
            fontWeight: 400,
            letterSpacing: "normal",
            orphans: "auto",
            textAlign: "start",
            textTransform: "none",
            whiteSpace: "normal",
            widows: "auto",
            wordSpacing: 0,
            WebkitTextStrokeWidth: 0,
            textDecoration: "none",
          }}
        >
          <span style={{ lineHeight: "14.4px" }}>
            (c)<span className="Apple-converted-space">&nbsp;</span>
          </span>
          <span style={{ lineHeight: "14.4px" }}>email address;</span>
        </div>
        <div
          className="s10"
          style={{
            marginTop: 0,
            marginBottom: 0,
            textIndent: "-42px",
            marginLeft: 85,
            caretColor: "rgb(0, 0, 0)",
            color: "rgb(0, 0, 0)",
            fontFamily: "-webkit-standard",
            fontSize: 16,
            fontStyle: "normal",
            fontVariantCaps: "normal",
            fontWeight: 400,
            letterSpacing: "normal",
            orphans: "auto",
            textAlign: "start",
            textTransform: "none",
            whiteSpace: "normal",
            widows: "auto",
            wordSpacing: 0,
            WebkitTextStrokeWidth: 0,
            textDecoration: "none",
          }}
        >
          <span style={{ lineHeight: "14.4px" }}>
            (d)<span className="Apple-converted-space">&nbsp;</span>
          </span>
          <span style={{ lineHeight: "14.4px" }}>
            social media information;
          </span>
        </div>
        <div
          className="s10"
          style={{
            marginTop: 0,
            marginBottom: 0,
            textIndent: "-42px",
            marginLeft: 85,
            caretColor: "rgb(0, 0, 0)",
            color: "rgb(0, 0, 0)",
            fontFamily: "-webkit-standard",
            fontSize: 16,
            fontStyle: "normal",
            fontVariantCaps: "normal",
            fontWeight: 400,
            letterSpacing: "normal",
            orphans: "auto",
            textAlign: "start",
            textTransform: "none",
            whiteSpace: "normal",
            widows: "auto",
            wordSpacing: 0,
            WebkitTextStrokeWidth: 0,
            textDecoration: "none",
          }}
        >
          <span style={{ lineHeight: "14.4px" }}>
            (e)<span className="Apple-converted-space">&nbsp;</span>
          </span>
          <span style={{ lineHeight: "14.4px" }}>
            telephone number and other contact details;
          </span>
        </div>
        <div
          className="s10"
          style={{
            marginTop: 0,
            marginBottom: 0,
            textIndent: "-42px",
            marginLeft: 85,
            caretColor: "rgb(0, 0, 0)",
            color: "rgb(0, 0, 0)",
            fontFamily: "-webkit-standard",
            fontSize: 16,
            fontStyle: "normal",
            fontVariantCaps: "normal",
            fontWeight: 400,
            letterSpacing: "normal",
            orphans: "auto",
            textAlign: "start",
            textTransform: "none",
            whiteSpace: "normal",
            widows: "auto",
            wordSpacing: 0,
            WebkitTextStrokeWidth: 0,
            textDecoration: "none",
          }}
        >
          <span style={{ lineHeight: "14.4px" }}>
            (f)<span className="Apple-converted-space">&nbsp;</span>
          </span>
          <span style={{ lineHeight: "14.4px" }}>age;</span>
        </div>
        <div
          className="s10"
          style={{
            marginTop: 0,
            marginBottom: 0,
            textIndent: "-42px",
            marginLeft: 85,
            caretColor: "rgb(0, 0, 0)",
            color: "rgb(0, 0, 0)",
            fontFamily: "-webkit-standard",
            fontSize: 16,
            fontStyle: "normal",
            fontVariantCaps: "normal",
            fontWeight: 400,
            letterSpacing: "normal",
            orphans: "auto",
            textAlign: "start",
            textTransform: "none",
            whiteSpace: "normal",
            widows: "auto",
            wordSpacing: 0,
            WebkitTextStrokeWidth: 0,
            textDecoration: "none",
          }}
        >
          <span style={{ lineHeight: "14.4px" }}>
            (g)<span className="Apple-converted-space">&nbsp;</span>
          </span>
          <span style={{ lineHeight: "14.4px" }}>date of birth;</span>
        </div>
        <div
          className="s10"
          style={{
            marginTop: 0,
            marginBottom: 0,
            textIndent: "-42px",
            marginLeft: 85,
            caretColor: "rgb(0, 0, 0)",
            color: "rgb(0, 0, 0)",
            fontFamily: "-webkit-standard",
            fontSize: 16,
            fontStyle: "normal",
            fontVariantCaps: "normal",
            fontWeight: 400,
            letterSpacing: "normal",
            orphans: "auto",
            textAlign: "start",
            textTransform: "none",
            whiteSpace: "normal",
            widows: "auto",
            wordSpacing: 0,
            WebkitTextStrokeWidth: 0,
            textDecoration: "none",
          }}
        >
          <span style={{ lineHeight: "14.4px" }}>
            (h)<span className="Apple-converted-space">&nbsp;</span>
          </span>
          <span style={{ lineHeight: "14.4px" }}>drivers’</span>
          <span style={{ lineHeight: "14.4px" }}>
            <span className="Apple-converted-space">&nbsp;</span>licence;
            <span className="Apple-converted-space">&nbsp;</span>
          </span>
        </div>
        <div
          className="s10"
          style={{
            marginTop: 0,
            marginBottom: 0,
            textIndent: "-42px",
            marginLeft: 85,
            caretColor: "rgb(0, 0, 0)",
            color: "rgb(0, 0, 0)",
            fontFamily: "-webkit-standard",
            fontSize: 16,
            fontStyle: "normal",
            fontVariantCaps: "normal",
            fontWeight: 400,
            letterSpacing: "normal",
            orphans: "auto",
            textAlign: "start",
            textTransform: "none",
            whiteSpace: "normal",
            widows: "auto",
            wordSpacing: 0,
            WebkitTextStrokeWidth: 0,
            textDecoration: "none",
          }}
        >
          <span style={{ lineHeight: "14.4px" }}>
            (i)<span className="Apple-converted-space">&nbsp;</span>
          </span>
          <span style={{ lineHeight: "14.4px" }}>
            credit card or other payment information;
          </span>
        </div>
        <div
          className="s10"
          style={{
            marginTop: 0,
            marginBottom: 0,
            textIndent: "-42px",
            marginLeft: 85,
            caretColor: "rgb(0, 0, 0)",
            color: "rgb(0, 0, 0)",
            fontFamily: "-webkit-standard",
            fontSize: 16,
            fontStyle: "normal",
            fontVariantCaps: "normal",
            fontWeight: 400,
            letterSpacing: "normal",
            orphans: "auto",
            textAlign: "start",
            textTransform: "none",
            whiteSpace: "normal",
            widows: "auto",
            wordSpacing: 0,
            WebkitTextStrokeWidth: 0,
            textDecoration: "none",
          }}
        >
          <span style={{ lineHeight: "14.4px" }}>
            (j)<span className="Apple-converted-space">&nbsp;</span>
          </span>
          <span style={{ lineHeight: "14.4px" }}>
            financial information such as bank details;
          </span>
        </div>
        <div
          className="s10"
          style={{
            marginTop: 0,
            marginBottom: 0,
            textIndent: "-42px",
            marginLeft: 85,
            caretColor: "rgb(0, 0, 0)",
            color: "rgb(0, 0, 0)",
            fontFamily: "-webkit-standard",
            fontSize: 16,
            fontStyle: "normal",
            fontVariantCaps: "normal",
            fontWeight: 400,
            letterSpacing: "normal",
            orphans: "auto",
            textAlign: "start",
            textTransform: "none",
            whiteSpace: "normal",
            widows: "auto",
            wordSpacing: 0,
            WebkitTextStrokeWidth: 0,
            textDecoration: "none",
          }}
        >
          <span style={{ lineHeight: "14.4px" }}>
            (k)<span className="Apple-converted-space">&nbsp;</span>
          </span>
          <span style={{ lineHeight: "14.4px" }}>
            sensitive information as set out below;
          </span>
        </div>
        <div
          className="s10"
          style={{
            marginTop: 0,
            marginBottom: 0,
            textIndent: "-42px",
            marginLeft: 85,
            caretColor: "rgb(0, 0, 0)",
            color: "rgb(0, 0, 0)",
            fontFamily: "-webkit-standard",
            fontSize: 16,
            fontStyle: "normal",
            fontVariantCaps: "normal",
            fontWeight: 400,
            letterSpacing: "normal",
            orphans: "auto",
            textAlign: "start",
            textTransform: "none",
            whiteSpace: "normal",
            widows: "auto",
            wordSpacing: 0,
            WebkitTextStrokeWidth: 0,
            textDecoration: "none",
          }}
        >
          <span style={{ lineHeight: "14.4px" }}>
            (l)<span className="Apple-converted-space">&nbsp;</span>
          </span>
          <span style={{ lineHeight: "14.4px" }}>
            information about your business or personal circumstances;
          </span>
        </div>
        <div
          className="s10"
          style={{
            marginTop: 0,
            marginBottom: 0,
            textIndent: "-42px",
            marginLeft: 85,
            caretColor: "rgb(0, 0, 0)",
            color: "rgb(0, 0, 0)",
            fontFamily: "-webkit-standard",
            fontSize: 16,
            fontStyle: "normal",
            fontVariantCaps: "normal",
            fontWeight: 400,
            letterSpacing: "normal",
            orphans: "auto",
            textAlign: "start",
            textTransform: "none",
            whiteSpace: "normal",
            widows: "auto",
            wordSpacing: 0,
            WebkitTextStrokeWidth: 0,
            textDecoration: "none",
          }}
        >
          <span style={{ lineHeight: "14.4px" }}>
            (m)<span className="Apple-converted-space">&nbsp;</span>
          </span>
          <span style={{ lineHeight: "14.4px" }}>
            information in connection with client surveys,
            <span className="Apple-converted-space">&nbsp;</span>
          </span>
          <span style={{ lineHeight: "14.4px" }}>questionnaires</span>
          <span style={{ lineHeight: "14.4px" }}>
            <span className="Apple-converted-space">&nbsp;</span>and promotions;
          </span>
        </div>
        <div
          className="s10"
          style={{
            marginTop: 0,
            marginBottom: 0,
            textIndent: "-42px",
            marginLeft: 85,
            caretColor: "rgb(0, 0, 0)",
            color: "rgb(0, 0, 0)",
            fontFamily: "-webkit-standard",
            fontSize: 16,
            fontStyle: "normal",
            fontVariantCaps: "normal",
            fontWeight: 400,
            letterSpacing: "normal",
            orphans: "auto",
            textAlign: "start",
            textTransform: "none",
            whiteSpace: "normal",
            widows: "auto",
            wordSpacing: 0,
            WebkitTextStrokeWidth: 0,
            textDecoration: "none",
          }}
        >
          <span style={{ lineHeight: "14.4px" }}>
            (n)<span className="Apple-converted-space">&nbsp;</span>
          </span>
          <span style={{ lineHeight: "14.4px" }}>
            your device identity and type, I.P. address, geo-location
            information, page view statistics, advertising data and standard web
            log information;
            <span className="Apple-converted-space">&nbsp;</span>
          </span>
        </div>
        <div
          className="s10"
          style={{
            marginTop: 0,
            marginBottom: 0,
            textIndent: "-42px",
            marginLeft: 85,
            caretColor: "rgb(0, 0, 0)",
            color: "rgb(0, 0, 0)",
            fontFamily: "-webkit-standard",
            fontSize: 16,
            fontStyle: "normal",
            fontVariantCaps: "normal",
            fontWeight: 400,
            letterSpacing: "normal",
            orphans: "auto",
            textAlign: "start",
            textTransform: "none",
            whiteSpace: "normal",
            widows: "auto",
            wordSpacing: 0,
            WebkitTextStrokeWidth: 0,
            textDecoration: "none",
          }}
        >
          <span style={{ lineHeight: "14.4px" }}>
            (o)<span className="Apple-converted-space">&nbsp;</span>
          </span>
          <span style={{ lineHeight: "14.4px" }}>
            information about third parties; and
          </span>
        </div>
        <div
          className="s10"
          style={{
            marginTop: 0,
            marginBottom: 0,
            textIndent: "-42px",
            marginLeft: 85,
            caretColor: "rgb(0, 0, 0)",
            color: "rgb(0, 0, 0)",
            fontFamily: "-webkit-standard",
            fontSize: 16,
            fontStyle: "normal",
            fontVariantCaps: "normal",
            fontWeight: 400,
            letterSpacing: "normal",
            orphans: "auto",
            textAlign: "start",
            textTransform: "none",
            whiteSpace: "normal",
            widows: "auto",
            wordSpacing: 0,
            WebkitTextStrokeWidth: 0,
            textDecoration: "none",
          }}
        >
          <span style={{ lineHeight: "14.4px" }}>
            (p)<span className="Apple-converted-space">&nbsp;</span>
          </span>
          <span style={{ lineHeight: "14.4px" }}>
            any other information provided by you to us via our website or our
            online presence, or otherwise required by us or provided by you.
          </span>
        </div>
        <div
          className="s7"
          style={{
            marginTop: 18,
            marginBottom: 0,
            textIndent: "-42px",
            marginLeft: 42,
            caretColor: "rgb(0, 0, 0)",
            color: "rgb(0, 0, 0)",
            fontFamily: "-webkit-standard",
            fontSize: 16,
            fontStyle: "normal",
            fontVariantCaps: "normal",
            fontWeight: 400,
            letterSpacing: "normal",
            orphans: "auto",
            textAlign: "start",
            textTransform: "none",
            whiteSpace: "normal",
            widows: "auto",
            wordSpacing: 0,
            WebkitTextStrokeWidth: 0,
            textDecoration: "none",
          }}
        >
          <span
            className="s5"
            style={{
              lineHeight: "14.4px",
              textTransform: "uppercase",
              fontWeight: "bold",
            }}
          >
            3<span className="Apple-converted-space">&nbsp;</span>
          </span>
          <a name="_Toc8723800" />
          <span
            className="s5"
            style={{
              lineHeight: "14.4px",
              textTransform: "uppercase",
              fontWeight: "bold",
            }}
          >
            HOW WE COLLECT PERSONAL INFORMATION
          </span>
        </div>
        <div
          className="s9"
          style={{
            marginTop: 0,
            marginBottom: 0,
            textIndent: 0,
            marginLeft: 42,
            caretColor: "rgb(0, 0, 0)",
            color: "rgb(0, 0, 0)",
            fontFamily: "-webkit-standard",
            fontSize: 16,
            fontStyle: "normal",
            fontVariantCaps: "normal",
            fontWeight: 400,
            letterSpacing: "normal",
            orphans: "auto",
            textAlign: "start",
            textTransform: "none",
            whiteSpace: "normal",
            widows: "auto",
            wordSpacing: 0,
            WebkitTextStrokeWidth: 0,
            textDecoration: "none",
          }}
        >
          <span style={{ lineHeight: "14.4px" }}>
            We may collect personal information either directly from you, or
            from third parties, including where you:
            <span className="Apple-converted-space">&nbsp;</span>
          </span>
        </div>
        <div
          className="s10"
          style={{
            marginTop: 0,
            marginBottom: 0,
            textIndent: "-42px",
            marginLeft: 85,
            caretColor: "rgb(0, 0, 0)",
            color: "rgb(0, 0, 0)",
            fontFamily: "-webkit-standard",
            fontSize: 16,
            fontStyle: "normal",
            fontVariantCaps: "normal",
            fontWeight: 400,
            letterSpacing: "normal",
            orphans: "auto",
            textAlign: "start",
            textTransform: "none",
            whiteSpace: "normal",
            widows: "auto",
            wordSpacing: 0,
            WebkitTextStrokeWidth: 0,
            textDecoration: "none",
          }}
        >
          <span style={{ lineHeight: "14.4px" }}>
            (a)<span className="Apple-converted-space">&nbsp;</span>
          </span>
          <span style={{ lineHeight: "14.4px" }}>
            contact us through our website;
          </span>
        </div>
        <div
          className="s10"
          style={{
            marginTop: 0,
            marginBottom: 0,
            textIndent: "-42px",
            marginLeft: 85,
            caretColor: "rgb(0, 0, 0)",
            color: "rgb(0, 0, 0)",
            fontFamily: "-webkit-standard",
            fontSize: 16,
            fontStyle: "normal",
            fontVariantCaps: "normal",
            fontWeight: 400,
            letterSpacing: "normal",
            orphans: "auto",
            textAlign: "start",
            textTransform: "none",
            whiteSpace: "normal",
            widows: "auto",
            wordSpacing: 0,
            WebkitTextStrokeWidth: 0,
            textDecoration: "none",
          }}
        >
          <span style={{ lineHeight: "14.4px" }}>
            (b)<span className="Apple-converted-space">&nbsp;</span>
          </span>
          <span style={{ lineHeight: "14.4px" }}>
            receive goods or services from us;
          </span>
        </div>
        <div
          className="s10"
          style={{
            marginTop: 0,
            marginBottom: 0,
            textIndent: "-42px",
            marginLeft: 85,
            caretColor: "rgb(0, 0, 0)",
            color: "rgb(0, 0, 0)",
            fontFamily: "-webkit-standard",
            fontSize: 16,
            fontStyle: "normal",
            fontVariantCaps: "normal",
            fontWeight: 400,
            letterSpacing: "normal",
            orphans: "auto",
            textAlign: "start",
            textTransform: "none",
            whiteSpace: "normal",
            widows: "auto",
            wordSpacing: 0,
            WebkitTextStrokeWidth: 0,
            textDecoration: "none",
          }}
        >
          <span style={{ lineHeight: "14.4px" }}>
            (c)<span className="Apple-converted-space">&nbsp;</span>
          </span>
          <span style={{ lineHeight: "14.4px" }}>
            submit any of our online
            <span className="Apple-converted-space">&nbsp;</span>
          </span>
          <span style={{ lineHeight: "14.4px" }}>sign up</span>
          <span style={{ lineHeight: "14.4px" }}>
            <span className="Apple-converted-space">&nbsp;</span>forms;
          </span>
        </div>
        <div
          className="s10"
          style={{
            marginTop: 0,
            marginBottom: 0,
            textIndent: "-42px",
            marginLeft: 85,
            caretColor: "rgb(0, 0, 0)",
            color: "rgb(0, 0, 0)",
            fontFamily: "-webkit-standard",
            fontSize: 16,
            fontStyle: "normal",
            fontVariantCaps: "normal",
            fontWeight: 400,
            letterSpacing: "normal",
            orphans: "auto",
            textAlign: "start",
            textTransform: "none",
            whiteSpace: "normal",
            widows: "auto",
            wordSpacing: 0,
            WebkitTextStrokeWidth: 0,
            textDecoration: "none",
          }}
        >
          <span style={{ lineHeight: "14.4px" }}>
            (d)<span className="Apple-converted-space">&nbsp;</span>
          </span>
          <span style={{ lineHeight: "14.4px" }}>
            communicate with us via email, telephone, SMS, social applications
            (such as LinkedIn
          </span>
          <span style={{ lineHeight: "14.4px" }}>or</span>
          <span style={{ lineHeight: "14.4px" }}>
            <span className="Apple-converted-space">&nbsp;</span>Facebook) or
            otherwise;
          </span>
        </div>
        <div
          className="s10"
          style={{
            marginTop: 0,
            marginBottom: 0,
            textIndent: "-42px",
            marginLeft: 85,
            caretColor: "rgb(0, 0, 0)",
            color: "rgb(0, 0, 0)",
            fontFamily: "-webkit-standard",
            fontSize: 16,
            fontStyle: "normal",
            fontVariantCaps: "normal",
            fontWeight: 400,
            letterSpacing: "normal",
            orphans: "auto",
            textAlign: "start",
            textTransform: "none",
            whiteSpace: "normal",
            widows: "auto",
            wordSpacing: 0,
            WebkitTextStrokeWidth: 0,
            textDecoration: "none",
          }}
        >
          <span style={{ lineHeight: "14.4px" }}>
            (e)<span className="Apple-converted-space">&nbsp;</span>
          </span>
          <span style={{ lineHeight: "14.4px" }}>
            interact with our website, social applications, services,
            <span className="Apple-converted-space">&nbsp;</span>
          </span>
          <span style={{ lineHeight: "14.4px" }}>content</span>
          <span style={{ lineHeight: "14.4px" }}>
            <span className="Apple-converted-space">&nbsp;</span>and
            advertising; and
          </span>
        </div>
        <div
          className="s10"
          style={{
            marginTop: 0,
            marginBottom: 0,
            textIndent: "-42px",
            marginLeft: 85,
            caretColor: "rgb(0, 0, 0)",
            color: "rgb(0, 0, 0)",
            fontFamily: "-webkit-standard",
            fontSize: 16,
            fontStyle: "normal",
            fontVariantCaps: "normal",
            fontWeight: 400,
            letterSpacing: "normal",
            orphans: "auto",
            textAlign: "start",
            textTransform: "none",
            whiteSpace: "normal",
            widows: "auto",
            wordSpacing: 0,
            WebkitTextStrokeWidth: 0,
            textDecoration: "none",
          }}
        >
          <span style={{ lineHeight: "14.4px" }}>
            (f)<span className="Apple-converted-space">&nbsp;</span>
          </span>
          <span style={{ lineHeight: "14.4px" }}>
            invest in our business or enquire as to a potential purchase in our
            business.
          </span>
        </div>
        <div
          className="s9"
          style={{
            marginTop: 0,
            marginBottom: 0,
            textIndent: 0,
            marginLeft: 42,
            caretColor: "rgb(0, 0, 0)",
            color: "rgb(0, 0, 0)",
            fontFamily: "-webkit-standard",
            fontSize: 16,
            fontStyle: "normal",
            fontVariantCaps: "normal",
            fontWeight: 400,
            letterSpacing: "normal",
            orphans: "auto",
            textAlign: "start",
            textTransform: "none",
            whiteSpace: "normal",
            widows: "auto",
            wordSpacing: 0,
            WebkitTextStrokeWidth: 0,
            textDecoration: "none",
          }}
        >
          <span style={{ lineHeight: "14.4px" }}>
            We may also collect personal information from you when you use or
            access our website or our social media pages. This may be done
            through use of web analytics tools,
            <span className="Apple-converted-space">&nbsp;</span>
          </span>
          <span style={{ lineHeight: "14.4px" }}>‘</span>
          <span style={{ lineHeight: "14.4px" }}>cookies</span>
          <span style={{ lineHeight: "14.4px" }}>’</span>
          <span style={{ lineHeight: "14.4px" }}>
            <span className="Apple-converted-space">&nbsp;</span>or other
            similar tracking technologies that allow us to track and analyse
            your website usage. Cookies are small files that store information
            on your computer, mobile phone or other device and enable and allow
            the creator of the cookie to identify when you visit different
            websites. If you do not wish information to be stored as a cookie,
            you can disable cookies in your web browser.
          </span>
        </div>
        <div
          className="s9"
          style={{
            marginTop: 0,
            marginBottom: 0,
            textIndent: 0,
            marginLeft: 42,
            caretColor: "rgb(0, 0, 0)",
            color: "rgb(0, 0, 0)",
            fontFamily: "-webkit-standard",
            fontSize: 16,
            fontStyle: "normal",
            fontVariantCaps: "normal",
            fontWeight: 400,
            letterSpacing: "normal",
            orphans: "auto",
            textAlign: "start",
            textTransform: "none",
            whiteSpace: "normal",
            widows: "auto",
            wordSpacing: 0,
            WebkitTextStrokeWidth: 0,
            textDecoration: "none",
          }}
        >
          <span style={{ lineHeight: "14.4px" }}>
            We may use Google Analytics to collect and process data, including
            when you use third party websites or apps. To find out more see
            <span className="Apple-converted-space">&nbsp;</span>
          </span>
          <a href="http://www.google.com/policies/privacy/partners/">
            <span
              className="s11"
              style={{
                lineHeight: "14.4px",
                textDecoration: "underline",
                color: "rgb(23, 192, 185)",
              }}
            >
              How Google uses data when you use our partners
            </span>
            <span
              className="s11"
              style={{
                lineHeight: "14.4px",
                textDecoration: "underline",
                color: "rgb(23, 192, 185)",
              }}
            >
              ’
            </span>
            <span
              className="s11"
              style={{
                lineHeight: "14.4px",
                textDecoration: "underline",
                color: "rgb(23, 192, 185)",
              }}
            >
              <span className="Apple-converted-space">&nbsp;</span>sites or apps
            </span>
          </a>
          <span style={{ lineHeight: "14.4px" }}>.</span>
        </div>
        <div
          className="s7"
          style={{
            marginTop: 18,
            marginBottom: 0,
            textIndent: "-42px",
            marginLeft: 42,
            caretColor: "rgb(0, 0, 0)",
            color: "rgb(0, 0, 0)",
            fontFamily: "-webkit-standard",
            fontSize: 16,
            fontStyle: "normal",
            fontVariantCaps: "normal",
            fontWeight: 400,
            letterSpacing: "normal",
            orphans: "auto",
            textAlign: "start",
            textTransform: "none",
            whiteSpace: "normal",
            widows: "auto",
            wordSpacing: 0,
            WebkitTextStrokeWidth: 0,
            textDecoration: "none",
          }}
        >
          <span
            className="s5"
            style={{
              lineHeight: "14.4px",
              textTransform: "uppercase",
              fontWeight: "bold",
            }}
          >
            4<span className="Apple-converted-space">&nbsp;</span>
          </span>
          <a name="_Toc8723801" />
          <span
            className="s5"
            style={{
              lineHeight: "14.4px",
              textTransform: "uppercase",
              fontWeight: "bold",
            }}
          >
            USE OF YOUR PERSONAL INFORMATION
          </span>
        </div>
        <div
          className="s9"
          style={{
            marginTop: 0,
            marginBottom: 0,
            textIndent: 0,
            marginLeft: 42,
            caretColor: "rgb(0, 0, 0)",
            color: "rgb(0, 0, 0)",
            fontFamily: "-webkit-standard",
            fontSize: 16,
            fontStyle: "normal",
            fontVariantCaps: "normal",
            fontWeight: 400,
            letterSpacing: "normal",
            orphans: "auto",
            textAlign: "start",
            textTransform: "none",
            whiteSpace: "normal",
            widows: "auto",
            wordSpacing: 0,
            WebkitTextStrokeWidth: 0,
            textDecoration: "none",
          }}
        >
          <span style={{ lineHeight: "14.4px" }}>
            We collect and use personal information for the following purposes:
          </span>
        </div>
        <div
          className="s10"
          style={{
            marginTop: 0,
            marginBottom: 0,
            textIndent: "-42px",
            marginLeft: 85,
            caretColor: "rgb(0, 0, 0)",
            color: "rgb(0, 0, 0)",
            fontFamily: "-webkit-standard",
            fontSize: 16,
            fontStyle: "normal",
            fontVariantCaps: "normal",
            fontWeight: 400,
            letterSpacing: "normal",
            orphans: "auto",
            textAlign: "start",
            textTransform: "none",
            whiteSpace: "normal",
            widows: "auto",
            wordSpacing: 0,
            WebkitTextStrokeWidth: 0,
            textDecoration: "none",
          }}
        >
          <span style={{ lineHeight: "14.4px" }}>
            (a)<span className="Apple-converted-space">&nbsp;</span>
          </span>
          <span style={{ lineHeight: "14.4px" }}>
            to provide goods,
            <span className="Apple-converted-space">&nbsp;</span>
          </span>
          <span style={{ lineHeight: "14.4px" }}>services</span>
          <span style={{ lineHeight: "14.4px" }}>
            <span className="Apple-converted-space">&nbsp;</span>or information
            to you;
          </span>
        </div>
        <div
          className="s10"
          style={{
            marginTop: 0,
            marginBottom: 0,
            textIndent: "-42px",
            marginLeft: 85,
            caretColor: "rgb(0, 0, 0)",
            color: "rgb(0, 0, 0)",
            fontFamily: "-webkit-standard",
            fontSize: 16,
            fontStyle: "normal",
            fontVariantCaps: "normal",
            fontWeight: 400,
            letterSpacing: "normal",
            orphans: "auto",
            textAlign: "start",
            textTransform: "none",
            whiteSpace: "normal",
            widows: "auto",
            wordSpacing: 0,
            WebkitTextStrokeWidth: 0,
            textDecoration: "none",
          }}
        >
          <span style={{ lineHeight: "14.4px" }}>
            (b)<span className="Apple-converted-space">&nbsp;</span>
          </span>
          <span style={{ lineHeight: "14.4px" }}>
            for record keeping and administrative purposes;
          </span>
        </div>
        <div
          className="s10"
          style={{
            marginTop: 0,
            marginBottom: 0,
            textIndent: "-42px",
            marginLeft: 85,
            caretColor: "rgb(0, 0, 0)",
            color: "rgb(0, 0, 0)",
            fontFamily: "-webkit-standard",
            fontSize: 16,
            fontStyle: "normal",
            fontVariantCaps: "normal",
            fontWeight: 400,
            letterSpacing: "normal",
            orphans: "auto",
            textAlign: "start",
            textTransform: "none",
            whiteSpace: "normal",
            widows: "auto",
            wordSpacing: 0,
            WebkitTextStrokeWidth: 0,
            textDecoration: "none",
          }}
        >
          <span style={{ lineHeight: "14.4px" }}>
            (c)<span className="Apple-converted-space">&nbsp;</span>
          </span>
          <span style={{ lineHeight: "14.4px" }}>to verify your identity;</span>
        </div>
        <div
          className="s10"
          style={{
            marginTop: 0,
            marginBottom: 0,
            textIndent: "-42px",
            marginLeft: 85,
            caretColor: "rgb(0, 0, 0)",
            color: "rgb(0, 0, 0)",
            fontFamily: "-webkit-standard",
            fontSize: 16,
            fontStyle: "normal",
            fontVariantCaps: "normal",
            fontWeight: 400,
            letterSpacing: "normal",
            orphans: "auto",
            textAlign: "start",
            textTransform: "none",
            whiteSpace: "normal",
            widows: "auto",
            wordSpacing: 0,
            WebkitTextStrokeWidth: 0,
            textDecoration: "none",
          }}
        >
          <span style={{ lineHeight: "14.4px" }}>
            (d)<span className="Apple-converted-space">&nbsp;</span>
          </span>
          <span style={{ lineHeight: "14.4px" }}>
            to provide information about you to our contractors, employees,
            consultants, agents or other third parties for the purpose of
            providing goods or services to you;
          </span>
        </div>
        <div
          className="s10"
          style={{
            marginTop: 0,
            marginBottom: 0,
            textIndent: "-42px",
            marginLeft: 85,
            caretColor: "rgb(0, 0, 0)",
            color: "rgb(0, 0, 0)",
            fontFamily: "-webkit-standard",
            fontSize: 16,
            fontStyle: "normal",
            fontVariantCaps: "normal",
            fontWeight: 400,
            letterSpacing: "normal",
            orphans: "auto",
            textAlign: "start",
            textTransform: "none",
            whiteSpace: "normal",
            widows: "auto",
            wordSpacing: 0,
            WebkitTextStrokeWidth: 0,
            textDecoration: "none",
          }}
        >
          <span style={{ lineHeight: "14.4px" }}>
            (e)<span className="Apple-converted-space">&nbsp;</span>
          </span>
          <span style={{ lineHeight: "14.4px" }}>
            to improve and optimise our service offering and customer
            experience;
          </span>
        </div>
        <div
          className="s10"
          style={{
            marginTop: 0,
            marginBottom: 0,
            textIndent: "-42px",
            marginLeft: 85,
            caretColor: "rgb(0, 0, 0)",
            color: "rgb(0, 0, 0)",
            fontFamily: "-webkit-standard",
            fontSize: 16,
            fontStyle: "normal",
            fontVariantCaps: "normal",
            fontWeight: 400,
            letterSpacing: "normal",
            orphans: "auto",
            textAlign: "start",
            textTransform: "none",
            whiteSpace: "normal",
            widows: "auto",
            wordSpacing: 0,
            WebkitTextStrokeWidth: 0,
            textDecoration: "none",
          }}
        >
          <span style={{ lineHeight: "14.4px" }}>
            (f)<span className="Apple-converted-space">&nbsp;</span>
          </span>
          <span style={{ lineHeight: "14.4px" }}>
            to comply with our legal obligations, resolve disputes or enforce
            our agreements with third parties;
          </span>
        </div>
        <div
          className="s10"
          style={{
            marginTop: 0,
            marginBottom: 0,
            textIndent: "-42px",
            marginLeft: 85,
            caretColor: "rgb(0, 0, 0)",
            color: "rgb(0, 0, 0)",
            fontFamily: "-webkit-standard",
            fontSize: 16,
            fontStyle: "normal",
            fontVariantCaps: "normal",
            fontWeight: 400,
            letterSpacing: "normal",
            orphans: "auto",
            textAlign: "start",
            textTransform: "none",
            whiteSpace: "normal",
            widows: "auto",
            wordSpacing: 0,
            WebkitTextStrokeWidth: 0,
            textDecoration: "none",
          }}
        >
          <span style={{ lineHeight: "14.4px" }}>
            (g)<span className="Apple-converted-space">&nbsp;</span>
          </span>
          <span style={{ lineHeight: "14.4px" }}>
            to send you marketing and promotional messages and other information
            that may be of interest to you and for the purpose of direct
            marketing (in accordance with the Spam Act). In this regard, we may
            use email, SMS, social
            <span className="Apple-converted-space">&nbsp;</span>
          </span>
          <span style={{ lineHeight: "14.4px" }}>media</span>
          <span style={{ lineHeight: "14.4px" }}>
            <span className="Apple-converted-space">&nbsp;</span>or mail to send
            you direct marketing communications. You can opt out of receiving
            marketing materials from us by using the opt-out facility provided
            (e.g. an unsubscribe link);
          </span>
        </div>
        <div
          className="s10"
          style={{
            marginTop: 0,
            marginBottom: 0,
            textIndent: "-42px",
            marginLeft: 85,
            caretColor: "rgb(0, 0, 0)",
            color: "rgb(0, 0, 0)",
            fontFamily: "-webkit-standard",
            fontSize: 16,
            fontStyle: "normal",
            fontVariantCaps: "normal",
            fontWeight: 400,
            letterSpacing: "normal",
            orphans: "auto",
            textAlign: "start",
            textTransform: "none",
            whiteSpace: "normal",
            widows: "auto",
            wordSpacing: 0,
            WebkitTextStrokeWidth: 0,
            textDecoration: "none",
          }}
        >
          <span style={{ lineHeight: "14.4px" }}>
            (h)<span className="Apple-converted-space">&nbsp;</span>
          </span>
          <span style={{ lineHeight: "14.4px" }}>
            to send you administrative messages, reminders, notices, updates,
            security alerts, and other information requested by you; and
          </span>
        </div>
        <div
          className="s10"
          style={{
            marginTop: 0,
            marginBottom: 0,
            textIndent: "-42px",
            marginLeft: 85,
            caretColor: "rgb(0, 0, 0)",
            color: "rgb(0, 0, 0)",
            fontFamily: "-webkit-standard",
            fontSize: 16,
            fontStyle: "normal",
            fontVariantCaps: "normal",
            fontWeight: 400,
            letterSpacing: "normal",
            orphans: "auto",
            textAlign: "start",
            textTransform: "none",
            whiteSpace: "normal",
            widows: "auto",
            wordSpacing: 0,
            WebkitTextStrokeWidth: 0,
            textDecoration: "none",
          }}
        >
          <span style={{ lineHeight: "14.4px" }}>
            (i)<span className="Apple-converted-space">&nbsp;</span>
          </span>
          <span style={{ lineHeight: "14.4px" }}>
            to consider an application of employment from you.
          </span>
        </div>
        <div
          className="s9"
          style={{
            marginTop: 0,
            marginBottom: 0,
            textIndent: 0,
            marginLeft: 42,
            caretColor: "rgb(0, 0, 0)",
            color: "rgb(0, 0, 0)",
            fontFamily: "-webkit-standard",
            fontSize: 16,
            fontStyle: "normal",
            fontVariantCaps: "normal",
            fontWeight: 400,
            letterSpacing: "normal",
            orphans: "auto",
            textAlign: "start",
            textTransform: "none",
            whiteSpace: "normal",
            widows: "auto",
            wordSpacing: 0,
            WebkitTextStrokeWidth: 0,
            textDecoration: "none",
          }}
        >
          <span style={{ lineHeight: "14.4px" }}>
            We may disclose your personal information to cloud-providers,
            contractors and other third parties located inside or outside of
            Australia. If we do so, we will take reasonable steps to ensure that
            any overseas recipient deals with such personal information in a
            manner consistent with how we deal with it.
          </span>
        </div>
        <div
          className="s9"
          style={{
            marginTop: 0,
            marginBottom: 0,
            textIndent: 0,
            marginLeft: 42,
            caretColor: "rgb(0, 0, 0)",
            color: "rgb(0, 0, 0)",
            fontFamily: "-webkit-standard",
            fontSize: 16,
            fontStyle: "normal",
            fontVariantCaps: "normal",
            fontWeight: 400,
            letterSpacing: "normal",
            orphans: "auto",
            textAlign: "start",
            textTransform: "none",
            whiteSpace: "normal",
            widows: "auto",
            wordSpacing: 0,
            WebkitTextStrokeWidth: 0,
            textDecoration: "none",
          }}
        >
          <span style={{ lineHeight: "14.4px" }}>(</span>
          <span
            className="s8"
            style={{ lineHeight: "14.4px", fontWeight: "bold" }}
          >
            Sharing of your Information
          </span>
          <span style={{ lineHeight: "14.4px" }}>
            ) We may need to share your personal information with third parties,
            including verification services that we work with
            <span className="Apple-converted-space">&nbsp;</span>
          </span>
          <span style={{ lineHeight: "14.4px" }}>
            to<span className="Apple-converted-space">&nbsp;</span>
          </span>
          <span style={{ lineHeight: "14.4px" }}>
            provide you with our services. You consent to the sharing of your
            personal and sensitive information with these third parties as
            required to provide you with the services.
          </span>
        </div>
        <div
          className="s7"
          style={{
            marginTop: 18,
            marginBottom: 0,
            textIndent: "-42px",
            marginLeft: 42,
            caretColor: "rgb(0, 0, 0)",
            color: "rgb(0, 0, 0)",
            fontFamily: "-webkit-standard",
            fontSize: 16,
            fontStyle: "normal",
            fontVariantCaps: "normal",
            fontWeight: 400,
            letterSpacing: "normal",
            orphans: "auto",
            textAlign: "start",
            textTransform: "none",
            whiteSpace: "normal",
            widows: "auto",
            wordSpacing: 0,
            WebkitTextStrokeWidth: 0,
            textDecoration: "none",
          }}
        >
          <span
            className="s5"
            style={{
              lineHeight: "14.4px",
              textTransform: "uppercase",
              fontWeight: "bold",
            }}
          >
            5<span className="Apple-converted-space">&nbsp;</span>
          </span>
          <span
            className="s5"
            style={{
              lineHeight: "14.4px",
              textTransform: "uppercase",
              fontWeight: "bold",
            }}
          >
            SENSITIVE INFORMATION
          </span>
        </div>
        <div
          className="s9"
          style={{
            marginTop: 0,
            marginBottom: 0,
            textIndent: 0,
            marginLeft: 42,
            caretColor: "rgb(0, 0, 0)",
            color: "rgb(0, 0, 0)",
            fontFamily: "-webkit-standard",
            fontSize: 16,
            fontStyle: "normal",
            fontVariantCaps: "normal",
            fontWeight: 400,
            letterSpacing: "normal",
            orphans: "auto",
            textAlign: "start",
            textTransform: "none",
            whiteSpace: "normal",
            widows: "auto",
            wordSpacing: 0,
            WebkitTextStrokeWidth: 0,
            textDecoration: "none",
          }}
        >
          <a name="_Hlk58478757" />
          <span style={{ lineHeight: "14.4px" }}>
            We may collect sensitive information about you
            <span className="Apple-converted-space">&nbsp;</span>
          </span>
          <span style={{ lineHeight: "14.4px" }}>during the course of</span>
          <span style={{ lineHeight: "14.4px" }}>
            <span className="Apple-converted-space">&nbsp;</span>providing you
            services. The type of sensitive information we may collect includes
            criminal records.
            <span className="Apple-converted-space">&nbsp;</span>
          </span>
        </div>
        <div
          className="s9"
          style={{
            marginTop: 0,
            marginBottom: 0,
            textIndent: 0,
            marginLeft: 42,
            caretColor: "rgb(0, 0, 0)",
            color: "rgb(0, 0, 0)",
            fontFamily: "-webkit-standard",
            fontSize: 16,
            fontStyle: "normal",
            fontVariantCaps: "normal",
            fontWeight: 400,
            letterSpacing: "normal",
            orphans: "auto",
            textAlign: "start",
            textTransform: "none",
            whiteSpace: "normal",
            widows: "auto",
            wordSpacing: 0,
            WebkitTextStrokeWidth: 0,
            textDecoration: "none",
          }}
        >
          <span style={{ lineHeight: "14.4px" }}>
            We will only collect this sensitive information where you consent
            and provide us with this information. If you consent, your sensitive
            information may only be used and disclosed for purposes relating to
            providing you with our services and verifying your information with
            third party verification providers. &nbsp;
          </span>
        </div>
        <div
          className="s9"
          style={{
            marginTop: 0,
            marginBottom: 0,
            textIndent: 0,
            marginLeft: 42,
            caretColor: "rgb(0, 0, 0)",
            color: "rgb(0, 0, 0)",
            fontFamily: "-webkit-standard",
            fontSize: 16,
            fontStyle: "normal",
            fontVariantCaps: "normal",
            fontWeight: 400,
            letterSpacing: "normal",
            orphans: "auto",
            textAlign: "start",
            textTransform: "none",
            whiteSpace: "normal",
            widows: "auto",
            wordSpacing: 0,
            WebkitTextStrokeWidth: 0,
            textDecoration: "none",
          }}
        >
          <span style={{ lineHeight: "14.4px" }}>
            Your sensitive information may also be used or disclosed to comply
            with our legal obligations.
          </span>
        </div>
        <div
          className="s7"
          style={{
            marginTop: 18,
            marginBottom: 0,
            textIndent: "-42px",
            marginLeft: 42,
            caretColor: "rgb(0, 0, 0)",
            color: "rgb(0, 0, 0)",
            fontFamily: "-webkit-standard",
            fontSize: 16,
            fontStyle: "normal",
            fontVariantCaps: "normal",
            fontWeight: 400,
            letterSpacing: "normal",
            orphans: "auto",
            textAlign: "start",
            textTransform: "none",
            whiteSpace: "normal",
            widows: "auto",
            wordSpacing: 0,
            WebkitTextStrokeWidth: 0,
            textDecoration: "none",
          }}
        >
          <span
            className="s5"
            style={{
              lineHeight: "14.4px",
              textTransform: "uppercase",
              fontWeight: "bold",
            }}
          >
            6<span className="Apple-converted-space">&nbsp;</span>
          </span>
          <a name="_Toc8723802" />
          <span
            className="s5"
            style={{
              lineHeight: "14.4px",
              textTransform: "uppercase",
              fontWeight: "bold",
            }}
          >
            SECURITY
          </span>
        </div>
        <div
          className="s9"
          style={{
            marginTop: 0,
            marginBottom: 0,
            textIndent: 0,
            marginLeft: 42,
            caretColor: "rgb(0, 0, 0)",
            color: "rgb(0, 0, 0)",
            fontFamily: "-webkit-standard",
            fontSize: 16,
            fontStyle: "normal",
            fontVariantCaps: "normal",
            fontWeight: 400,
            letterSpacing: "normal",
            orphans: "auto",
            textAlign: "start",
            textTransform: "none",
            whiteSpace: "normal",
            widows: "auto",
            wordSpacing: 0,
            WebkitTextStrokeWidth: 0,
            textDecoration: "none",
          }}
        >
          <span style={{ lineHeight: "14.4px" }}>
            We take reasonable steps to ensure your personal information is
            secure and protected from misuse or unauthorised access. Our
            information technology systems are password protected, and we use a
            range of administrative and technical measures to protect these
            systems. However, we cannot guarantee the security of your personal
            information.
          </span>
        </div>
        <div
          className="s7"
          style={{
            marginTop: 18,
            marginBottom: 0,
            textIndent: "-42px",
            marginLeft: 42,
            caretColor: "rgb(0, 0, 0)",
            color: "rgb(0, 0, 0)",
            fontFamily: "-webkit-standard",
            fontSize: 16,
            fontStyle: "normal",
            fontVariantCaps: "normal",
            fontWeight: 400,
            letterSpacing: "normal",
            orphans: "auto",
            textAlign: "start",
            textTransform: "none",
            whiteSpace: "normal",
            widows: "auto",
            wordSpacing: 0,
            WebkitTextStrokeWidth: 0,
            textDecoration: "none",
          }}
        >
          <span
            className="s5"
            style={{
              lineHeight: "14.4px",
              textTransform: "uppercase",
              fontWeight: "bold",
            }}
          >
            7<span className="Apple-converted-space">&nbsp;</span>
          </span>
          <a name="_Toc8723803" />
          <span
            className="s5"
            style={{
              lineHeight: "14.4px",
              textTransform: "uppercase",
              fontWeight: "bold",
            }}
          >
            LINKS
          </span>
        </div>
        <div
          className="s9"
          style={{
            marginTop: 0,
            marginBottom: 0,
            textIndent: 0,
            marginLeft: 42,
            caretColor: "rgb(0, 0, 0)",
            color: "rgb(0, 0, 0)",
            fontFamily: "-webkit-standard",
            fontSize: 16,
            fontStyle: "normal",
            fontVariantCaps: "normal",
            fontWeight: 400,
            letterSpacing: "normal",
            orphans: "auto",
            textAlign: "start",
            textTransform: "none",
            whiteSpace: "normal",
            widows: "auto",
            wordSpacing: 0,
            WebkitTextStrokeWidth: 0,
            textDecoration: "none",
          }}
        >
          <span style={{ lineHeight: "14.4px" }}>
            Our website may contain links to other websites. Those links are
            provided for convenience and may not remain current or be
            maintained. We are not responsible for the privacy practices of
            those linked<span className="Apple-converted-space">&nbsp;</span>
          </span>
          <span style={{ lineHeight: "14.4px" }}>websites</span>
          <span style={{ lineHeight: "14.4px" }}>
            <span className="Apple-converted-space">&nbsp;</span>and we suggest
            you review the privacy policies of those websites before using them.
          </span>
        </div>
        <div
          className="s7"
          style={{
            marginTop: 18,
            marginBottom: 0,
            textIndent: "-42px",
            marginLeft: 42,
            caretColor: "rgb(0, 0, 0)",
            color: "rgb(0, 0, 0)",
            fontFamily: "-webkit-standard",
            fontSize: 16,
            fontStyle: "normal",
            fontVariantCaps: "normal",
            fontWeight: 400,
            letterSpacing: "normal",
            orphans: "auto",
            textAlign: "start",
            textTransform: "none",
            whiteSpace: "normal",
            widows: "auto",
            wordSpacing: 0,
            WebkitTextStrokeWidth: 0,
            textDecoration: "none",
          }}
        >
          <span
            className="s5"
            style={{
              lineHeight: "14.4px",
              textTransform: "uppercase",
              fontWeight: "bold",
            }}
          >
            8<span className="Apple-converted-space">&nbsp;</span>
          </span>
          <a name="_Toc8723804" />
          <span
            className="s5"
            style={{
              lineHeight: "14.4px",
              textTransform: "uppercase",
              fontWeight: "bold",
            }}
          >
            REQUESTING ACCESS OR CORRECTING YOUR PERSONAL INFORMATION
          </span>
        </div>
        <div
          className="s9"
          style={{
            marginTop: 0,
            marginBottom: 0,
            textIndent: 0,
            marginLeft: 42,
            caretColor: "rgb(0, 0, 0)",
            color: "rgb(0, 0, 0)",
            fontFamily: "-webkit-standard",
            fontSize: 16,
            fontStyle: "normal",
            fontVariantCaps: "normal",
            fontWeight: 400,
            letterSpacing: "normal",
            orphans: "auto",
            textAlign: "start",
            textTransform: "none",
            whiteSpace: "normal",
            widows: "auto",
            wordSpacing: 0,
            WebkitTextStrokeWidth: 0,
            textDecoration: "none",
          }}
        >
          <span style={{ lineHeight: "14.4px" }}>
            If you wish to request access to the personal information we hold
            about you, please contact us using the contact details set out below
            including your name and contact details. We may need to verify your
            identity before providing you with your personal information. In
            some cases, we may be unable to provide you with access to all your
            personal information and where this occurs, we will explain why. We
            will deal with all requests for access to personal information
            within a reasonable timeframe.
          </span>
        </div>
        <div
          className="s9"
          style={{
            marginTop: 0,
            marginBottom: 0,
            textIndent: 0,
            marginLeft: 42,
            caretColor: "rgb(0, 0, 0)",
            color: "rgb(0, 0, 0)",
            fontFamily: "-webkit-standard",
            fontSize: 16,
            fontStyle: "normal",
            fontVariantCaps: "normal",
            fontWeight: 400,
            letterSpacing: "normal",
            orphans: "auto",
            textAlign: "start",
            textTransform: "none",
            whiteSpace: "normal",
            widows: "auto",
            wordSpacing: 0,
            WebkitTextStrokeWidth: 0,
            textDecoration: "none",
          }}
        >
          <span style={{ lineHeight: "14.4px" }}>
            If you think that any personal
            <span className="Apple-converted-space">&nbsp;</span>
          </span>
          <span style={{ lineHeight: "14.4px" }}>information</span>
          <span style={{ lineHeight: "14.4px" }}>
            <span className="Apple-converted-space">&nbsp;</span>we hold about
            you is inaccurate, please contact us using the contact details set
            out below and we will take reasonable steps to ensure that it is
            corrected.
          </span>
        </div>
        <div
          className="s7"
          style={{
            marginTop: 18,
            marginBottom: 0,
            textIndent: "-42px",
            marginLeft: 42,
            caretColor: "rgb(0, 0, 0)",
            color: "rgb(0, 0, 0)",
            fontFamily: "-webkit-standard",
            fontSize: 16,
            fontStyle: "normal",
            fontVariantCaps: "normal",
            fontWeight: 400,
            letterSpacing: "normal",
            orphans: "auto",
            textAlign: "start",
            textTransform: "none",
            whiteSpace: "normal",
            widows: "auto",
            wordSpacing: 0,
            WebkitTextStrokeWidth: 0,
            textDecoration: "none",
          }}
        >
          <span
            className="s5"
            style={{
              lineHeight: "14.4px",
              textTransform: "uppercase",
              fontWeight: "bold",
            }}
          >
            9<span className="Apple-converted-space">&nbsp;</span>
          </span>
          <a name="_Toc8723805" />
          <span
            className="s5"
            style={{
              lineHeight: "14.4px",
              textTransform: "uppercase",
              fontWeight: "bold",
            }}
          >
            COMPLAINTS
          </span>
        </div>
        <div
          className="s9"
          style={{
            marginTop: 0,
            marginBottom: 0,
            textIndent: 0,
            marginLeft: 42,
            caretColor: "rgb(0, 0, 0)",
            color: "rgb(0, 0, 0)",
            fontFamily: "-webkit-standard",
            fontSize: 16,
            fontStyle: "normal",
            fontVariantCaps: "normal",
            fontWeight: 400,
            letterSpacing: "normal",
            orphans: "auto",
            textAlign: "start",
            textTransform: "none",
            whiteSpace: "normal",
            widows: "auto",
            wordSpacing: 0,
            WebkitTextStrokeWidth: 0,
            textDecoration: "none",
          }}
        >
          <span style={{ lineHeight: "14.4px" }}>
            If you wish to complain about how we handle your personal
            information held by us, please contact us using the details set out
            below including your name and contact details. We will investigate
            your complaint promptly and respond to you within a reasonable
            timeframe.
          </span>
        </div>
        <div
          className="s7"
          style={{
            marginTop: 18,
            marginBottom: 0,
            textIndent: "-42px",
            marginLeft: 42,
            caretColor: "rgb(0, 0, 0)",
            color: "rgb(0, 0, 0)",
            fontFamily: "-webkit-standard",
            fontSize: 16,
            fontStyle: "normal",
            fontVariantCaps: "normal",
            fontWeight: 400,
            letterSpacing: "normal",
            orphans: "auto",
            textAlign: "start",
            textTransform: "none",
            whiteSpace: "normal",
            widows: "auto",
            wordSpacing: 0,
            WebkitTextStrokeWidth: 0,
            textDecoration: "none",
          }}
        >
          <span
            className="s5"
            style={{
              lineHeight: "14.4px",
              textTransform: "uppercase",
              fontWeight: "bold",
            }}
          >
            10<span className="Apple-converted-space">&nbsp;</span>
          </span>
          <a name="_Toc8723806" />
          <span
            className="s5"
            style={{
              lineHeight: "14.4px",
              textTransform: "uppercase",
              fontWeight: "bold",
            }}
          >
            CONTACT US
          </span>
        </div>
        <div
          className="s9"
          style={{
            marginTop: 0,
            marginBottom: 0,
            textIndent: 0,
            marginLeft: 42,
            caretColor: "rgb(0, 0, 0)",
            color: "rgb(0, 0, 0)",
            fontFamily: "-webkit-standard",
            fontSize: 16,
            fontStyle: "normal",
            fontVariantCaps: "normal",
            fontWeight: 400,
            letterSpacing: "normal",
            orphans: "auto",
            textAlign: "start",
            textTransform: "none",
            whiteSpace: "normal",
            widows: "auto",
            wordSpacing: 0,
            WebkitTextStrokeWidth: 0,
            textDecoration: "none",
          }}
        >
          <span style={{ lineHeight: "14.4px" }}>
            For further information about our privacy policy or practices, or to
            access or correct your personal information, or make a complaint,
            please contact us using the details set out below:
          </span>
        </div>
        <div
          className="s9"
          style={{
            marginTop: 0,
            marginBottom: 0,
            textIndent: 0,
            marginLeft: 42,
            caretColor: "rgb(0, 0, 0)",
            color: "rgb(0, 0, 0)",
            fontFamily: "-webkit-standard",
            fontSize: 16,
            fontStyle: "normal",
            fontVariantCaps: "normal",
            fontWeight: 400,
            letterSpacing: "normal",
            orphans: "auto",
            textAlign: "start",
            textTransform: "none",
            whiteSpace: "normal",
            widows: "auto",
            wordSpacing: 0,
            WebkitTextStrokeWidth: 0,
            textDecoration: "none",
          }}
        >
          <span
            className="s8"
            style={{ lineHeight: "14.4px", fontWeight: "bold" }}
          >
            Name:<span className="Apple-converted-space">&nbsp;</span>
          </span>
          <span style={{ lineHeight: "14.4px" }}>[</span>
          <span style={{ lineHeight: "14.4px" }}>insert</span>
          <span style={{ lineHeight: "14.4px" }}>]</span>
        </div>
        <div
          className="s9"
          style={{
            marginTop: 0,
            marginBottom: 0,
            textIndent: 0,
            marginLeft: 42,
            caretColor: "rgb(0, 0, 0)",
            color: "rgb(0, 0, 0)",
            fontFamily: "-webkit-standard",
            fontSize: 16,
            fontStyle: "normal",
            fontVariantCaps: "normal",
            fontWeight: 400,
            letterSpacing: "normal",
            orphans: "auto",
            textAlign: "start",
            textTransform: "none",
            whiteSpace: "normal",
            widows: "auto",
            wordSpacing: 0,
            WebkitTextStrokeWidth: 0,
            textDecoration: "none",
          }}
        >
          <span
            className="s8"
            style={{ lineHeight: "14.4px", fontWeight: "bold" }}
          >
            Email
          </span>
          <span style={{ lineHeight: "14.4px" }}>: [</span>
          <span style={{ lineHeight: "14.4px" }}>insert</span>
          <span style={{ lineHeight: "14.4px" }}>]</span>
        </div>
        <div
          className="s9"
          style={{
            marginTop: 0,
            marginBottom: 0,
            textIndent: 0,
            marginLeft: 42,
            caretColor: "rgb(0, 0, 0)",
            color: "rgb(0, 0, 0)",
            fontFamily: "-webkit-standard",
            fontSize: 16,
            fontStyle: "normal",
            fontVariantCaps: "normal",
            fontWeight: 400,
            letterSpacing: "normal",
            orphans: "auto",
            textAlign: "start",
            textTransform: "none",
            whiteSpace: "normal",
            widows: "auto",
            wordSpacing: 0,
            WebkitTextStrokeWidth: 0,
            textDecoration: "none",
          }}
        >
          <span style={{ lineHeight: "14.4px" }}>
            Our privacy policy was last updated on
            <span className="Apple-converted-space">&nbsp;</span>
          </span>
          <span style={{ lineHeight: "14.4px" }}>30</span>
          <span style={{ lineHeight: "14.4px" }}>
            <span className="Apple-converted-space">&nbsp;</span>November 2023.
          </span>
        </div>
        <p
          style={{
            marginTop: 0,
            marginBottom: 0,
            lineHeight: "14.4px",
            caretColor: "rgb(0, 0, 0)",
            color: "rgb(0, 0, 0)",
            fontFamily: "-webkit-standard",
            fontSize: 16,
            fontStyle: "normal",
            fontVariantCaps: "normal",
            fontWeight: 400,
            letterSpacing: "normal",
            orphans: "auto",
            textAlign: "start",
            textIndent: 0,
            textTransform: "none",
            whiteSpace: "normal",
            widows: "auto",
            wordSpacing: 0,
            WebkitTextStrokeWidth: 0,
            textDecoration: "none",
          }}
        >
          <span style={{ lineHeight: "14.4px" }}>&nbsp;</span>
        </p>
        <table
          style={{
            borderCollapse: "collapse",
            borderColor: "black",
            fontSize: 16,
            caretColor: "rgb(0, 0, 0)",
            fontFamily: "-webkit-standard",
            letterSpacing: "normal",
            orphans: "auto",
            textTransform: "none",
            widows: "auto",
            wordSpacing: 0,
            WebkitTextStrokeWidth: 0,
            textDecoration: "none",
            marginLeft: 0,
          }}
        >
          <tbody>
            <tr style={{ verticalAlign: "top" }}>
              <td
                className="s12"
                style={{ overflowWrap: "break-word", height: 14 }}
              >
                <div style={{ marginTop: 0, marginBottom: 0, maxWidth: 219 }}>
                  <p
                    className="s13"
                    style={{
                      marginTop: 4,
                      marginBottom: 0,
                      lineHeight: "14.4px",
                    }}
                  >
                    <span style={{ lineHeight: "14.4px" }}>&nbsp;</span>
                  </p>
                </div>
              </td>
              <td
                className="s12"
                style={{ overflowWrap: "break-word", height: 14 }}
              >
                <div style={{ marginTop: 0, marginBottom: 0, maxWidth: 42 }}>
                  <p
                    style={{
                      marginTop: 0,
                      marginBottom: 0,
                      lineHeight: "14.4px",
                    }}
                  >
                    <a>
                      <span
                        className="s14"
                        style={{ lineHeight: "10.8px", fontSize: 9 }}
                      >
                        1
                      </span>
                    </a>
                  </p>
                </div>
              </td>
              <td
                className="s12"
                style={{ overflowWrap: "break-word", height: 14 }}
              >
                <div style={{ marginTop: 0, marginBottom: 0, maxWidth: 219 }}>
                  <div
                    className="s13"
                    style={{
                      marginTop: 4,
                      marginBottom: 0,
                      border: "1px solid transparent",
                    }}
                  >
                    <br />
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <p
          className="s16"
          style={{
            marginTop: 0,
            marginBottom: 0,
            lineHeight: "14.4px",
            caretColor: "rgb(0, 0, 0)",
            color: "rgb(0, 0, 0)",
            fontFamily: "-webkit-standard",
            fontSize: 16,
            fontStyle: "normal",
            fontVariantCaps: "normal",
            fontWeight: 400,
            letterSpacing: "normal",
            orphans: "auto",
            textAlign: "start",
            textIndent: 0,
            textTransform: "none",
            whiteSpace: "normal",
            widows: "auto",
            wordSpacing: 0,
            WebkitTextStrokeWidth: 0,
            textDecoration: "none",
          }}
        >
          <span style={{ lineHeight: "14.4px" }}>&nbsp;</span>
        </p>
        <table
          style={{
            borderCollapse: "collapse",
            borderColor: "black",
            fontSize: 16,
            caretColor: "rgb(0, 0, 0)",
            fontFamily: "-webkit-standard",
            letterSpacing: "normal",
            orphans: "auto",
            textTransform: "none",
            widows: "auto",
            wordSpacing: 0,
            WebkitTextStrokeWidth: 0,
            textDecoration: "none",
            marginLeft: 0,
            borderStyle: "none",
          }}
        >
          <tbody>
            <tr style={{ verticalAlign: "top" }}>
              <td
                className="s27"
                style={{
                  overflowWrap: "break-word",
                  height: 14,
                  borderStyle: "none",
                }}
              >
                <br />
              </td>
              <td
                className="s27"
                style={{
                  overflowWrap: "break-word",
                  height: 14,
                  borderStyle: "none",
                }}
              >
                <br />
              </td>
              <td
                className="s27"
                style={{
                  overflowWrap: "break-word",
                  height: 14,
                  borderStyle: "none",
                }}
              >
                <div style={{ marginTop: 0, marginBottom: 0, maxWidth: 219 }}>
                  <p
                    className="s30"
                    style={{
                      marginTop: 4,
                      marginBottom: 0,
                      lineHeight: "14.4px",
                      textAlign: "right",
                    }}
                  >
                    <br />
                  </p>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <p
          className="s31"
          style={{
            marginTop: 0,
            marginBottom: 0,
            lineHeight: "14.4px",
            caretColor: "rgb(0, 0, 0)",
            color: "rgb(0, 0, 0)",
            fontFamily: "-webkit-standard",
            fontSize: 16,
            fontStyle: "normal",
            fontVariantCaps: "normal",
            fontWeight: 400,
            letterSpacing: "normal",
            orphans: "auto",
            textAlign: "start",
            textIndent: 0,
            textTransform: "none",
            whiteSpace: "normal",
            widows: "auto",
            wordSpacing: 0,
            WebkitTextStrokeWidth: 0,
            textDecoration: "none",
          }}
        >
          <span style={{ lineHeight: "14.4px" }}>&nbsp;</span>
        </p>
      </Container>
    </Layout>
  );
};

export default PrivacyPolicy;
